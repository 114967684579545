import React, { memo } from 'react';
import Utilities from '../../helpers/Utilities';

const CommonProfileLogo = (props) => {

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    let propsLogo = (props.profileDetail.logo ?? '') !== '' ? (props.profileDetail.logo ?? '') : (props.profileDetail.logo_url ?? '');
    propsLogo = (props.profileDetail.img ?? '') !== '' ? props.profileDetail.img : propsLogo;

    const propsName = props.profileDetail.name ?? '';
    const propsClass = props.classAttr ?? '';
    const propsPreventOnclick = props.preventOnclick ?? false;

    let bgImage = Utilities.showHideProfileImage(propsLogo);
    const bgColor = Utilities.bgcolorHideInitialBg(propsLogo);

    if (!Utilities.isEmpty(propsLogo)) {
        let imageName = propsLogo.split('/').slice(-1)[0];
        imageName = imageName.split('.')[0];

        if (imageName === 'broadcast_default') {
            bgImage = DEFAUL_AVATAR;
        }
    }


    const handleImageView = (e) => {
        if (propsPreventOnclick) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (typeof props.handlePreviewProfileImage !== "undefined") {
            props.handlePreviewProfileImage(propsLogo);
        }
    }

    return (
        <div className={propsClass} style={{ backgroundImage: `url(${bgImage})`, backgroundColor: bgColor }} onClick={(e) => handleImageView(e)} >
            {(props.children ?? '') === '' ? Utilities.showHideInitial(propsLogo, propsName) : props.children}
        </div>
    )

}

export default memo(CommonProfileLogo);