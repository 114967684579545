const ENV_SITE_TITLE = process.env.REACT_APP_site_title;


class TitlesLabels {

    settings = `Settings`
    setting = `Setting`

    navigations = {
        qrCode: `QR Code`,
        messagesInfo: `messages/info`,
        standardizedReplies: `saved frequent message`, //`standardized replies`,
        departments: `Departments`,
        importantInformation: `Important Information`,
        bizcomAssist: `${ENV_SITE_TITLE} Assist`,
        search: `Search`,
        teamMembers: `Team Members`,
        team: `Team`,
        profile: `Profile`,
        viewChats: `View Chats`,
        sendDocument: `Send Document`,
        documents: `Documents`,
        documentLibrary: `Document Library`,
        managePersonalProfile: `Manage Personal Profile`,
        deactivateAccount: `Deactivate Account`,
        linkedChats: `Linked Chats`,
        divert: `Divert`,
        manageDepartments: `Manage Departments`,
        manageCompanyProfile: `manage company profile`,
        manageCompany: `manage company`,
        manageWorkProfile: `manage work profile`,
        calendar: `calendar`,
        diary: `Diary`,
        appointmentsReservations: `Appointments/Reservations`,
        security: `Security`,
        specialPermissions: `Special Permissions`,
        followersNotices: `Followers/Notices`,
        notifications: `Notifications`,
        notificationManagement: `Notification Management`,
        notificationDetail: `Notification Detail`,
        sendNotification: `Send Notification`,
        branchInfo: `branch info`,
        branchList: `branch list`,

        followersList: `Follower's List`,
        suspendFollower: `Suspend Follower`,
        removeFollower: `Remove Follower`,

        companyGroups: `groups`,
        add: `add`,
        delete: `delete`,

        bulkMessage: `Bulk message`,
        gotoLinks: `Goto Links`,
        categories: `Catogies`,
        socialMediaLibrary: `social media library`,
        addSocial: `add social`,
    }

    general = {
        internal_chat: `internal chat`,
        admin: `admin`,
        team_member: `team member`,
        manage: `manage`,
        headings: `headings`,
        couldNotFetchData: `could not fetch data, please reload and try again.`,
        noDepartmentAssigned: `no department assigned`,
        noTeamAdded: `No Team Added!`,
        noTeamMemberAvailable: `No Team Member Available!`,
        noDepartmentAvailable: `No Department Available!`,
        noGroupsAvailable: `No Groups Available!`,
        noMediaAvailable: `No media available`,
        noDocumentsAvailable: `No documents available`,
        noLinksAvailable: `No links available`,
        noLocationsAvailable: `No locations available`,
        noDetailAvailable: `no detail available`,
        noRecordAvailable: `no record available`,
        noBranchAvailable: `no branch available`,
        clickHereToDownload: `click here to download`,
        unableToDownload: `unable to download`,
        gettingCurrentLocation: `getting current location...`,
        gettingLocation: `getting location...`,
        passwordGuideText: `Your password must contain following letters: 1 Uppercase, 1 lowercase, 1 number & 1 special character.`,
        clickHereToGetCurrentLocation: `click here to get current location`,
        noAppointmentReservationFound: `no appointment/reservation found`,
        exitWithoutSaving: `exit without saving`,
        termsAndConditions: `Terms and Conditions`,
        disclaimer: `Disclaimer`,
        privacyPolicy: `Privacy Policy`,
        endUserLicenseAgreement: `End User License Agreement`,
        beonchatCompetitionBoost: `Beonchat Competition T&Cs`,
        quickLinks: `quick links`,
        branchList: `branch list`,
        chooseNumberRefectOnBusiness: `choose number to reflect on Business Info visible to other users`,
        numberInvalidIncorrect: `number is invalid or incorrect`,
        emailAddressInvalid: `invalid email address`,
        fieldsWithAsteriskIsMandatory: `fields with asterisk (*) is mandatory`,
        required: `(required)`,
        noContent: `no content`,
        deactivateAccountWarning: `you currently have other profile/s as either a team member or as an administrator. You can only delete your personal profile after you remove or delete these first`,
        automatedReply: `automated reply`,
        currentLocation: `Current Location`,
        businessType: `business type`,
        defaultMessage: `default message`,
        couldNotUploadFile: `Could not upload file!`,
        specialCharactersNotAllowed: `special characters are not allowed!`,
        currentEmailIncorrent: `current email incorrect!`,
        currentPasswordIncorrent: `current password incorrect!`,
        currentMobileIncorrent: `current mobile number incorrect!`,
        directorySearch: `directory search`,
        newNotifications: `new notifications`,
        notifications: `notifications`,
        chatSearch: `chat search`,
        searchProfile: `Search Profile`,
        search: `Search`,
        referral: `referral`,
        congratulations: `congratulations!`,
        request: `request`,
        reasonForRejection: `reason for rejection`,
        messageIsMandatory: `message is manadatory`,

        newConfirmEmailNotSame: `new and confirm email are not the same!`,
        newConfirmPasswordNotSame: `new and confirm password are not the same!`,
        newConfirmMobileNotSame: `new and confirm mobile are not the same!`,

        signup_importantInformation: `important information / conditions of use`,
        noticesAndNotification: `notices and notification`,

        follow: `follow`,
        followed: `followed`,
        following: `following`,
        unfollow: `unfollow`,

        notificationOption: `notification option`,
        followers: `followers`,

        muteNotification: `mute notification`,

        followSettings: `Follow Settings`,

        chatNewMessageWarning: `this is a first communication from this user. in the event this is an advertisement, please "block or report" this user. if a legitimate message, please continue.`,
        chatNewMessageInfo: `important: we do not tolerate any unsolicited advertising by any user. advertising is onlt permitted to followers of a business through our follow/notifications module`,
        chatBewareCommnunicaion: `please be aware this is a one way communication which only allows the group administers to send messages`,
        sendToSelectedMembers: `send to selected members`,

        title_unableToSendDetail: `unable to send detail`,
        title_unableToSendQRCode: `unable to send qrcode`,
        title_unableToSendMail: `unable to send mail`,
        title_detailHasBeenSent: `your message has been successfully sent`,

        restricted_someKeyword: `some keywords are restricted`,
        restricted_name: `name is restricted`,
        restricted_email: `email has already been taken`,
        restricted_nameEmail: `name or email is restricted`,
        restricted_nameKeywords: `name or some keywords are restricted`,
        restricted_someWords: `some words are restricted`,

        appStore_googlePlay: `google play`,
        appStore_appleStore: `app store`,
        appStore_huaweiGallery: `appgallery`,

        noRecordFound: `no record found!`,
        profiles: `profiles`,

        additionalCompanyRequest: `additional company request`,
        dragTheMarkerToSelectlocation: `drag the marker to select location`,
        companyAddress: `Company Address`,
        additionalCompany: `additional company`,

        dailyGlance: `Daily Glance`,
        events: `Events`,
        featuredArticles: `Featured Articles`,
        signUps: `Sign Ups`,
        preview: `preview`,
        security_alert: `Security alert`,
    }

    siteTitles = {
        login: `Login`,
        headings: `Headings`,
        switchAccount: `Switch Account`,
        termsAndConditions: `Terms and Conditions`,
        privacyPolicy: `Privacy Policy`,
        siteMap: `Site Map`,
        selectType: `Select Type`,
        newPassword: `New Password`,
        personalInformation: `Personal Information`,
        credentialDetails: `Credential Details`,
        personalAccount: `Personal Account`,
        personalLogin: `Personal Login`,
        businessAccount: `Business Account`,
        digitVerification: `4 Digit Verification`,
        profilePhoto: `Profile Photo`,
        companyInformation: `Company Information`,
        keywordInformation: `Keyword Information`,
        companyAddress: `Company Address`,
        companyLogo: `Company Logo`,
        teamMember: `Team Member`,
        teamMembers: `Team Members`,
        chat: `Chat`,
        departmentDashboard: `Department Dashboard`,
        dashboard: `Dashboard`,
        linkedDepartments: `Linked Departments`,
        shareQrCode: `Share QR Code`,
        qrCode: `QR Code`,
        specialMessage: `Saved Frequent Message`,
        security: `Security`,
        importantInformation: `Important Information`,
        department: `Department`,
        departments: `Departments`,
        internalChat: `Internal Chat`,
        viewChats: `View Chats`,
        sendDocument: `Send Document`,
        documents: `Documents`,
        managePersonalProfile: `Manage Personal Profile`,
        deactivateProfile: `Deactivate Profile`,
        linkedChats: `Linked Chats`,
        divertChats: `Divert Chats`,
        assign: `Assign`,
        manageDepartments: `Manage Departments`,
        companyProfile: `Company Profile`,
        workProfile: `Work Profile`,
        pageNotFound: `404 | Page Not Found`,
        calendarDiary: `Calendar Diary`,
        groupConversation: `Conversation`,
        companyGroups: `Groups`,
        directorySearch: `directory search`,
        contactSearch: `contact search`,
        directoryBusiness: `Business Directory`,
        branchCompanyInfo: `Branch Company Info`,
        specialPermissions: `Special Permissions`,
        campainAppDowload: `Download App`,
        referral: `Referral`,
        welcome: `Welcome`,
        businessBoostCompetition: `Business Boost Competition`,
        gotoLinks: `goto links`,
    }

    alertMessages = {
        requestSent: `request sent!`,
        noRecordFound: `no record found!`,
        wishToUpdateBranches: `do you wish to update all branches information?`,
        couldNotGetConversationDetails: `Could not get conversation details!`,
        couldNotProcessRequest: `Could not process request!`,
        profileSavedSuccessfully: `Profile saved successfully!`,
        profileUpdatedSuccessfully: `Profile updated successfully!`,
        couldNotUpdatePassword: `Could not update password!`,
        passwordUpdatedSuccessfully: `Password updated successfully!`,
        couldNotUpdateEmail: `Could not update email!`,
        emailUpdatedSuccessfully: `Email updated successfully!`,
        couldNotUpdateMobile: `Could not update mobile!`,
        mobileUpdatedSuccessfully: `Mobile updated successfully!`,
        couldNotUpdateProfile: `Could not update profile!`,
        messageIsRequired: `Message is required!`,
        descriptionIsRequired: `Description is required!`,
        titleIsMissing: `Title is missing!`,
        titleIsRequired: `Title is required!`,
        messageIsMissing: `Message is missing!`,
        noMemberSelected: `No member selected!`,
        couldNotSendFileTryAgain: `Could not send file! Please try again.`,
        //successfullyDeleted : `Has been successfully deleted.`,
        broadcastNameIsRequired: `Broadcast name is required!`,
        successfullyCreated: `Created successfully!`,
        successfullyUpdated: `Updated successfully!`,
        successfullyDeleted: `Deleted successfully!`,
        successfullyJoined: `Joined successfully!`,
        successfullyLeaved: `Leaved successfully!`,
        successfullyRemoved: `Removed successfully!`,
        selectFileToSend: `Please select file to send!`,
        noteShouldNotEmpty: `Note should not be empty!`,
        couldNotSaveNote: `Could not save note!`,
        couldNotGetTemplates: `Could not get templates!`,
        noItemSelected: `no item selected`,
        microphoneIsNotDetected: `Microphone is not detected!`,
        documentSuccessfullyDeleted: `Document successfully deleted!`,
        couldNotDeleteDocument: `Could not delete document!`,
        fileShouldNotBeEmpty: `file should not be empty!`,
        couldNotGenerateQrCode: `Could not generate qrcode.`,
        messageShouldNotEmpty: `Message should not be empty!`,
        couldNotDeleteMember: `Could not delete member!`,
        invitationSuccessfullySent: `Invitation successfully sent!`,
        fileExtensionIsNotValid: `File extension is not valid!`,
        titlesUpSendOtherCode: `Time is up! Try to send another code.`,
        verificationSentCheckYourInbox: `Verification has been sent to your email! Please check you inbox or spam.`,
        couldNptSendEmailTryAgain: `Could not send email. Please try again!`,
        couldNotSendCodeTryAgain: `Could not send code. Please try again!`,
        successSentVeificationCode: `verification code sent!`,
        imageMandatory: `Image is mandatory!`,
        imagesMandatory: `Images are mandatory!`,
        documentSentSuccessfully: `document sent successfully.`,
        incorrectCodeCheckInbox: `Incorrect code, please check your inbox!`,
        incorrectCode: `Incorrect code!`,
        cantSendEmail: `Can't send email, please try again!`,
        opsInvalidUrl: `Opps! Invalid URL`,
        invalidYoutubeUrl: `Invalid youtube url`,
        exceededMaximumFileSize: `Exceeded maximim file size!`,
        invalidEmail: `Invalid email!`,
        messageSuccessfullySent: `Message successfully sent!`,
        specialPermissionsUpdated: `Special permissions updated successfully!`,
        useThisToLogin: `use this to login to ${ENV_SITE_TITLE.toLowerCase()}`,
        mobileActivationWarningMessage: `by activating this feature, your mobile number will be publicly displayed on your company listing. please be mindful of the implications of this visibility.`,
        mobileActivationDepartmentWarningMessage: `you have listed a telephone number which will be publicly displayed on the department chat. please be mindful of the implications of this visibility`,
        companyAddressVisibilityWarningMessage: `by activating visibility, you are allowing for your full address to be shown publicly. alternatively only your suburb, city, state and country will be shown`,
        mobileTeamActivationWarningMessage: `by activating this feature, your number will be publicly displayed on your profile listing. please be mindful of the implications of this visibility.`,

        successfullyFollowed: `has been successfully followed`,
        successfullyUnFollowed: `has been successfully unfollowed`,

        notiticationHasBeenSent: `notification has been successfully sent`,
        followersHasBeenRemoved: `follower/s has been sucessfully removed`,
        followersHasBeenSuspended: `follower/s has been sucessfully suspended`,

        messageHasBeenSend: `message has been successfully sent`,

        noProfileSelected: `no profile selected`,

        oneKeywordRequired: `one keyword is required!`,
        threeKeywordRequired: `three keywords is required!`,
        mobileNumberShouldNotBeEmpty: `mobile number should not be empty!`,

        loginNoFoundRecord: `No record found!`,
        loginFoundAccountCredetial: `Have existing credential account`,
        loginInvalidCredential: `invalid credential details`,
        loginAlreadyWithPassword: `user already registered with password`,


        groupAccessInfo: `allows entry if security code is used by user`,
        groupAccessPasscodeInfo: `allows entry if security code is used by user`,
        groupAccessGroupAdminInfo: `group admin/s will receive requests to join group. acceptance and removal of group member/s is vested with them`,

        isRequired: `is required!`,
        passcodeInvalidNumericLength: `passcode should be 6 numeric values`,

        linkInvalid: `link is invalid!`,

        warningMessageCompanyCreation: `you already have a company registered on your profile. would you like to request for an additional company listing?`,
        companyRequestAlreadySent: `company request has already been sent`,
        emailShouldNotBeSameExisting: `new email can't be the same as existing email`,
        passwordShouldNotBeSameExisting: `new password can't be the same as existing password`,
        numberShouldNotBeSameExisting: `new number can't be the same as existing number`,

        noUserFoundWithEmailAddress: "no user found with that email address.",
        noUserFoundWithEmailMobile: "no user found with that mobile number",

        successSentPasswordReset: "we've successfully sent you a password reset.",
        successSentPasswordResetEmail: "we've successfully sent you a password reset email.",
        successSentPasswordResetMobile: "we've successfully sent you a password reset mobile.",

        successMuteUpdated: `mute status have been successfully updated.`,
        teamInvitationSuccess: `team member invitation successfully sent`,
        invalidUsername: `invalid username`,
    }

    confirmMessages = {
        wishToDelete: `Are you sure you wish to delete?`,
        wishToDeleteBroadcast: `Are you sure you wish to delete broadcast?`,
        wantToDeleteGroup: `Are you sure you want to delete the group?`,
        wantToLeaveGroup: `Are you sure you want to leave the group?`,
        wantDeleteConversation: `Are you sure you want to delete conversation?`,
        wantDeleteDepartment: `Are you sure you wish to delete this department?`,
        wishToDeleteDocument: `Are you sure you wish to delete document?`,
        wishToUnlinkDepartment: `are you sure you wish to unlink this department?`,
        wantToClearMessages: `Are you sure you want to clear messages?`,
        wantDeleteSelectedMessages: `Are you sure you want to delete selected messages?`,
        wantToLogout: `do you wish to logout?`,
        wishDeActivateAutomatedReply: `do you wish to de-activate automated reply?`,
        wishDeleteTeamMember: `Are you sure you wish to delete this team member?`,
        wishToAddProfileImage: `do you wish to add a profile image?`,
        wishToCancelRequest: `do you wish to cancel request?`,
        wishToCancel: `do you wish to cancel?`,
        wishGrantManagementAccess: `are you sure you wish to grant management access to this team member?`,
        followFeatures: `this feature allows customers to follow your company which permit you to send them any sale or advertising material.<br /><br />do you wish to activate?`,
        wishToUnfollow: `do you wish to unfollow?`,
        wishToFollow: `do you wish to follow?`,
        wishToFollowCompany: `do you wish to follow this company?`,
        wishToDeactivate: `do you wish to deactivate?`,
        wishToActivate: `do you wish to activate?`,
        wishToUnfollowCompany: `are you sure you wish to unfollow the selected companies?`,
        wishToMuteompany: `are you sure you wish to mute the selected companies?`,
        areYouSureResendCode: `Are you sure to resend the verification code?`,
    }

}

export default new TitlesLabels();

