import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import PagesAuthentication from "../../helpers/PagesAuthentication";
import TitlesLabels from "../../helpers/TitlesLabels";
import { showFooter, showHeader, showMainSettings as showMainSettingsReducer } from "../../actions";
import UrlSlugs from "../../helpers/UrlSlugs";

import { checkingFollowDailyGlanceStatus, getListByDate } from "../../helpers/QueryDailyGlance";
import Utilities from "../../helpers/Utilities";
import { getCompanyNewRegistered } from "../../helpers/QueryDirectoryRegisteredNew";

import { getListEvents } from "../../helpers/QueryDailyGlanceEvents";
import { getListFeatured } from "../../helpers/QueryDailyGlanceFeatured";
import { handleClickAdverstisement } from "../../helpers/UtilitiesDailyGlance";
import { DailyGlanceMainPopup } from "./DailyGlancePopups";
import SessionUtilities from "../../helpers/SessionUtilities";



const DailyGlanceEventsFeaturedLayout = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const typeName = props.typeName ?? 'events';

    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const ADS_CompanyId = process.env.REACT_APP_notification_glance_signup_company_id ?? '0';

    const { dateYYmmdd } = useParams();
    let result_Db = '';

    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const defaultLocation = Utilities.defaultLocation();
    const currentCityId = defaultLocation.city_id ?? 0;
    const currentAreaId = defaultLocation.area_id ?? 0;


    const [categoryList, setCategoryList] = useState([]);
    const [articleListAll, setArticleListAll] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [registeredTotal, setRegisteredTotal] = useState(0);

    const [eventTotalPage, setEventTotalPage] = useState(0);
    const [featuredTotalPage, setFeaturedTotalPage] = useState(0);

    const [selectedArticle, setSelectedArticle] = useState({});

    const [showOptionPopup, setShowOptionPopup] = useState(false);

    const handleCloseOptionPopUp = () => {
        setShowOptionPopup(false);
    }

    const handleShowOptionPopUp = () => {
        setShowOptionPopup(true);
    }


    const gettingCategories = async (dateFormatStr = '') => {

        result_Db = await getListByDate({ date_selected: dateFormatStr ?? '' });
        if (parseInt(result_Db.status ?? 0) === 1) {
            const articleAll = result_Db.list ?? [];

            setCategoryList(result_Db.categories ?? []);
            setArticleListAll(articleAll);

            setSelectedArticle({
                ads_flag: '1',
                ads_company_id: ADS_CompanyId.toString(),
                ads_image: result_Db.ads_signup_url ?? ''.toString()
            });
        }
    }

    const handleClickCategorySelection = async (categoryInfo = {}) => {
        if (Object.keys(categoryInfo).length > 0) {

            const listByCategory = articleListAll.filter(row => parseInt(row.today_category_id ?? -2) === parseInt(categoryInfo.category_id ?? 0));

            if (Object.keys(listByCategory).length > 0) {
                const detail = listByCategory[0];
                const readMoreUrl = `/${UrlSlugs.notificationDailyGlanceArticle}/${btoa(detail.id)}/${btoa(detail.date ?? '')}`;
                history.push(readMoreUrl);
            }

        }
    }


    const gettingRegisteredCounter = async () => {
        if (currentCityId > 0 && parseInt(profileLoggedin.account_id ?? 0) > 0) {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(),    //API_TOKEN.toString(),
                account_id: profileLoggedin.account_id.toString(),
                city_id: (currentCityId ?? 0).toString(),
                area_id: (currentAreaId ?? 0).toString()
            }

            result_Db = await getCompanyNewRegistered(passval);
            if (Object.keys(result_Db).length > 0) {
                setRegisteredTotal(Object.keys(result_Db ?? []).length);
            }

        }
    }

    const redrectToGlanceHome = () => {
        history.push(`/${UrlSlugs.settings_notifications}`);
    }


    const getEventsAndFeturedList = async () => {

        result_Db = await getListEvents({ view_all: 0 });
        if (parseInt(result_Db.status ?? 0) === 1) {

            if (parseInt(result_Db.count ?? 0) > 0) {
                setEventTotalPage(result_Db.page_last ?? 0);
            }
        }


        result_Db = await getListFeatured({ view_all: 0, per_page: 6 });
        if (parseInt(result_Db.status ?? 0) === 1) {

            if (parseInt(result_Db.count ?? 0) > 0) {
                setFeaturedTotalPage(result_Db.page_last ?? 0);
            }
        }

    }

    useEffect(async () => {

        PagesAuthentication.getUserLoggedin();

        dispatch(showHeader(1));
        dispatch(showFooter(0));

        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (!glanceStatus) {
            redrectToGlanceHome();
        }


        try {
            let dateYYmmddParam = atob(dateYYmmdd);
            setSelectedDate(dateYYmmddParam);

            await gettingCategories(dateYYmmddParam);
            await gettingRegisteredCounter();
            await getEventsAndFeturedList();

        } catch (e) {
            redrectToGlanceHome();
        }




    }, []);



    return (
        <>
            <div className="header-daily-glance">

                <div className="breadcrumbs  breadcrumbs-chat">
                    <div className="container breadcrumbs-content">
                        <ul className="breadcrumbs-nav">
                            <li className="btn-option">
                                <div onClick={() => history.goBack()} className="btn-icon btn-arrow-left" title={TitlesLabels.general.dailyGlance}></div>
                                <Link to={`/${UrlSlugs.notificationDailyGlance}`}>{TitlesLabels.general.dailyGlance}</Link>
                                <div className="current">{props.menuTitle ?? ''}</div>
                            </li>
                            <li className="btn-option">

                                <div className="btn-icon btn-icon btn-menu-two" title={TitlesLabels.navigations.categories} onClick={() => handleShowOptionPopUp()}></div>
                                <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>

                            </li>
                        </ul>
                    </div>

                </div>

                <div className="main-content position-relative">
                    <div className="container py-5">
                        {props.children}
                        {
                            typeName === 'signups' &&
                            <div className="col-lg-10 mx-auto mt-4">
                                <div className="ads-image" style={{ backgroundImage: `url('${selectedArticle.ads_image ?? ''}` }} onClick={() => handleClickAdverstisement(selectedArticle, 1, dispatch)}></div>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <DailyGlanceMainPopup showPopup={showOptionPopup} handleClosePopUp={handleCloseOptionPopUp} listCategories={categoryList} totalPageEvent={eventTotalPage} totalPageFeatured={featuredTotalPage} totalRegistered={registeredTotal} selectedDate={dateYYmmdd} handleClickCategorySelection={handleClickCategorySelection} typeName={typeName} />

        </>

    )
}

export default DailyGlanceEventsFeaturedLayout;