import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';

import TitlesLabels from '../../helpers/TitlesLabels';
import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';


import { showHeader, showFooter, showLoader, pageTitle, showMainSettings as showMainSettingsReducer, showDepartmentSettings as showDepartmentSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showPopupVideo as showPopupVideoReducer } from '../../actions';

import { sendAppReports } from '../../helpers/SendingEmails';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import { getAppVideos, getMarketingTools, talkToUsConversation } from '../../helpers/BizcomAssist';
import ChatUtilities from '../../helpers/ChatUtilities';
import LinkCopiedTooltip from '../../components/common/LinkCopiedTooltip';
import VideoPopup from '../../components/popups/VideoPopup';
import ApiUrls from '../../helpers/ApiUrls';
import SessionUtilities from '../../helpers/SessionUtilities';

const BizcomAssist = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const IMG_PATH = process.env.REACT_APP_img_folder;
    //const errorMsg = process.env.REACT_APP_message_error_process;
    const SITE_DOMAIN = process.env.REACT_APP_site_url;
    const siteTitle = process.env.REACT_APP_site_title;
    const maxAttachmentAllowed = 1;

    let defaultFormValues = {
        email: '',
        message: '',
        subject: siteTitle + ' web app suggestion'
    }


    const fieldsChecker = ['message'];
    const subjectList = { 1: 'suggestion', 2: 'app running issue' }; //, 3 : 'talk to team' };
    const subjectListMail = { 1: defaultFormValues.subject, 2: siteTitle + ' web app bug report' };


    const [userLoggedinDetail, setUserLoggedinDetail] = useState({});

    const [subjectListSelection, setSubjectListSelection] = useState(subjectList);
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [parentTabActive, setParentTabActive] = useState(1);
    const [childTabActive, setChildTabActive] = useState(1);
    const [headerTypeCurrent, setHeaderTypeCurrent] = useState(1);
    const [includeInformationBool, setIncludeInformationBool] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [attachment, setAttachment] = useState('');

    const [loaderIframe, setLoaderIframe] = useState({ terms: false, licenses: false });
    const [marketingList, setMarketingList] = useState([]);

    const [videoList, setVideoList] = useState([]);
    const [videoListBanner, setVideoBannerList] = useState([]);
    const [videoKeyPlaying, setVideoKeyPlaying] = useState(-1);
    const [videoUrl, setVideoUrl] = useState('');

    const [showShareTooltip, setShowShareTooltip] = useState(false);
    const [showCodeTooltip, setShowCodeTooltip] = useState(false);

    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [videoYoutubeCodePopup, setVideoYoutubeCodePopup] = useState('');


    const handleOnClickVideoIframe = (keyId = -1, clsName = '', youtubeCode = '') => {

        if (clsName === 'video-height__small') {
            setVideoYoutubeCodePopup(youtubeCode);
            setShowVideoPopup(true);

            setVideoUrl('');
            setVideoKeyPlaying(-1);
            return false;
        }

        setVideoUrl(`https://www.youtube.com/embed/${youtubeCode}?autoplay=1`);
        setVideoKeyPlaying(parseInt(keyId));
    }

    const handleSelectFile = () => {
        jQuery('input#talkus_attachment').trigger('click');
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'message') {
            formValuesTemp = { ...formValuesTemp, ...{ message: value } };
        }

        jQuery('[name="' + fieldName + '"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);
    }

    const previewAttachment = (e) => {
        let fileLocation = e.target.value;
        const fileValue = e.target.files[0];
        const fileSize = fileValue.size ?? 0;
        let fileLocationArr = fileLocation.toString().split("\\");

        const fileSizeFortmatted = Utilities.formatBytes(fileSize, 2);
        const fileSizeValue = fileSizeFortmatted.split(' ')[0].toLowerCase();
        const fileSizeExt = fileSizeFortmatted.split(' ')[1].toLowerCase();

        if (!['bytes', 'kb'].includes(fileSizeExt) && parseFloat(fileSizeValue) > 3) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.exceededMaximumFileSize);
            jQuery(e.target).val('');
            return false;
        }

        if (parseInt(attachments.length) === parseInt(maxAttachmentAllowed)) {
            Utilities.messagePopup('error', `maximum of ${maxAttachmentAllowed} attachments allowed!`);
            jQuery(e.target).val('');
            return false;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            const filebase64 = e.target.result;
            const attachmentsTemp = [...attachments, { name: fileLocationArr.slice(-1)[0].toString(), base64: filebase64 }];

            setAttachments(attachmentsTemp);
            jQuery(e.target).val('');
        };

        setAttachment(fileValue);
        reader.readAsDataURL(fileValue);
    }

    const removeAttachment = (e, key = -1) => {
        key = parseInt(key);

        let attachmentsTemp = attachments;
        if (key > -1) {
            attachmentsTemp.splice(key, 1);
        }
        attachmentsTemp = [...attachmentsTemp];
        setAttachments(attachmentsTemp);
        setAttachment('');

    }

    const handleTabChange = async (tabId = 1) => {
        tabId = parseInt(tabId);
        setVideoKeyPlaying(-1);
        setVideoUrl('');
        setMarketingList({});

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        let passval = {}, responseDb = {};

        if (tabId === 1) {

        } else if (tabId === 2) {
            window.open(UrlSlugs.page_helpCenter, '_blank');

        } else if (tabId === 3) {
            //window.open( UrlSlugs.page_termsConditions, '_blank');
            setLoaderIframe({ ...loaderIframe, ...{ terms: true, licenses: false } });

        } else if (tabId === 4) {
            setLoaderIframe({ ...loaderIframe, ...{ terms: false, licenses: true } });

            /* }else if ( tabId===5 ){
                window.open( UrlSlugs.page_talkToUsUrl, '_blank'); */

        } else if (tabId === 6) {


            passval = {
                api_token: API_TOKEN.toString(),
                account_id: userLoginDetailTemp.account_id.toString(),
                //profile_id: parseInt(userLoginDetailTemp.account_type) === 4 ? userLoginDetailTemp.company_detail.account_id.toString() : userLoginDetailTemp.account_id.toString(),
                //profile_type: parseInt(userLoginDetailTemp.account_type) === 4 ? userLoginDetailTemp.company_detail.account_type.toString() : userLoginDetailTemp.account_type.toString(),
            }


            dispatch(showLoader(1));
            responseDb = await getMarketingTools(passval);
            dispatch(showLoader(0));

            if (parseInt(responseDb.success) === 1) {
                setMarketingList(responseDb.result.beonchat_marketing_tools);
            }


        } else if (tabId === 7) {

            passval = {
                api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
                account_id: userLoginDetailTemp.account_id.toString()
            }

            dispatch(showLoader(1));
            responseDb = await getAppVideos(passval);
            dispatch(showLoader(0));

            if (parseInt(responseDb.success) === 1) {
                setVideoList(responseDb.result.beonchat_videos_list.list_videos ?? []);
                setVideoBannerList(responseDb.result.beonchat_videos_list.banner_details ?? []);
            }

        }

        setChildTabActive(1);

        if (![2, 5].includes(tabId)) {
            setParentTabActive(tabId);
        }

    }


    const switchToMasterAccountAndBizcomChat = async () => {

        dispatch(showLoader(0));

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {

                const accountListNormalArray = Utilities.toNormalArrayObject(accountsList);
                let personalAccount = accountListNormalArray.filter(account => parseInt(account.account_type) === 1)[0] ?? [];

                if (Object.keys(personalAccount).length > 0) {

                    dispatch(showLoader(1));

                    // UPDATE LOGIN - PUT MASTER ACCOUNT
                    PagesAuthentication.setLoginAccountDetail(personalAccount);

                    // REDIRECT TO BIZCOM GENERAL CHAT
                    const responseInformation = await talkToUsConversation(parseInt(personalAccount.account_id));
                    dispatch(showLoader(0));

                    if (Object.keys(responseInformation).length > 0) {
                        const profileChat = responseInformation.conversation_details;
                        let passValues = ChatUtilities.encryptChatDetail(profileChat);

                        ChatUtilities.saveAccountsConversationDetail(profileChat);
                        history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(profileChat.account_id))}/${passValues}`);
                    }


                }

            }
        }
    }


    const handleTalkToUsSubject = async (id = 0, label = '') => {

        setAttachments([]);
        jQuery('#talkus_attachment').val('');

        if (id === 3) {
            switchToMasterAccountAndBizcomChat();
            return false;

        } else if (id === 2) {
            setIncludeInformationBool(false);
        }

        if (id !== 3) {
            setChildTabActive(id);
        }

        label = subjectListMail[id].toString().toLowerCase();
        setFormValues({ ...formValues, ...{ subject: label } });
    }

    const handleSubmitTalkToUs = async () => {

        const button_name = 'btn-send-report';
        Utilities.elementDisabledStatus(button_name, false);
        dispatch(showLoader(0));

        const formData = new FormData();

        if (!Utilities.isEmpty(attachment)) {
            formData.append('file', attachment);
        }

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if (Object.keys(loginProfileInfo).length > 0) {
                formData.append('account_id', loginProfileInfo.account_id);
            }
        }

        let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
        if (sessionLocation !== null) {
            sessionLocation = JSON.parse(sessionLocation);

            if (Object.keys(sessionLocation).length > 0) {
                formData.append('lat_long', sessionLocation.latitude + ',' + sessionLocation.longitude);
            }
        }

        const browerAgentNane = Utilities.getBrowerAgent();
        formData.append('include_info', includeInformationBool ? 1 : 0);
        formData.append('subject', formValues.subject);
        formData.append('subject_id', childTabActive.toString());
        formData.append('device', browerAgentNane);
        formData.append('browser', window.navigator.userAgent);


        let errorCounter = 0;
        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    jQuery('[name="' + key + '"]').closest('.form-group').addClass('error');
                    errorCounter++;

                } else {
                    if (key === 'email' && !Utilities.isEmail(value)) {
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.invalidEmail);
                        errorCounter++;
                    }

                    formData.append(key, value);
                }
            }
        });

        if (errorCounter > 0) { return false; }

        dispatch(showLoader(1));
        Utilities.elementDisabledStatus(button_name, true);
        const responseInformation = await sendAppReports(formData);

        Utilities.elementDisabledStatus(button_name, false);
        dispatch(showLoader(0));

        if (Object.entries(responseInformation).length === 0) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.cantSendEmail);
        }

        if (parseInt(responseInformation.status) === 1) {
            Utilities.messagePopup('success', TitlesLabels.alertMessages.messageSuccessfullySent);
            setFormValues(defaultFormValues);
            setAttachments([]);
            setAttachment('');
            setIncludeInformationBool(false);
        } else {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.cantSendEmail);
        }

    }

    const handleOnLoadIFrame = (actionName = '') => {

        if (actionName === 'terms') {
            setLoaderIframe({ ...loaderIframe, ...{ terms: false, licenses: true } });

        } else if (actionName === 'licenses') {
            setLoaderIframe({ ...loaderIframe, ...{ terms: true, licenses: false } });
        }

    }


    const htmlTabContent = () => {
        let returnHtml = '';

        if (parentTabActive === 1) {

            returnHtml = <div className='mt-n4'>

                <div className={`form-col-3 font-gotham-book fs-18 mb-4  ${Object.keys(subjectListSelection).length === 3 ? '' : 'col-8'} mx-auto`}>
                    {
                        Object.entries(subjectListSelection).map(([key, labelName]) =>
                            <div key={key} className={`text-center cursor-pointer pb-3 ${parseInt(key) === childTabActive ? 'font-gotham-bold border-bottom border-theme-colored border-theme-width-2' : 'color-theme-grey'} `} onClick={(e) => handleTalkToUsSubject(parseInt(key), labelName)}>{labelName}</div>
                        )
                    }
                </div>

                <div className="form-theme pb-5">

                    <div className="form-group">
                        <textarea name="message" className="form-control" placeholder=" " autoFocus autoComplete="off" rows="7" value={formValues.message} onChange={(e) => pushValue(e, 'message')}></textarea>
                        <label className="form-label">Type here</label>
                    </div>

                    {
                        childTabActive === 2 &&
                        <div className="text-size-semi-medium checkbox-radio-default checkbox-radio-check-icon d-flex">
                            <input type="checkbox" name="terms" checked={includeInformationBool} onChange={() => setIncludeInformationBool(includeInformationBool ? false : true)}  ></input>
                            <span className="ml-2 fs-14 fs-md-14">include user information</span>
                        </div>
                    }


                    <div className="btn-attachement-trigger cursor-pointer color-theme-grey" onClick={() => handleSelectFile()} >add attachment</div>

                    <ul className="attachment-list-selected mt-3 text-lowercase font-gotham-book">
                        {
                            attachments.map((row, key) =>
                                <li key={key}>
                                    <div className="filename">{row.name}</div><div className="btn-remove-attachment" onClick={(e) => removeAttachment(e, key)}></div>
                                </li>
                            )
                        }
                    </ul>

                    <input type="file" name="talkus-attachment" id="talkus_attachment" className="invisible" readOnly onChange={(e) => previewAttachment(e)} />

                    <div className="button-wrap text-lowercase text-center col-md-6 mx-auto">
                        <button type="button" id="btn-send-report" className="btn-theme-black text-lowercase button-has-loader" onClick={() => handleSubmitTalkToUs()}>Send</button>
                    </div>

                </div>
            </div>

        } else if (parentTabActive === 3) {
            returnHtml = <>
                {loaderIframe.terms && <div className="content-loader animate"></div>}
                <iframe height={`${loaderIframe.terms ? '0' : '1000'}`} width="100%" className='scroll-colored' onLoad={() => handleOnLoadIFrame('terms')} src={`${UrlSlugs.page_privacyPolicy}#toolbar=0&view=Fill`}></iframe>
                {/* <object>
                                <embed type="application/pdf"  height={`${loaderIframe.terms?'0':'1000'}`} width="100%" onLoad={ () => handleOnLoadIFrame('terms') } src={`${ UrlSlugs.page_privacyPolicy}#toolbar=0&view=Fill`} />
                            </object>  */}
            </>

        } else if (parentTabActive === 4) {
            returnHtml = <>
                {loaderIframe.licenses && <div className="content-loader animate"></div>}
                <iframe height={`${loaderIframe.licenses ? '0' : '1000'}`} width="100%" className='scroll-colored' onLoad={() => handleOnLoadIFrame('licenses')} src={`${UrlSlugs.page_enduserLicense}#toolbar=0&view=Fill`}></iframe>
            </>

        } else if (parentTabActive === 7) {
            let videoListHtml = '';

            returnHtml = <div className="text-center text-lowercase no-record-label">no video available</div>

            if (videoList.length > 0 || videoListBanner.length > 0) {

                //videoListHtml = <div className="mb-5"><ViewOutputHtml videoId={videoList[0].id} title={videoList[0].title} code={videoList[0].link.split('/').pop()} width="100%" classAttr="video-height__large" handleOnClickVideoIframe={handleOnClickVideoIframe} videoKeyPlaying={videoKeyPlaying} videoUrl={videoUrl} /></div>

                returnHtml = <div className="mb-5 text-lowercase">
                    {
                        videoListBanner.map(banner =>
                            <ViewOutputHtml videoId={banner.id} title={banner.title} code={banner.link.split('/').pop()} width="100%" classAttr="video-height__large mb-4" handleOnClickVideoIframe={handleOnClickVideoIframe} videoKeyPlaying={videoKeyPlaying} videoUrl={videoUrl} />
                        )
                    }
                    <div className="form-col-2 ">
                        {
                            videoList.map(video =>
                                <ViewOutputHtml key={video.id} videoId={video.id} title={video.title} code={video.link.split('/').pop()} classAttr="video-height__small" handleOnClickVideoIframe={handleOnClickVideoIframe} videoKeyPlaying={videoKeyPlaying} videoUrl={videoUrl} />
                            )
                        }
                    </div>
                </div>

                returnHtml = <div className='appvideo-container mx-auto'>{returnHtml}</div>
            }


        }



        return returnHtml;
    }


    const triggerSetting = () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if (sesstionLoginType !== null) {
            sesstionLoginType = parseInt(sesstionLoginType);

            if (sesstionLoginType === 2) {
                dispatch(showDepartmentSettingsReducer(new Date()));
            } else {
                dispatch(showMainSettingsReducer(new Date()));
            }

        } else {
            dispatch(showMainSettingsReducer(new Date()));
        }

    }

    const handleClickCopyLink = () => {
        Utilities.copyContent('sort_link_url');
        setShowShareTooltip(true);
        setTimeout(() => {
            setShowShareTooltip(false);
        }, 1000);
    }

    const handleClickCopyCode = () => {

        //${ApiUrls.deepLink}/${ btoa("comp_connect_poup/"+profileSelected.account_id) }/${Utilities.removeSpecialChar(profileSelected.name??'')}

        if (Object.keys(userLoggedinDetail).length > 0) {

            const companyDetail = userLoggedinDetail.company_detail ?? {};

            if (Object.keys(companyDetail).length > 0) {

                const urlShare = `${ApiUrls.deepLink}/${btoa("comp_connect_poup/" + companyDetail.account_id)}/${Utilities.removeSpecialChar(companyDetail.name ?? '')}`
                Utilities.copyContentDirect(`<link rel="stylesheet" href="${SITE_DOMAIN}/assets/css/floating-app/style.min.css" /><a href="${urlShare}" class="beonchatapp-float" target="_blank"></a>`);

                setShowCodeTooltip(true);
                setTimeout(() => {
                    setShowCodeTooltip(false);
                }, 1000);
            }
        }

    }

    const marketingListingTemplate = (key = '', detail = '') => {

        let returnHtml = <></>
        let floatingHtml = <></>
        if (!Utilities.isEmpty(detail)) {

            let fileExt = detail.split('.').slice(-1)[0];

            if (key === 'sort_link_url') {
                returnHtml = <div className="text-center font-gotham-bold fs-18 py-3 bg-gray px-4 border-radius w-100">{detail}<input id="sort_link_url" type="text" className="d-none" value={detail} /></div>


            } else if (key === 'image_link') {
                returnHtml = <img src={`${IMG_PATH}/logo/beonchat-and-initial-logo.svg`} width={120} />

                if (parseInt(userLoggedinDetail.account_type ?? 0) > 1) {
                    floatingHtml = <div className="btn-tool-copy btn-code-copy cursor-pointer position-relative" onClick={() => handleClickCopyCode()}>
                        floating icon
                        <LinkCopiedTooltip boolShow={showCodeTooltip} text={`code copied!`} />
                    </div>
                }


            } else {
                if (Utilities.validate_image_ext(fileExt)) {
                    returnHtml = <img src={detail} width={120} />

                } else if (Utilities.validate_document_ext(fileExt)) {
                    returnHtml = <img src={`${IMG_PATH}/icons/gray/outline-file-doc.svg`} width={120} />

                } else {
                    returnHtml = <img src={`${IMG_PATH}/icons/gray/outline-file-default.svg`} width={120} />
                }

            }


            returnHtml = <div className={`${key === 'sort_link_url' ? 'link-tool' : ''}`}>
                <div className='tool-wrap pb-4'>{returnHtml}</div>
                <div className={`fs-12-important pt-3 d-flex align-items-center justify-content-around`}>
                    {
                        key === 'sort_link_url' ?
                            <div className="btn-tool-copy cursor-pointer position-relative" onClick={() => handleClickCopyLink()}>
                                copy link
                                <LinkCopiedTooltip boolShow={showShareTooltip} />
                            </div>
                            :
                            <>
                                <a href={Utilities.isEmpty(detail) ? '#' : `${ApiUrls.awsDownloadImage}${detail}`} onClick={() => unableToDownloadMsg(detail)} className="btn-tool-download d-block" target={`_blank`} download={key} >download</a>
                                {floatingHtml}
                            </>
                    }
                </div>
            </div>
        }

        return returnHtml;
    }


    const unableToDownloadMsg = (detail = '') => {
        if (Utilities.isEmpty(detail)) {
            Utilities.messagePopup('', TitlesLabels.general.unableToDownload);

        }/* else{
            let fileExt = detail.split('.').slice(-1)[0];
            if ( Utilities.validate_image_ext(fileExt) ){
                downloadImage(detail);
            }
        } */
    }

    /* const downloadImage = (url) => {
        fetch(url, {
          mode : 'no-cors',
        })
          .then(response => response.blob())
          .then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = url.replace(/^.*[\\\/]/, '');
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
    } */


    const handleCloseVideoPopUp = () => {
        setShowVideoPopup(false);
        setVideoYoutubeCodePopup('');
    }


    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.importantInformation}`));

        PagesAuthentication.getUserLoggedin();

        let headerType = 1;
        let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        setHeaderTypeCurrent(loginType === null ? 0 : 1);

        if (loginType !== null) {
            loginType = JSON.parse(loginType);
            loginType = parseInt(loginType);
            headerType = loginType === 2 ? 5 : 1;
        }

        if (headerType === 1 && loginType !== null) {
            setSubjectListSelection({ ...subjectListSelection, ...{ 3: 'talk to team' } });
        }

        dispatch(showHeader(headerType));
        dispatch(showFooter(0));

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        setUserLoggedinDetail(userLoginDetailTemp ?? {});

    }, []);

    useEffect(() => {

        let segment = Utilities.getUrlSegments();
        let lastUrlSlug = segment.pop();
        lastUrlSlug = lastUrlSlug.toLowerCase();

        if (lastUrlSlug === 'terms-privacy') {
            handleTabChange(3);
        } else if (lastUrlSlug === 'licenses') {
            handleTabChange(4);
        } else if (lastUrlSlug === 'suggestion') {
            handleTabChange(1);
            setChildTabActive(1);
        } else if (lastUrlSlug === 'issues') {
            handleTabChange(1);
            setChildTabActive(2);
        } else if (lastUrlSlug === 'videos') {
            handleTabChange(7);
        } else {
            handleTabChange(1);
            setChildTabActive(1);
        }

    }, [window.location.pathname]);

    return (
        <div className="important-information-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{TitlesLabels.settings}</div>
                            <div className="current">{siteTitle} assist</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            {/* <div className="btn-icon btn-video" onClick={ () => dispatch( showPopupVideoReducer( Utilities.homeVideoUrl ) ) }  title="video"></div> */}
                            {headerTypeCurrent === 1 && <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>}
                        </li>
                    </ul>
                </div>

            </div>


            <div className="main-content">

                <div className="content-subnav-wrap height-inherit-important">
                    <div className="container">
                        <ul className="list-tab--assist fs-16 text-lowercase text-center line-height-1-2">
                            <li className={`tab-assist-icon talk-icon ${parentTabActive === 1 ? 'active' : ''}`} onClick={() => handleTabChange(1)}>talk to us<br /><div className='opacity-0'>talk to us</div></li>
                            <li className={`tab-assist-icon videos-icon ${parentTabActive === 7 ? 'active' : ''}`} onClick={() => handleTabChange(7)}>{siteTitle}<br />videos</li>
                            <li className={`tab-assist-icon horn-icon ${parentTabActive === 6 ? 'active' : ''}`} onClick={() => handleTabChange(6)}>marketing<br />tools</li>
                            <li className={`tab-assist-icon question-icon ${parentTabActive === 2 ? 'active' : ''}`} onClick={() => handleTabChange(2)}>help<br />center</li>
                            <li className={`tab-assist-icon terms-icon ${parentTabActive === 3 ? 'active' : ''}`} onClick={() => handleTabChange(3)}>privacy<br />policy</li>
                            <li className={`tab-assist-icon ribbon-icon ${parentTabActive === 4 ? 'active' : ''}`} onClick={() => handleTabChange(4)}>end user<br />license agreement</li>
                            {/* <li className={`tab-assist-icon globe-icon ${ parentTabActive===5 ? 'active' : '' }`} onClick={ () => handleTabChange(5) }>{siteTitle}.com</li> */}
                        </ul>
                    </div>
                </div>

                <div className="container">



                    <div className={`col-md-${[3, 4, 6, 7].includes(parentTabActive) ? '10' : '8'} mx-auto mt-5 list-tab-content`}>
                        {htmlTabContent()}
                        {
                            parentTabActive === 6 &&
                            <>
                                {
                                    Object.entries(marketingList).length === 0 ?
                                        <div className="text-center text-lowercase no-record-label">{TitlesLabels.general.noRecordAvailable}</div>
                                        :
                                        <ul className="ul-marketing-tools ul-grid-3">
                                            {
                                                Object.entries(marketingList).map(([key, row]) =>
                                                    <li key={key}>{marketingListingTemplate(key, row)}</li>
                                                )
                                            }
                                        </ul>
                                }
                            </>

                        }
                    </div>

                </div>
            </div>


            <VideoPopup showPopup={showVideoPopup} handleClosePopUp={handleCloseVideoPopUp} youtubeCode={videoYoutubeCodePopup} />

        </div>
    )
}

export default BizcomAssist;


const ViewOutputHtml = (props) => {
    return <div key={props.videoId ?? 0} className="pb-3 position-relative">
        {
            parseInt(props.videoKeyPlaying) !== parseInt(props.videoId) &&
            <div className='position-absolute pt-3 pl-3 d-flex align-items-center text-white'>
                {/* <div className="font-gotham-book fs-18 fs-md-18 mt-1 ellipsis-1 pl-3">{ props.title }</div> */}
            </div>
        }

        <div className={`bg-black overflow-hidden ${props.classAttr ?? ''} d-flex align-items-center justify-content-center`} style={{ borderRadius: 10 }} onClick={() => props.handleOnClickVideoIframe(parseInt(props.videoId ?? -1), props.classAttr ?? '', props.code ?? '')}>
            {
                parseInt(props.videoKeyPlaying) === parseInt(props.videoId) ?
                    <iframe width={`${props.width ?? '100%'}`} className={`${props.classAttr ?? ''}`} src={props.videoUrl ?? ''} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderRadius: 10, backgroundColor: "#000000" }} ></iframe>
                    :
                    <>
                        {
                            (props.classAttr ?? '') === "video-height__large" ?
                                <div style={{ backgroundImage: "url('" + process.env.REACT_APP_img_folder + "/video-cover.png')", backgroundRepeat: `no-repeat`, backgroundSize: `cover` }} className='h-100 w-100 bg-white border overflow-hidden border-theme-grey'>

                                </div>
                                :
                                <div className='text-center text-white'>
                                    <img src={`${process.env.REACT_APP_img_folder}/icons/green/outline-video-thin.svg`} width={40} />
                                    <div className="font-gotham-book fs-20 fs-md-18 mt-2 ellipsis-1 pl-3">{props.title}</div>
                                </div>
                        }
                    </>

            }

        </div>
        {/* <div className="font-gotham-book fs-14-important mt-1 ellipsis-1">{ props.title }</div> */}
    </div>
}