import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import axios from 'axios';
import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import TitlesLabels from '../../helpers/TitlesLabels';
import { uploadProfileLogo } from '../../helpers/AWSUpload';

import { showHeader, showFooter, pageTitle, reloadProfile, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';

import FieldInput from '../../components/FieldInput';
import { blockWordsAndEmailsValidation } from '../../helpers/VerificationUtilities';
//import PasswordInput from '../../components/PasswordInput';


const WorkForm = ({ match }) => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const limitCharacters = Utilities.companyAboutWordsLimit;

    let defaultFormValues = {
        team_id: 0,
        team_first_name: '',
        team_last_name: '',
        team_email: '',
        //password : '',
        //password_confirm : '',

        team_department: '',
        team_position: '',

        team_department_assign: TitlesLabels.general.noDepartmentAssigned,

        team_internal_chat_status: 0,
        team_is_listing: 0,
        team_external_availability: 0,

        team_contact_number_code_id: 0,
        team_contact_number_code: '',
        team_contact_number: '',
        team_contact_number_status: 0,

        team_is_admin: 0,
        team_about: ''
    }

    const [formValues, setFormValues] = useState(defaultFormValues);

    const [domainName, setDomainName] = useState('');
    const [logoProfile, setLogoProfile] = useState('');
    const [profileLogoValue, setProfileLogoValue] = useState('');
    const [companyAccount, setCompanyAccount] = useState({});
    const [profileAccount, setProfileAccount] = useState({});
    const [contactCountryDetail, setContactCountryDetail] = useState({});


    const [showAboutSection, setShowAboutSection] = useState(false);
    const [aboutContentPrev, setAboutContentPrev] = useState('');

    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }


    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfile(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'team_first_name') {
            formValuesTemp = { ...formValuesTemp, ...{ team_first_name: value } };
        } else if (fieldName === 'team_last_name') {
            formValuesTemp = { ...formValuesTemp, ...{ team_last_name: value } };
        } else if (fieldName === 'team_email') {
            formValuesTemp = { ...formValuesTemp, ...{ team_email: value } };
        } else if (fieldName === 'password') {
            formValuesTemp = { ...formValuesTemp, ...{ password: value } };

        } else if (fieldName === 'password_confirm') {
            formValuesTemp = { ...formValuesTemp, ...{ password_confirm: value } };

        } else if (fieldName === 'team_department') {
            formValuesTemp = { ...formValuesTemp, ...{ team_department: value } };
        } else if (fieldName === 'team_position') {
            formValuesTemp = { ...formValuesTemp, ...{ team_position: value } };
        }

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }

    const pushValueVisiblity = (fieldName) => {

        if (!Utilities.isEmpty(fieldName)) {

            let formValuesTemp = formValues;
            if (fieldName === 'team_is_listing') {
                let valueTemp = (parseInt(formValuesTemp.team_is_listing) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_is_listing: valueTemp } };

            } else if (fieldName === 'team_internal_chat_status') {
                let valueTemp = (parseInt(formValuesTemp.team_internal_chat_status) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_internal_chat_status: valueTemp } };

            } else if (fieldName === 'team_external_availability') {
                let valueTemp = (parseInt(formValuesTemp.team_external_availability) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_external_availability: valueTemp } };

            }

            setFormValues(formValuesTemp);
        }

    }


    const submitTeam = async (e) => {
        e.preventDefault();

        let loginPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginPersonalInfo !== null) {

            loginPersonalInfo = JSON.parse(loginPersonalInfo);
            let profileLoggedinTemp = profileAccount;

            const button_name = 'btn-submit-member';
            Utilities.elementDisabledStatus(button_name, false);

            const formData = new FormData();
            formData.append('team_id', formValues.team_id);
            formData.append('company_id', companyAccount.account_id);
            formData.append('domain', domainName);

            let errorCounter = 0, responseChecker = false;
            const fieldsChecker = ['team_first_name', 'team_last_name', 'team_department', 'team_position', 'team_is_admin'];

            Object.entries(formValues).map(([key, value]) => {
                if (fieldsChecker.includes(key)) {

                    if (Utilities.isEmpty(value)) {
                        //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                        Utilities.addFormGroupClass(key);
                        errorCounter++;

                    } else {

                        if (key === 'team_email') {
                            if (Utilities.checkSpecialChar(formValues.team_email)) {
                                //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                                Utilities.addFormGroupClass(key);
                                errorCounter++;
                            } else {
                                //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                                Utilities.removeFormGroupClass(key);
                                formData.append(key, value);
                            }

                        } else if (['team_first_name', 'team_last_name'].includes(key)) {
                            responseChecker = Utilities.inputCharacterChecker(value, 'general');
                            if (responseChecker === false) {
                                //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                                Utilities.addFormGroupClass(key);
                                errorCounter++;
                            } else {
                                //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                                Utilities.removeFormGroupClass(key);
                                formData.append(key, value);
                            }
                        } else {
                            //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            Utilities.removeFormGroupClass(key);
                            formData.append(key, value);
                        }
                    }
                }
            });

            if (!Utilities.isEmpty(formValues.password)) {

                /* if ( formValues.password.length<=5){
                    Utilities.messagePopup('Error', Utilities.labelPasswordFiveCharacters );
                    return false;
                } */

                const passwordValidation = Utilities.passwordValidation(formValues.password);
                if (passwordValidation.response === false) {
                    //jQuery('[name="password"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(`password`);
                    return false;
                }

                if (formValues.password !== formValues.password_confirm) {
                    Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame);
                    return false;
                }

                formData.append('password', formValues.password);
            }

            if (errorCounter > 0) { return false; }


            // RESTRICTED WORDS VALIDATION
            // TYPES : 1=>email, 2=>name, 3=>keywords
            let wordsRequestArray = [];
            //wordsRequestArray.push(formValues.team_email);
            wordsRequestArray.push(formValues.team_first_name);
            wordsRequestArray.push(formValues.team_last_name);

            Utilities.elementDisabledStatus(button_name, true);
            const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_nameEmail);
                return false;
            }

            // END RESTRICTED WORDS VALIDATION

            formData.append('team_contact_number', formValues.team_contact_number || '');
            formData.append('team_contact_number_code_id', formValues.team_contact_number_code_id || 0);
            formData.append('team_contact_number_status', formValues.team_contact_number_status || 0);

            formData.append('team_internal_chat_status', formValues.team_internal_chat_status);
            formData.append('team_is_listing', formValues.team_is_listing);
            formData.append('team_external_availability', formValues.team_external_availability);
            //formData.append('team_external_availability', parseInt(formValues.team_external_availability)===0 ? 1 : 0 );
            //formData.append('team_external_availability', parseInt(formValues.team_is_admin)===1 ? 0 :  formValues.team_external_availability );
            formData.append('team_about', formValues.team_about ?? '');

            //console.log(formValues.team_external_availability);

            formData.append('company_category_id', companyAccount.category_id);
            formData.append('country_id', companyAccount.country_id);
            formData.append('city_id', companyAccount.city_id);
            formData.append('area_id', companyAccount.area_id);
            formData.append('state_id', companyAccount.state_id);


            const logo_value = document.getElementById('input_select_file').value;
            if (!Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue)) {
                Utilities.elementDisabledStatus(button_name, true);

                const formLogoData = new FormData();
                formLogoData.append('account_id', formValues.team_id);
                formLogoData.append('file', profileLogoValue);

                const responseUpload = await uploadProfileLogo(formLogoData);
                Utilities.elementDisabledStatus(button_name, false);

                if (parseInt(responseUpload.status) === 1) {
                    profileLoggedinTemp = { ...profileLoggedinTemp, team_display_pic: responseUpload.file_detail.filename, logo_url: responseUpload.file_detail.url_thumb };

                    setLogoProfile(responseUpload.file_detail.url_thumb);
                }
            }


            Utilities.elementDisabledStatus(button_name, true);

            await axios
                .post(ApiUrls.teamAddUpdateDetail, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {

                    Utilities.elementDisabledStatus(button_name, false);


                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus > 0) {
                            Utilities.messagePopup('success', TitlesLabels.alertMessages.profileUpdatedSuccessfully);
                            setAboutContentPrev(formValues.team_about);

                            const team_contact_number_code = formValues.hasOwnProperty('team_contact_number_code') ? parseInt(formValues.team_contact_number_code || '') : '';
                            profileLoggedinTemp = {
                                ...profileLoggedinTemp,
                                team_first_name: formValues.team_first_name,
                                team_last_name: formValues.team_last_name,
                                team_department: formValues.team_department,
                                team_position: formValues.team_position,

                                team_internal_chat_status: parseInt(formValues.team_internal_chat_status || 0),
                                team_is_listing: parseInt(formValues.team_is_listing || 0),
                                team_external_availability: parseInt(formValues.team_external_availability || 0),
                                team_email: formValues.team_email + '@' + domainName,
                                team_is_admin: formValues.team_is_admin,
                                team_about: formValues.team_about,

                                team_contact_number_code_id: formValues.hasOwnProperty('team_contact_number_code_id') ? parseInt(formValues.team_contact_number_code_id || 0) : 0,
                                team_contact_number_code: !Utilities.isEmpty(team_contact_number_code) ? '+' + team_contact_number_code : '',
                                team_contact_number: formValues.hasOwnProperty('team_contact_number') ? parseInt(formValues.team_contact_number || '') : '',
                                team_contact_number_status: formValues.hasOwnProperty('team_contact_number_status') ? parseInt(formValues.team_contact_number_status || 0) : 0,
                            };

                            if (parseInt(profileAccount.account_id) === parseInt(loginPersonalInfo.account_id)) {
                                localStorage.setItem(
                                    process.env.REACT_APP_session_login,
                                    JSON.stringify(profileLoggedinTemp)
                                );
                            }

                            setFormValues({ ...formValues, password: '', password_confirm: '' });

                            // UPDATE PROFILE IN LIST 
                            Utilities.updateProfileInAccountList(profileLoggedinTemp);
                            dispatch(reloadProfile(Date.now()));
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    Utilities.elementDisabledStatus(button_name, false);
                });


        }
    }

    const getTeamDetail = async (id) => {

        if (parseInt(id) > 0) {
            await axios
                .post(ApiUrls.teamDetail, { id: id })
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        if (parseInt(dataResponse.status) === 1) {

                            const dataDetail = dataResponse.detail;
                            setContactCountryDetail(dataResponse.country_contact_detail ?? {});

                            /* let team_external_availability = dataDetail.hasOwnProperty('team_external_availability') ? parseInt(dataDetail.team_external_availability || 0) : 0;
                            if ( parseInt(dataDetail.team_is_admin || 0)===1 ){
                                team_external_availability = 0;
                            } */

                            let formValuesTemp = formValues, team_contact_number_code = dataDetail.hasOwnProperty('team_contact_number_code') ? parseInt(dataDetail.team_contact_number_code || '') : '';
                            formValuesTemp = {
                                ...formValues,
                                team_id: dataDetail.account_id,
                                team_first_name: dataDetail.team_first_name,
                                team_last_name: dataDetail.team_last_name,
                                team_department: (typeof dataDetail.team_department === 'undefined') ? '' : dataDetail.team_department,
                                team_position: dataDetail.team_position,
                                team_email: dataDetail.team_email.toString().split('@')[0],
                                team_internal_chat_status: parseInt(dataDetail.team_internal_chat_status || 0),
                                team_is_listing: parseInt(dataDetail.team_is_listing || 0),
                                team_external_availability: parseInt(dataDetail.team_external_availability || 0),
                                team_is_admin: parseInt(dataDetail.team_is_admin || 0),
                                team_about: dataDetail.team_about ?? '',

                                team_contact_number_code_id: dataDetail.hasOwnProperty('team_contact_number_code_id') ? parseInt(dataDetail.team_contact_number_code_id || 0) : 0,
                                team_contact_number_code: !Utilities.isEmpty(team_contact_number_code) ? '+' + team_contact_number_code : '',
                                team_contact_number: dataDetail.hasOwnProperty('team_contact_number') ? parseInt(dataDetail.team_contact_number || '') : '',
                                team_contact_number_status: dataDetail.hasOwnProperty('team_contact_number_status') ? parseInt(dataDetail.team_contact_number_status || 0) : 0,
                            }

                            setFormValues(formValuesTemp);

                            let profileDetail = Utilities.formatProfileDetail(dataDetail);
                            setLogoProfile(profileDetail['logo_url']);
                        }
                    }
                })
                .catch((err) => {

                });
        }
    }

    const submitTeamAbout = () => {
        const charCounter = Utilities.wordsCounter(formValues.team_about);
        if (parseInt(charCounter) > limitCharacters) {
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!`);
            return false;
        }

        setShowAboutSection(false);
    }

    const setupForm = (reqID) => {
        reqID = parseInt(reqID);

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {

                Object.entries(accountsList).map(([acctid, acctdetail]) => {
                    const account_id = parseInt(acctdetail.account_id);
                    if (account_id === reqID) {

                        let accountProfile = Utilities.formatProfileDetail(acctdetail);
                        let team_external_availability = accountProfile.hasOwnProperty('team_external_availability') ? parseInt(accountProfile.team_external_availability || 0) : 0;
                        if (parseInt(accountProfile.team_is_admin || 0) === 1) {
                            team_external_availability = 0;
                        }

                        accountProfile = { ...accountProfile, team_external_availability: team_external_availability.toString() };
                        setProfileAccount(accountProfile);

                        const companyDomainRequest = Utilities.isEmpty(accountProfile.company_detail.company_display_name) ? accountProfile.company_detail.company_name : accountProfile.company_detail.company_display_name;
                        let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                        domainNameTemp = domainNameTemp.toString().toLowerCase();
                        setDomainName(domainNameTemp);

                        let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                        setCompanyAccount(profileCompanyTemp);

                        getTeamDetail(account_id);

                    }
                });

            }

        }
    }

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }

    const setUpAboutContent = () => {
        setAboutContentPrev(formValues.team_about);
        setShowAboutSection(true);

        setTimeout(() => {
            jQuery('textarea#team_about').focus();
        }, 200);
    }

    const cancelAboutContent = () => {
        setFormValues({ ...formValues, team_about: aboutContentPrev });
        setShowAboutSection(false);
    }



    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        let reqAccountID = match.params.mid;
        if (typeof reqAccountID !== 'undefined') {
            reqAccountID = atob(reqAccountID);

            if (parseInt(reqAccountID) > 0) {
                setupForm(reqAccountID);
            }
        }

    }, [match.params.mid]);


    useEffect(() => {
        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.workProfile}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

    }, []);

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="cursor-pointer" onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            <div className="current">{TitlesLabels.navigations.manageWorkProfile}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>

                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container pt-5">

                    {
                        showAboutSection === true ?
                            <div className="col-md-10 mx-auto pt-1 form-theme text-lowercase">
                                <div className="form-group">
                                    <textarea id="team_about" name="team_about" className="form-control" rows="11" placeholder=" " value={formValues.team_about} onChange={(e) => setFormValues({ ...formValues, ...{ team_about: e.target.value } })}></textarea>
                                    <label className="form-label">about</label>
                                </div>

                                <div className="d-flex align-items-center justify-content-center text-center text-color-gray fs-13-important">
                                    <div>
                                        <div>maximum {Utilities.number_format(limitCharacters.toString())} words</div>
                                        <div className="text-color-blue cursor-pointer" onClick={() => setFormValues({ ...formValues, team_about: '' })}>clear</div>
                                    </div>
                                </div>

                                <div className="mt-5 button-group-boolean  col-md-5 mx-auto ">
                                    <button type="button" className="btn-theme-black" onClick={() => cancelAboutContent()}>cancel</button>
                                    <button type="button" className="btn-theme-black" onClick={() => submitTeamAbout()}>submit</button>
                                </div>
                            </div>
                            :
                            <form id="form-work-detail" className="col-md-10 mx-auto form-theme text-lowercase mt-n3" autoComplete="off">

                                <input type="file" id="input_select_file" name="file" className="invisible" accept={Utilities.attributeImageAcceptExtension} readOnly onChange={(e) => previewImage(e)} ></input>

                                <div className={`form-team-wrapper`}>


                                    <div className="avatar-wrap avatar-md-wrap">
                                        <div className={`avatar-preview  border logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage: `url(${logoProfile})` }} ></div>

                                        <div className='profile-logo-change text-center'>
                                            <button type="button" className="btn-select-file bg-theme-green font-gotham-bold border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2 px-4" onClick={() => selectImage()}>change</button>
                                            <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                                        </div>

                                    </div>
                                    <div className="pt-4"></div>

                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <FieldInput inputName="team_first_name" inputLabel="First Name" inputValue={formValues.team_first_name} pushValue={pushValue} checkerType="general" />
                                        </div>
                                        <div className='col-md-6'>
                                            <FieldInput inputName="team_last_name" inputLabel="Last Name" inputValue={formValues.team_last_name} pushValue={pushValue} checkerType="general" />
                                        </div>

                                        {/* <div className='col-md-6'>
                                        <div className="form-group optional">
                                            <input type="text" name="team_email" className="form-control" placeholder=" " disabled={`${parseInt(formValues.team_is_admin)===1 ? '' : 'disabled'}`} autoComplete="new-password" value={ formValues.team_email }  onChange={ (e) => pushValue( e, 'team_email') } ></input>
                                            <label className="form-label">Email</label>
                                            <div className="optional-label">@{ domainName }</div>
                                            
                                        </div>
                                    </div> */}

                                        <div className='col-md-6'>
                                            {/* <PasswordInput inputName="password" inputLabel="Password"  inputValue={ formValues.password } pushValue={pushValue} showChecker={true}/>

                                        <PasswordInput inputName="password_confirm" inputLabel="Confirm Password"  inputValue={ formValues.password_confirm } pushValue={pushValue} /> */}

                                            <div className="form-group">
                                                <input type="text" name="team_department" className="form-control" placeholder=" " autoComplete="off" disabled={`${parseInt(formValues.team_is_admin) === 1 ? '' : 'disabled'}`} value={formValues.team_department} onChange={(e) => pushValue(e, 'team_department')} ></input>
                                                <label className="form-label">Department</label>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>

                                            <div className="form-group ">
                                                <input type="text" name="team_position" className="form-control" placeholder=" " autoComplete="off" disabled={`${parseInt(formValues.team_is_admin) === 1 ? '' : 'disabled'}`} value={formValues.team_position} onChange={(e) => pushValue(e, 'team_position')} ></input>
                                                <label className="form-label">Company Position</label>
                                            </div>

                                        </div>

                                        {
                                            !Utilities.isEmpty(formValues.team_contact_number || '') ?
                                                <div className='col-md-6'>
                                                    <div className="form-group active cursor-notallow optional has-off-on">
                                                        <div className="d-flex form-group-mobile">
                                                            {
                                                                !Utilities.isEmpty(formValues.team_contact_number_code) &&
                                                                <div className="flag-code">
                                                                    <span className={`flag-icon flag-icon-${contactCountryDetail.hasOwnProperty('country_short_code') ? contactCountryDetail.country_short_code.toLowerCase() : ''} mr-2`}></span>
                                                                    <span className="fs-14">{formValues.team_contact_number_code}</span>
                                                                </div>
                                                            }

                                                            <input type="text" name="mobile_number" className="form-control" placeholder=" " value={`${formValues.team_contact_number}`} disabled autoComplete="off"  ></input>
                                                        </div>
                                                        <label className="form-label">Work mobile number</label>
                                                        <div className="optional-label position-placeholder">(Optional)</div>

                                                        <input type="checkbox" className="checkbox-onoff  cursor-notallow" value={formValues.team_contact_number_status} checked={formValues.team_contact_number_status === 1}></input>
                                                    </div>
                                                </div>
                                                :
                                                <div className='col-md-6'>
                                                    <div className="form-group cursor-notallow has-off-on">
                                                        <input type="text" name="mobile_number" className="form-control" placeholder=" " autoComplete="off" disabled ></input>
                                                        <label className="form-label">Work mobile number</label>

                                                        <input type="checkbox" className="checkbox-onoff  cursor-notallow" value={formValues.team_contact_number_status} checked={formValues.team_contact_number_status === 1}></input>
                                                    </div>
                                                </div>
                                        }


                                        {
                                            parseInt(formValues.team_is_admin) === 1 &&
                                            <div className='col-md-6'>
                                                <div className="form-group optional active">
                                                    <div className="form-control" onClick={() => setUpAboutContent()}>view</div>
                                                    <label className="form-label">profile info</label>
                                                    <div className="optional-label">Optional</div>
                                                </div>

                                            </div>
                                        }

                                    </div>


                                    {
                                        parseInt(formValues.team_is_admin) === 1 &&

                                        <div className="form-group active border-bottom-0  mt-2">
                                            <div className="form-control">
                                                <div className="d-flex mt-2 checkbox-radio-default align-items-center">
                                                    <div className="align-items-center d-flex"><input type="checkbox" className='border-0' name="listing" value={formValues.team_internal_chat_status} onChange={() => pushValueVisiblity('team_internal_chat_status')} checked={formValues.team_internal_chat_status === 1} ></input><span className="ml-2">Internal</span></div>
                                                    <div className="ml-4 align-items-center d-flex"><input type="checkbox" className='border-0' name="external" value={formValues.team_is_listing} onChange={() => pushValueVisiblity('team_is_listing')} checked={formValues.team_is_listing === 1} ></input><span className="ml-2">External</span></div>

                                                    <div className="ml-4 align-items-center d-flex opacity-05 cursor-notallow"><input type="checkbox" className='border-0 cursor-notallow' disabled name="external" value={formValues.team_external_availability} checked={formValues.team_external_availability === 1} ></input><span className="ml-2">Disable work chat</span></div>

                                                    {/* <div className="ml-4 align-items-center d-flex"><input type="checkbox" className='border-0'  name="external"  value={ formValues.team_external_availability }   onChange={ () => pushValueVisiblity('team_external_availability') } checked={formValues.team_external_availability===1 } ></input><span className="ml-2">Disable work chat</span></div> */}

                                                </div>
                                            </div>
                                            <label className="form-label">Allow Visibility</label>
                                        </div>

                                    }

                                </div>

                                <div className="button-wrap text-lowercase text-center col-md-5 mx-auto my-5 pt-4">
                                    <button type="button" id="btn-submit-member" className=" btn-theme-black text-lowercase button-has-loader" onClick={(e) => submitTeam(e)} >Update</button>
                                </div>

                            </form>

                    }

                </div>
            </div>
        </div>

    )
}

export default WorkForm;