import React, { useState, useEffect, memo } from 'react';
import { Dropdown } from 'react-bootstrap';
import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';

const MobileSelection = (props) => {

    const propsAlignment = props.alignment ?? '';
    const propMobileNumberWidth = props.mobileNumberWidth ?? '';
    const propsExtraClasses = props.extraClasses ?? '';
    const propShowPlaceholder = props.showPlaceholder ?? true;
    const propPlaceholderExtra = props.placeholderExtra ?? '';
    const propsInputClass = props.inputClass ?? '';
    const propsContentClass = props.contentClass ?? '';
    const propsMinNumber = props.minNumber ?? 6;
    const propsMaxNumber = props.maxNumber ?? 11;

    const [countryList, setCountryList] = useState([]);
    const [defaultFlag, setDefaultFlag] = useState('');

    const [currentCountryCode, setCurrentCountryCode] = useState('');
    const [defaultSearchValue, setDefaultSearchValue] = useState('');

    const [showMobileMsg, setShowMobileMsg] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        setShowMobileMsg('');
        let mobile_number = value.replace(' ', '');
        //mobile_number = mobile_number.replace(/\D/g, '');

        if (!Utilities.isEmpty(mobile_number) && mobile_number.split('').length > 0) {
            if (isNaN(parseInt(mobile_number))) {
                return false;
            }

            let mobileArr = mobile_number.split('');

            if (mobileArr[0].toString() == "0") {
                mobileArr.shift();
                mobile_number = mobileArr.join('');

                jQuery(`input[name="${fieldName}"]`).val(mobile_number);
            }
        }

        if (!Utilities.isEmpty(mobile_number) && !Utilities.contactNumberValidate(mobile_number, 1, propsMinNumber, propsMaxNumber).status) {
            setShowMobileMsg(Utilities.contactNumberValidate(mobile_number, 1).msg);
        }
        props.pushValue(e, fieldName ?? '');
    }

    const focusDropdown = (input_id) => {
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();

        if (!Utilities.isEmpty(input_id)) {
            let timer = setTimeout(() => {
                jQuery('#' + input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }

    const pushValueCode = (e, input_id, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = (id = '', keyword = '') => {
        if (Utilities.isEmpty(id)) { return false; }
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#' + id + ' > .dropdown-item').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const changeCountryCode = (passValues) => {
        let defaultFlagTemp = (!Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code !== null) ? passValues.country_short_code.toLowerCase() : '';
        setDefaultFlag(defaultFlagTemp);
        setCurrentCountryCode(passValues.phonecode);

        if (typeof props.changeCountryCode !== 'undefined') {
            props.changeCountryCode(passValues, props.inputName ?? '');
        }

    }


    useEffect(() => {


    }, []);

    useEffect(() => {

        //const defaultLocation = Utilities.defaultLocation();

        const { countryListProvided, countryCodeProvided } = props;
        let countryCodeProvidedTemp = parseInt(countryCodeProvided ?? 0);


        /* if ( Object.keys(defaultLocation).length>0  ){
            countryCodeProvidedTemp = parseInt(defaultLocation.country_id);
            
        } */

        //if ( parseInt(countryListProvided.length)>0 ){
        setCountryList(countryListProvided);
        Object.entries(countryListProvided).map(([key, row]) => {
            if (parseInt(countryCodeProvidedTemp ?? 0) === parseInt(row.id ?? 0)) {
                setDefaultFlag((row.country_short_code ?? '').toLowerCase());
                setCurrentCountryCode(row.phonecode ?? 0);
            }
        });
        //}

    }, [props]);

    return (
        <div className={`${propsContentClass}`}>
            <div className={`form-group active ${propsAlignment === 'center' ? 'text-center' : ''} ${!Utilities.isEmpty(propPlaceholderExtra) ? 'optional' : ''} ${propsExtraClasses} `}>
                <div className={`form-group-mobile ${(props.showDropdownArrow ?? true) === true ? 'with-arrow' : ''}  ${propsAlignment === 'center' ? 'justify-content-center' : ''}  ${propsInputClass} `}>
                    <Dropdown className={`dropdown-country-selection ${(props.showDropdownArrow ?? true) === true ? 'show-arrow' : 'hide-arrow'} ${Utilities.isEmpty(props.dropDownClass ?? '') ? '' : props.dropDownClass}`}>
                        <Dropdown.Toggle>
                            <div className="flag-code" onClick={() => focusDropdown(props.inputListingId ?? '')}>
                                <span className={`flag-icon flag-icon-${defaultFlag} mr-2`}></span>
                                <span>{!Utilities.isEmpty(currentCountryCode) ? '+' + currentCountryCode : ''}</span>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            <div className="px-4 mb-1">
                                <input type="text" id={props.inputListingId ?? ''} className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={defaultSearchValue} onChange={(e) => pushValueCode(e, `${props.inputListingId ?? ''}-codes`, 'search-keyword')}></input>
                            </div>
                            <div id={`${props.inputListingId ?? ''}-codes`} className="country-code-list">
                                {
                                    Object.entries(countryList).map(([key, row]) =>
                                        <Dropdown.Item key={key} data-name={`${row.country_name.toLowerCase()} +${row.phonecode}`} onClick={() => changeCountryCode(row)}>
                                            <div className="flag-code text-lowercase">
                                                <span className={`flag-icon flag-icon-${(!Utilities.isEmpty(row.country_short_code) && row.country_short_code !== null) ? row.country_short_code.toLowerCase() : ''} mr-2`}></span>
                                                <span>{row.country_name}</span>
                                                <span className="text-color-gray ml-3">+{row.phonecode}</span>
                                            </div>
                                        </Dropdown.Item>
                                    )
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                    <input type="text" name={props.inputName ?? ''} className={`form-control pl-3`} placeholder={`${!propShowPlaceholder ? (props.inputLabel ?? ' ') : ' '}`} autoFocus={props.autoFocus ?? false} value={props.inputValue} onChange={(e) => pushValue(e, props.inputName ?? '')} autoComplete="off" style={{ width: propsAlignment === 'center' ? 165 : !Utilities.isEmpty(propMobileNumberWidth) ? propMobileNumberWidth : 'inherit' }} ></input>
                </div>
                {
                    propShowPlaceholder === true &&
                    <label className="form-label">
                        {props.inputLabel ?? ''}
                        {!Utilities.isEmpty(propPlaceholderExtra) && <span className="color-placholder-option-gray ml-2 mr-5">{propPlaceholderExtra}</span>}
                    </label>
                }

                {
                    props.children ?? ''
                }

            </div>

            {(!Utilities.isEmpty(showMobileMsg) && !Utilities.isEmpty(props.inputValue)) && <div className={`fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3 ${propsAlignment === 'center' ? 'text-center' : ''}`}>{showMobileMsg}</div>}
        </div>
    )
}

export default memo(MobileSelection);