import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import jQuery from 'jquery';
import Select from 'react-select';
import * as RM from 'ramda';

import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import TitlesLabels from '../../helpers/TitlesLabels';
import { uploadProfileLogo } from '../../helpers/AWSUpload';
import MapBox from '../../components/MapBox';
import FieldInput from '../../components/FieldInput';
//import PasswordInput from '../../components/PasswordInput';

import { showHeader, showFooter, pageTitle, reloadProfile, showMainSettings as showMainSettingsReducer, showPopupCompanyCategory as showPopupCompanyCategoryReducer, passCompanyCategoryDetail as passCompanyCategoryDetailReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showLoader as showLoaderReducer, showPopupSocialMediaLibrary as showPopupSocialMediaLibraryReducer } from '../../actions';
import { blockWordsAndEmailsValidation, checkCompanyMobileExistance } from '../../helpers/VerificationUtilities';
import UrlSlugs from '../../helpers/UrlSlugs';
import { getCompanyBranchesByIds } from '../../helpers/QueryCompanyBranch';
import MapSearchPopup from '../../components/popups/MapSearchPopup';
import MobileSelection from '../../components/MobileSelection';
import QueryUtilities from '../../helpers/QueryUtilities';
import { ComanySocialLinksManage } from '../../components/common/CompanySocialLinks';

const CompanyForm = ({ match }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let { mid, branchId } = useParams();
    branchId = parseInt((branchId ?? '0').toString() !== '0' ? atob(branchId) : 0);

    const select_labels = Utilities.selectLocationLabels;


    const errorMsg = process.env.REACT_APP_message_error_process;
    //const MAP_API = process.env.REACT_APP_google_map_key_api;
    const limitCharacters = Utilities.companyAboutWordsLimit;

    const passCompanyCategoryDetail = useSelector(state => state.passCompanyCategoryDetail);

    const defaultLocation = {
        country_id: 0, // process.env.REACT_APP_country_id,
        country_name: '', //process.env.REACT_APP_country_name,

        state_id: 0, //process.env.REACT_APP_state_id,
        state_name: '', //process.env.REACT_APP_state_name,

        city_id: 0, //process.env.REACT_APP_city_id,
        city_name: '', //process.env.REACT_APP_city_name,

        area_id: 0, //process.env.REACT_APP_area_id,
        area_name: '', // process.env.REACT_APP_area_name,
    };

    let defaultFormValues = {
        ...defaultLocation,

        company_id: 0,
        company_name: '',
        branch_name: '',
        company_display_name: '',
        company_email: '',
        company_website: '',
        category_name: '',
        category_id: 0,

        company_phone: '',
        company_phone_code: 0, //defaultLocationUtil.country_id,
        company_landline: '',
        company_landline_code: 0, //defaultLocationUtil.country_id,

        street_name: '',
        street_number: '',
        building_name: '',
        building_number: '',
        postal_code: '',

        latitude: '',
        longitude: '',

        company_phone_checked: 0,
        company_landline_checked: 0,

        address_visibility_status: 0
    }

    let finalSocialValues = { ...Utilities.socialLinkValues };
    let finalAppValues = { apple: '', google: '', huawei: '' };

    const formGroupKeywordElem = jQuery('#form-group-keyword');
    const coordinatesInitial = { lat: 0, lng: 0 };

    const [coordinates, setCoordinates] = useState(coordinatesInitial);
    const [mapLocationProvided, setMapLocationProvided] = useState('');
    const [mapLocationAction, setMapLocationAction] = useState(2);  // 1 - from city/suburb, 2 - by coordinates

    const selectStateRef = useRef();
    const selectCityRef = useRef();
    const selectAreaRef = useRef();


    const [formValues, setFormValues] = useState(defaultFormValues);

    const [formSocialValues, setFormSocialValues] = useState(finalSocialValues);
    const [formCompanyAppValues, setFormCompanyAppValues] = useState(finalAppValues);

    const [countryNames, setCountryNames] = useState({});
    const [profileDetail, setProfileDetail] = useState([]);
    const [companyDetail, setCompanyDetail] = useState([]);

    const [profileLogo, setProfileLogo] = useState('');
    const [profileLogoValue, setProfileLogoValue] = useState('');


    const [countries, setCountries] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);


    const [mainBranchDetail, setMainBranchDetail] = useState({});
    const [showMapSearchPopup, setShowMapSearchPopup] = useState(false);


    let defaultInfoValues = {
        company_keywords: [],
        company_about: ''
    }
    const [formInfoValues, setFormInfoValues] = useState(defaultInfoValues);

    const [showSection, setShowSection] = useState('');
    const [formInfoValuesPrev, setFormInfoValuesPrev] = useState(defaultInfoValues);


    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }

    const pushKeywordValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let elem = jQuery(`input[name="${fieldName}"]`),
            val = elem.val();

        elem.val(val.replace(' ', ''));

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
    }

    const extracyCompanyKeywords = (keywordsProvided = {}) => {
        setTimeout(() => {
            const formKeywordElem = jQuery('#form-keyword-info');
            Object.entries(keywordsProvided).map(([key, keyword]) => {
                formKeywordElem.find('input[name="keyword_' + (parseInt(key) + 1) + '"]').val(keyword);
            });
        }, 200);
    }

    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setProfileLogo(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;

        let errorCounter = 0;

        if (['country_id', 'state_id', 'city_id', 'area_id'].includes(fieldName)) {

            if (fieldName === 'country_id') {
                getStateByCountryID(e.value);

                const country_name = (typeof countryNames[e.value] !== 'undefined') ? countryNames[e.value] : select_labels.country;
                formValuesTemp = { ...formValuesTemp, ...{ country_id: e.value, country_name: country_name, state_id: 0, state_name: select_labels.state, city_id: 0, city_name: select_labels.city, area_id: 0, area_name: select_labels.area } };

            } else if (fieldName === 'state_id') {
                getCityByStateID(e.value);
                formValuesTemp = { ...formValuesTemp, ...{ state_id: e.value, state_name: e.label, city_name: select_labels.city, area_id: 0, area_name: select_labels.area } };

            } else if (fieldName === 'city_id') {
                getAreByCityID(e.value);
                formValuesTemp = { ...formValuesTemp, ...{ city_id: e.value, city_name: e.label, area_id: 0, area_name: select_labels.area } };

                const stateNameSelected = !Utilities.isEmpty(formValuesTemp.state_id) ? stateList.filter(state => parseInt(state.value) === parseInt(formValuesTemp.state_id))[0]['label'] ?? '' : '';
                const cityNameSelected = !Utilities.isEmpty(e.value) ? cityList.filter(city => parseInt(city.value) === parseInt(e.value))[0]['label'] ?? '' : '';
                if (!Utilities.isEmpty(cityNameSelected)) {
                    setMapLocationProvided(stateNameSelected + ',' + cityNameSelected);
                    setMapLocationAction(1);
                }


            } else if (fieldName === 'area_id') {
                formValuesTemp = { ...formValuesTemp, ...{ area_id: e.value, area_name: e.label } };

                const cityNameSelected = !Utilities.isEmpty(formValuesTemp.city_id) ? cityList.filter(city => parseInt(city.value) === parseInt(formValuesTemp.city_id))[0]['label'] ?? '' : '';
                const areaNameSelected = !Utilities.isEmpty(e.value) ? areaList.filter(area => parseInt(area.value) === parseInt(e.value))[0]['label'] ?? '' : '';
                if (!Utilities.isEmpty(areaNameSelected)) {
                    setMapLocationProvided(cityNameSelected + ',' + areaNameSelected);
                    setMapLocationAction(1);
                }

            }
            setFormValues(formValuesTemp);


        } else {

            let value = e.target.value;

            if (fieldName === 'company_name') {

                if (branchId > 0) {
                    formValuesTemp = { ...formValuesTemp, ...{ branch_name: value } };
                } else {
                    formValuesTemp = { ...formValuesTemp, ...{ company_name: value } };
                }


            } else if (fieldName === 'company_display_name') {
                formValuesTemp = { ...formValuesTemp, ...{ company_display_name: value } };
            } else if (fieldName === 'company_email') {
                formValuesTemp = { ...formValuesTemp, ...{ company_email: value } };
            } else if (fieldName === 'company_website') {
                formValuesTemp = { ...formValuesTemp, ...{ company_website: value } };

            } else if (fieldName === 'company_landline') {

                let company_landline = value.replace(' ', '');
                if (!Utilities.isEmpty(company_landline) && !Utilities.contactNumberValidate(company_landline)) {
                    //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(fieldName);
                    errorCounter++;
                }
                formValuesTemp = { ...formValuesTemp, ...{ company_landline: parseInt(company_landline) || '' } };

            } else if (fieldName === 'company_landline_code') {
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: value.toString().trim() } };


            } else if (fieldName === 'company_phone') {

                let company_phone = value.replace(' ', '');
                if (!Utilities.contactNumberValidate(company_phone)) {
                    //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(fieldName);
                    errorCounter++;
                }
                formValuesTemp = { ...formValuesTemp, ...{ company_phone: parseInt(company_phone) || '' } };

            } else if (fieldName === 'company_phone_code') {
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_code: value.toString().trim() } };


            } else if (fieldName === 'street_name') {
                formValuesTemp = { ...formValuesTemp, ...{ street_name: value } };
            } else if (fieldName === 'street_number') {
                formValuesTemp = { ...formValuesTemp, ...{ street_number: value } };
            } else if (fieldName === 'building_name') {
                formValuesTemp = { ...formValuesTemp, ...{ building_name: value } };
            } else if (fieldName === 'building_number') {
                formValuesTemp = { ...formValuesTemp, ...{ building_number: value } };
            } else if (fieldName === 'postal_code') {
                formValuesTemp = { ...formValuesTemp, ...{ postal_code: value } };

            } else if (fieldName === 'company_phone_checked') {
                value = parseInt(formValuesTemp.company_phone_checked) === 1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: value } };
            } else if (fieldName === 'company_landline_checked') {
                value = parseInt(formValuesTemp.company_landline_checked) === 1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: value } };

            } else if (fieldName === 'address_visibility_status') {
                value = parseInt(formValuesTemp.address_visibility_status) === 1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ address_visibility_status: value } };
            }

            setFormValues(formValuesTemp);

            if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'].includes(fieldName) && value === 1) {
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.mobileActivationWarningMessage,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                        { label: 'continue', onClick: () => confirmContactNumber(fieldName, 1) }
                    ],
                    onClickOutside: () => confirmContactNumber(fieldName, 0),
                    onKeypressEscape: () => confirmContactNumber(fieldName, 0),
                });
            }

            if (!Utilities.isEmpty(fieldName) && ['address_visibility_status'].includes(fieldName) && value === 1) {
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.companyAddressVisibilityWarningMessage,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmAddressVisibility(0) },
                        { label: 'continue', onClick: () => confirmAddressVisibility(1) }
                    ],
                    onClickOutside: () => confirmAddressVisibility(0),
                    onKeypressEscape: () => confirmAddressVisibility(0),
                });
            }
        }

        if (errorCounter === 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
        }

    }

    const confirmAddressVisibility = (status = 0) => {
        setFormValues({ ...formValues, address_visibility_status: status });
    }

    const confirmContactNumber = (fieldName = '', value = 0) => {

        if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked']) {
            let formValuesTemp = formValues;

            if (fieldName === 'company_phone_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: parseInt(value) } };

            } else if (fieldName === 'company_landline_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: parseInt(value) } };
            }

            setFormValues(formValuesTemp);
        }
    }


    const getCoordinatesByCompleteLocation = async () => {

        let formValuesTemp = formValues;

        let addressArray = [];
        //addressArray.push(formValuesTemp.building_number);
        addressArray.push((formValuesTemp.building_name ?? '').trim());
        addressArray.push((formValuesTemp.street_name ?? '').trim());

        //addressArray.push((formValuesTemp.area_name??'').trim());
        addressArray.push((formValuesTemp.city_name ?? '').trim());
        addressArray.push((formValuesTemp.state_name ?? '').trim());
        addressArray.push((formValuesTemp.country_name ?? '').trim());

        addressArray = addressArray.filter(Boolean);
        const address = addressArray.join(',');

        setMapLocationProvided(address);
        setMapLocationAction(1);
    }

    const changeCountryCodeLandline = (passValues, fieldName = '') => {

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    const changeCountryCodeMobile = (passValues, fieldName = '') => {

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_phone_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    // COUTNRIES
    const getCountries = async (setupContact = {}) => {

        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    let optionList = [];
                    const countriesList = response.data;

                    let country_names = {};
                    Object.entries(countriesList).map(([key, row]) => {
                        optionList = [...optionList, { value: row.id, label: row.country_name }];

                        country_names[row.id] = row.country_name;

                        /* if ( Object.keys(setupContact).length>0 ){

                            if ( parseInt(setupContact.company_landline_code)===parseInt(row.id) ) {
                                setDefaultFlagLandline(row.country_short_code.toLowerCase());
                                setDefaultCountryCodeLandline(row.phonecode);
                            }
        
                            if ( parseInt(setupContact.company_phone_code)===parseInt(row.id) ) {
                                setDefaultFlagMobile(row.country_short_code.toLowerCase());
                                setDefaultCountryCodeMobile(row.phonecode);
                            }
                        } */

                    });
                    setCountryList(optionList);
                    setCountries(countriesList);
                    setCountryNames(country_names);
                }
            })
            .catch((err) => { });
    }

    // STATES
    const getStateByCountryID = async (country_id = 0) => {

        if (country_id > 0) {
            await axios
                .post(ApiUrls.stateList, { id: 0, country_id: country_id })
                .then(response => {
                    if (response.status === 200) {
                        let optionList = [];
                        Object.entries(response.data).map(([key, row]) => {
                            optionList = [...optionList, { value: row.id, label: row.state_name }];
                        });
                        setStateList(optionList);
                    }
                })
                .catch((err) => {

                });
        }
    }

    // CITIES
    const getCityByStateID = async (state_id = 0) => {

        if (state_id > 0) {
            await axios
                .post(ApiUrls.cityList, { id: 0, state_id: state_id })
                .then(response => {
                    if (response.status === 200) {
                        let optionList = [];
                        Object.entries(response.data).map(([key, row]) => {
                            optionList = [...optionList, { value: row.id, label: row.city_name }];
                        });
                        setCityList(optionList);
                    }
                })
                .catch((err) => {

                });
        }
    }

    // AREA / SUBURB
    const getAreByCityID = async (city_id = 0) => {

        if (city_id > 0) {
            await axios
                .post(ApiUrls.areaList, { id: 0, city_id: city_id })
                .then(response => {
                    if (response.status === 200) {
                        let optionList = [];
                        Object.entries(response.data).map(([key, row]) => {
                            optionList = [...optionList, { value: row.area_id, label: row.area_name }]
                        });
                        setAreaList(optionList);
                    }
                })
                .catch((err) => {

                });
        }
    }


    const submitDetail = async (e) => {
        e.preventDefault();

        let formValuesTemp = formValues;
        let company_detail = profileDetail.company_detail;

        const button_name = 'btn-submit-detail';

        formGroupKeywordElem.removeClass('error');

        const formData = new FormData();
        formData.append('company_id', formValues.company_id);

        const locationFields = ['country_id', 'state_id', 'city_id', 'area_id'];
        const requiredFields = ['company_name', 'company_display_name', 'country_id', 'state_id', 'city_id', 'company_landline_code', 'company_phone_code'];

        const saveFields = ['company_name', 'company_website', 'company_email', 'company_landline', 'country_id', 'state_id', 'city_id', 'area_id', 'street_name', 'street_number', 'building_name', 'building_number', 'company_landline_code', 'company_phone_code', 'company_phone', 'postal_code', 'category_id', 'business_type', 'address_visibility_status'];


        let errorCounter = 0, responseChecker = false;
        requiredFields.map(key => {
            const value = jQuery('input[name="' + key + '"]').val();
            if (Utilities.isEmpty(value)) {
                //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(key);
                errorCounter++;
            } else {
                formData.append(key, value);
            }

            if (['company_name', 'company_display_name'].includes(key)) {
                responseChecker = Utilities.inputCharacterChecker(value, 'general-companyname');
                if (responseChecker === false) {
                    //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });

        if (errorCounter > 0) { return false; }


        saveFields.map(key => {
            const value = jQuery('input[name="' + key + '"]').val();
            formData.append(key, value);
        });

        locationFields.map(key => {
            const inputElem = jQuery('input[name="' + key + '"]');
            if (inputElem.length > 0) {
                let value = inputElem.val();
                value = (Utilities.isEmpty(value)) ? 0 : value;
                if (key === 'country_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ country_id: value } };
                } else if (key === 'state_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ state_id: value } };
                } else if (key === 'city_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ city_id: value } };
                } else if (key === 'area_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ area_id: value } };
                }

            }
        });


        let contactErrorCounter = 0;
        if (!Utilities.isEmpty(formValuesTemp.company_landline) && !Utilities.contactNumberValidate(formValuesTemp.company_landline)) {
            //jQuery('[name="company_landline"]').closest('.form-group').addClass('error');
            Utilities.addFormGroupClass(`company_landline`);
            contactErrorCounter++;
        }

        if (!Utilities.contactNumberValidate(formValuesTemp.company_phone)) {
            //('[name="company_phone"]').closest('.form-group').addClass('error');
            Utilities.addFormGroupClass(`company_phone`);
            contactErrorCounter++;
        }


        if (contactErrorCounter > 0) { return false; }


        if (formInfoValues.company_keywords.length === 0) {
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired);
            formGroupKeywordElem.addClass('error');
            return false;
        }


        // RESTRICTED WORDS VALIDATION
        // TYPES : 1=>email, 2=>name, 3=>keywords
        let wordsRequestArray = [];
        wordsRequestArray.push(formValues.company_name);
        wordsRequestArray.push(formValues.company_display_name);

        Utilities.elementDisabledStatus(button_name, true);
        let responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(responseRestrictedValidationsDB.status) === 0) {
            Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_name);
            Utilities.addFormGroupClass(`company_name`);
            return false;
        }

        // END RESTRICTED WORDS VALIDATION


        // Checking email if exist in database
        // NO need because email input is disabled and no option to change it

        /* const newMobileNumber = `${formValues.company_phone_code}${formValues.company_phone}`,
                oldMobileNumber = `${formValuesOld.company_phone_code}${formValuesOld.company_phone}`;

                //console.log(newMobileNumber, oldMobileNumber);
                
                if ( newMobileNumber!==oldMobileNumber ){

                    // Checking mobile number if exist in database
                    Utilities.elementDisabledStatus(button_name, true );
                    const responseMobileCheckingDB = await checkCompanyMobileExistance( formValues.company_phone_code, formValues.company_phone );
                    Utilities.elementDisabledStatus(button_name, false );

                    if ( Object.keys(responseMobileCheckingDB).length>0 ){
                        if ( parseInt(responseMobileCheckingDB.exist)===1 ){
                            Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist )
                            return false;
                        }
                    }
                    
                } */


        formData.append('logo', profileLogoValue);
        formData.append('company_keywords', formInfoValues.company_keywords.length > 0 ? formInfoValues.company_keywords.join('|') : '');
        formData.append('company_about', formInfoValues.company_about);

        formData.append('company_phone_checked', parseInt(formValuesTemp.company_phone_checked || 0));
        formData.append('company_landline_checked', parseInt(formValuesTemp.company_landline_checked || 0));

        formData.append('latitude', coordinates.lat ?? 0);
        formData.append('longitude', coordinates.lng ?? 0);

        let socialFieldValues = Object.entries(formSocialValues).filter(val => val).join('|');
        formData.append('company_social', socialFieldValues);


        let companyAppFieldValues = Object.entries(formCompanyAppValues).filter(val => val).join('|');
        formData.append('company_app_link', companyAppFieldValues);


        const logo_value = document.getElementById('input_select_file').value;
        if (!Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue)) {

            Utilities.elementDisabledStatus(button_name, true);

            const formLogoData = new FormData();
            formLogoData.append('account_id', formValues.company_id);
            formLogoData.append('file', profileLogoValue);

            const responseUpload = await uploadProfileLogo(formLogoData);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(responseUpload.status) === 1) {
                company_detail = { ...company_detail, company_logo: responseUpload.file_detail.filename, logo_url: responseUpload.file_detail.url_thumb };

                setProfileLogo(responseUpload.file_detail.url_thumb);

            }
        }


        // ADDED BRANCH ID
        if (branchId > 0) {
            formData.append('branch_id', branchId);
            formData.append('company_name', formValuesTemp.company_name ?? '');
            formData.append('branch_name', formValuesTemp.branch_name ?? '');
        }


        Utilities.elementDisabledStatus(button_name, true);

        await axios
            .post(ApiUrls.companyUpdateDetail, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {

                Utilities.elementDisabledStatus(button_name, false);

                if (response.status === 200) {
                    const dataResponse = response.data;
                    const dataStatus = parseInt(dataResponse.status);

                    if (dataStatus > 0) {
                        Utilities.messagePopup('success', TitlesLabels.alertMessages.profileUpdatedSuccessfully);

                        let profileDetailTemp = profileDetail;

                        company_detail = {
                            ...company_detail,
                            company_name: formValuesTemp.company_name,
                            branch_name: formValuesTemp.branch_name,
                            company_display_name: formValuesTemp.company_display_name,
                            company_email: formValuesTemp.company_email,
                            company_website: formValuesTemp.company_website,

                            company_landline: formValuesTemp.company_landline,
                            company_landline_code: formValuesTemp.company_landline_code,

                            company_phone_code: formValuesTemp.company_phone_code,
                            company_phone: formValuesTemp.company_phone,

                            country_id: formValuesTemp.country_id,
                            state_id: formValuesTemp.state_id,
                            city_id: formValuesTemp.city_id,
                            area_id: formValuesTemp.area_id,

                            country_name: formValuesTemp.country_name,
                            state_name: formValuesTemp.state_name,
                            city_name: formValuesTemp.city_name,
                            area_name: formValuesTemp.area_name,

                            street_name: formValuesTemp.street_name,
                            street_number: formValuesTemp.street_number,
                            building_name: formValuesTemp.building_name,
                            building_number: formValuesTemp.building_number,
                            postal_code: formValuesTemp.postal_code,

                            company_phonne_checked: parseInt(formValuesTemp.company_phonne_checked || 0),
                            company_landline_checked: parseInt(formValuesTemp.company_landline_checked || 0),

                            latitude: coordinates.lat,
                            longitude: coordinates.lng,
                        }

                        profileDetailTemp = {
                            ...profileDetail,
                            company_detail
                        };

                        /* localStorage.setItem(
                            process.env.REACT_APP_session_login,
                            JSON.stringify(profileDetailTemp)
                        ); */

                        setFormValues({ ...formValuesTemp, password: '', password_confirm: '' });


                        setFormSocialValues(formSocialValues);
                        setFormCompanyAppValues(formCompanyAppValues);


                        // UPDATE PROFILE IN LIST 
                        Utilities.updateProfileInAccountList(profileDetailTemp);
                        dispatch(reloadProfile(Date.now()));

                    } else if (dataStatus === -1) {
                        Utilities.messagePopup('error', Utilities.labelEmailAddressTaken);
                    } else {
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotUpdateProfile);
                    }

                } else {
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotUpdateProfile);
                }
            })
            .catch((err) => {
                Utilities.messagePopup('error', errorMsg);
                Utilities.elementDisabledStatus(button_name, false);

            });

    }


    const setupForm = async (reqID) => {
        reqID = parseInt(reqID);

        // GET WORK DETAIL
        const workProfileInfo = await QueryUtilities.getProfileDetail(reqID, 1);
        const workCompanyInfo = workProfileInfo.company_detail ?? {};

        if (Object.keys(workCompanyInfo).length > 0) {
            setProfileDetail(workProfileInfo);

            dispatch(showLoaderReducer(1));

            const profileCompanyInfo = await QueryUtilities.getProfileDetail(workCompanyInfo.account_id, 1);
            let profileCompanyTemp = profileCompanyInfo;
            setCompanyDetail(profileCompanyTemp);

            setProfileLogo(profileCompanyTemp.logo_url);

            await getStateByCountryID(profileCompanyInfo.country_id);
            await getCityByStateID(profileCompanyInfo.state_id);
            await getAreByCityID(profileCompanyInfo.city_id);

            dispatch(showLoaderReducer(0));

            profileCompanyTemp = {
                ...profileCompanyTemp,
                company_id: profileCompanyTemp.account_id,
                company_email: (profileCompanyTemp.company_email ?? '') === 'undefined' ? '' : profileCompanyTemp.company_email,

                password: '',
                password_confirm: '',

                country_id: profileCompanyInfo.country_id,
                state_id: profileCompanyInfo.state_id,
                city_id: profileCompanyInfo.city_id,
                area_id: profileCompanyInfo.area_id,

                country_name: profileCompanyInfo.country_name,
                state_name: profileCompanyInfo.state_name,
                city_name: profileCompanyInfo.city_name,
                area_name: profileCompanyInfo.area_name,

                company_phone_checked: parseInt(profileCompanyInfo.mobile_number_status),
                company_landline_checked: parseInt(profileCompanyInfo.landline_number_status),

                category_id: profileCompanyInfo.category_id,
                category_name: profileCompanyInfo.category_name,

                latitude: profileCompanyInfo.latitude,
                longitude: profileCompanyInfo.longitude,

                category_name: profileCompanyInfo.category_name,
                address_visibility_status: parseInt(profileCompanyInfo.address_visibility_status)
            };

            setFormValues(profileCompanyTemp);

            setMapLocationAction(0);

            const coordinatesAssigned = { lat: parseFloat(profileCompanyTemp.latitude), lng: parseFloat(profileCompanyTemp.longitude) };

            let timer = setTimeout(function () {
                setCoordinates(coordinatesAssigned);
                setMapLocationAction(3);
                clearTimeout(timer);
            }, 100);

            const formInfoValues = {
                company_about: profileCompanyInfo.company_about ?? '',
                company_keywords: !Utilities.isEmpty(profileCompanyInfo.keywords) ? profileCompanyInfo.keywords.split(',') : [],
            }

            setFormInfoValues(formInfoValues);

            let socialLinksTemp = { ...formSocialValues };
            if (profileCompanyInfo.hasOwnProperty('company_social')) {


                let profileSocialLinks = profileCompanyInfo.company_social ?? {};
                //profileSocialLinks = { ...profileSocialLinks, kuaishou: 'ffff' };

                Object.entries(formSocialValues).map(([skey, sval]) => {
                    socialLinksTemp[skey] = profileSocialLinks[skey] ?? '';
                });

                Object.entries(profileSocialLinks).map(([skey, sval]) => {
                    if (!formSocialValues.hasOwnProperty(skey)) {
                        socialLinksTemp[skey] = sval ?? '';
                    }
                });
            }

            setFormSocialValues(socialLinksTemp);

            if (profileCompanyInfo.hasOwnProperty('company_app_link')) {
                const appLinks = {
                    ...formCompanyAppValues,
                    apple: profileCompanyInfo.company_app_link.appstore_link ?? '',
                    google: profileCompanyInfo.company_app_link.playstore_links ?? '',
                    huawei: profileCompanyInfo.company_app_link.appgalery_links ?? ''
                };

                setFormCompanyAppValues(appLinks);
            }


            getCountries({ company_landline_code: profileCompanyTemp.company_landline_code, company_phone_code: profileCompanyTemp.company_phone_code });

        } else {
            await getStateByCountryID(defaultLocation.country_id);
            await getCityByStateID(defaultLocation.state_id);
            await getAreByCityID(defaultLocation.city_id);
        }

    }


    const handleMapChanges = async (locationArray = {}, coordinates = {}) => {

        setShowMapSearchPopup(false);

        //console.log(locationArray);
        if (Object.keys(locationArray).length > 0 && Object.keys(coordinates).length > 0) {


            let profileCompanyTemp = {
                ...formValues,
                country_id: locationArray.country_id ?? 0,
                state_id: locationArray.state_id ?? 0,
                city_id: locationArray.city_id ?? 0,
                area_id: locationArray.area_id ?? 0,

                country_name: locationArray.country_name ?? select_labels.country,
                state_name: locationArray.state_name ?? select_labels.state,
                city_name: locationArray.city_name ?? select_labels.city,
                area_name: locationArray.area_name ?? select_labels.area,

                street_name: '',
                street_number: '',
                building_name: '',
                building_number: '',
                postal_code: '',

                latitude: locationArray.latitude ?? '',
                longitude: locationArray.longitude ?? '',
            };



            dispatch(showLoaderReducer(1));

            await getStateByCountryID(profileCompanyTemp.country_id);
            await getCityByStateID(profileCompanyTemp.state_id);
            await getAreByCityID(profileCompanyTemp.city_id);

            dispatch(showLoaderReducer(0));


            setFormValues(profileCompanyTemp);


            // CHANGE MAP
            setMapLocationAction(0);
            const coordinatesValue = { lat: parseFloat(coordinates.lat || 0), lng: parseFloat(coordinates.lng || 0) };

            let timer = setTimeout(function () {
                setCoordinates(coordinatesValue);
                setMapLocationAction(3);


                clearTimeout(timer);
            }, 1000);


        }

    }



    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }

    const submitCompanyAbout = () => {
        const charCounter = Utilities.wordsCounter(formInfoValues.company_about);
        if (parseInt(charCounter) > limitCharacters) {
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!`);
            return false;
        }

        setShowSection('');
    }

    const setUpAboutContent = () => {
        setFormInfoValuesPrev(formInfoValues);
        setShowSection('company-info');
    }

    const submitCompanyKeyword = async () => {

        let keywordLoop = '', keywordJoined = [], keywordError = 0, formKeywordElem = jQuery('#form-keyword-info'),
            button_name = 'btn-submit-keywords';

        formKeywordElem.find('input').each(function () {
            keywordLoop = jQuery(this).val();
            if (!Utilities.isEmpty(keywordLoop)) {
                keywordLoop = keywordLoop.trim();

                if (!Utilities.checkSpecialChar(keywordLoop)) {
                    keywordJoined.push(keywordLoop.toLowerCase());
                    jQuery(this).closest('.form-group').removeClass('error');
                } else {
                    jQuery(this).closest('.form-group').addClass('error');
                    keywordError++;
                }
            }
        });


        if (keywordError > 0) {
            Utilities.messagePopup('Error', TitlesLabels.general.specialCharactersNotAllowed);
            return false;
        }

        if (keywordJoined.length === 0) {
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired);
            return false;
        }

        if (keywordJoined.length > 0) {
            let wordsRequestArray = keywordJoined;

            Utilities.elementDisabledStatus(button_name, true);
            const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('3', wordsRequestArray);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_someKeyword);
                return false;
            }

        }


        setFormInfoValues({ ...formInfoValues, company_keywords: keywordJoined });
        setShowSection('');
    }

    const setUpkeywordContent = () => {

        // USE JQUERY FOR KEYWORD ----------------------------------------
        extracyCompanyKeywords(formInfoValues.company_keywords);
        // ----------------------------------------

        setFormInfoValuesPrev(formInfoValues);
        setShowSection('keyword-info');
    }


    const cancelKeywordContent = () => {
        setFormInfoValues(formInfoValuesPrev);
        setShowSection('');
        formGroupKeywordElem.removeClass('error');
    }

    const cancelAboutContent = () => {
        setFormInfoValues(formInfoValuesPrev);
        setShowSection('');
        formGroupKeywordElem.removeClass('error');
    }



    const handleCoordinatesRequest = (coodinatesResponse = coordinatesInitial) => {
        /* let formValuesTemp = { ...formValues, ...{ latitude : coodinatesResponse.lat.toString(), longitude : coodinatesResponse.lng.toString() } };
        setFormValues(formValuesTemp); */
        setCoordinates(coodinatesResponse);
    }


    const submitCompanySocial = (socialLinksValues = {}) => {
        setFormSocialValues({ ...socialLinksValues });
        setShowSection('');
    }

    const submitCompanyAppLinks = () => {

        jQuery('.company-app-links > li').removeClass('error');
        let validAppCounter = 0, errorAppCounter = 0;
        Object.entries(formCompanyAppValues).map(([key, val]) => {
            if (!Utilities.isEmpty(val)) {
                if (!Utilities.isValidURL(val)) {
                    jQuery(`input#company_app_${key}`).closest('li').addClass('error');
                    errorAppCounter++;
                } else {
                    validAppCounter++;
                }

            }
        });


        if (errorAppCounter > 0) {
            return false;
        }

        setShowSection('');

        //console.log(formCompanyAppValues, validAppCounter);
        let formValuesTemp = { ...formValues, company_app: validAppCounter > 0 ? 'view' : '' };
        setFormValues(formValuesTemp);


    }

    const htmlContent = () => {

        if (showSection === 'company-social') {

            return <section className="w-100 mt-4">
                <div className="container py-5">

                    <ComanySocialLinksManage submitCompanySocial={submitCompanySocial} socialLinksValue={formSocialValues} />

                </div>
            </section>


        } else if (showSection === 'company-app-link') {
            return <section className="w-100 mt-4">
                <div className="container py-5">

                    <div className="col-sm-5 mx-auto">
                        <div className="mb-2  font-gotham-bold fs-18 fs-md-18">company app link</div>

                        <ul className="company-social-input  company-app-links px-5">
                            <li className="app-apple"><input type="text" id="company_app_apple" placeholder="link" value={formCompanyAppValues.apple} onChange={(e) => setFormCompanyAppValues({ ...formCompanyAppValues, ...{ apple: e.target.value } })} /></li>
                            <li className="app-google"><input type="text" id="company_app_google" placeholder="link" value={formCompanyAppValues.google} onChange={(e) => setFormCompanyAppValues({ ...formCompanyAppValues, ...{ google: e.target.value } })} /></li>
                            <li className="app-huawei"><input type="text" id="company_app_huawei" placeholder="link" value={formCompanyAppValues.huawei} onChange={(e) => setFormCompanyAppValues({ ...formCompanyAppValues, ...{ huawei: e.target.value } })} /></li>
                        </ul>

                        <div className="mt-5 pt-4">
                            <button type="button" className="btn-theme-black" onClick={() => submitCompanyAppLinks()}>submit</button>
                        </div>
                    </div>

                </div>
            </section>

        } else if (showSection === 'company-info') {
            return <section className="col-sm-8 mx-auto pt-3 form-theme text-lowercase mt-5">

                <div id="form-company-info">

                    <div className="form-group">
                        <textarea id="company_about" name="company_about" className="form-control" rows="10" placeholder=" " value={formInfoValues.company_about} onChange={(e) => setFormInfoValues({ ...formInfoValues, ...{ company_about: e.target.value } })}></textarea>
                        <label className="form-label">about your business</label>
                    </div>

                    <div className="d-flex align-items-center justify-content-center text-color-gray text-center fs-14-important">
                        <div>
                            <div className="text-color-blue cursor-pointer" onClick={() => setFormInfoValues({ ...formInfoValues, company_about: '' })}>clear</div>
                            <div>maximum {Utilities.number_format(limitCharacters.toString())} words</div>
                        </div>
                    </div>

                    <div className="mt-5 mb-5 col-md-8 mx-auto button-group-boolean">
                        <button type="button" className="btn-theme-black" onClick={() => cancelAboutContent()}>cancel</button>
                        <button type="button" className="btn-theme-black" onClick={() => submitCompanyAbout()}>submit</button>
                    </div>

                </div>
            </section>

        } else if (showSection === 'keyword-info') {
            return <section className="col-sm-5 mx-auto pt-3 form-theme text-lowercase mt-5">


                <div id="form-keyword-info" >
                    <div className="mb-2  font-gotham-bold fs-18 fs-md-18 pb-3">add keyword</div>

                    {
                        [1, 2, 3, 4, 5].map(key =>
                            <div key={key} className="form-group ">
                                <input type="text" name={`keyword_${key.toString()}`} className="form-control" placeholder=" " onChange={(e) => pushKeywordValue(e, 'keyword_' + key.toString())} autoComplete="off" ></input>
                                <label className="form-label">keyword</label>
                            </div>
                        )
                    }

                    <div className="mt-5 pt-5 button-group-boolean">
                        <button type="button" className="btn-theme-black" onClick={() => cancelKeywordContent()}>cancel</button>
                        <button id="btn-submit-keywords" className="btn-theme-black button-has-loader" onClick={() => submitCompanyKeyword()}>submit</button>
                    </div>

                </div>
            </section>


        } else {
            return <form id="form-company-details" className="col-md-9 mx-auto pt-4 form-theme text-lowercase" autoComplete="off">

                <input type="file" id="input_select_file" name="file" className="invisible" accept={Utilities.attributeImageAcceptExtension} readOnly={true} onChange={(e) => previewImage(e)} ></input>
                <input type="hidden" id="input_company_phone_code" name="company_phone_code" readOnly={true} value={formValues.company_phone_code} onChange={(e) => pushValue(e, 'company_phone_code')} ></input>
                <input type="hidden" id="input_company_landline_code" name="company_landline_code" readOnly={true} value={formValues.company_landline_code} onChange={(e) => pushValue(e, 'company_landline_code')} ></input>
                <input type="hidden" id="input_category_id" name="category_id" readOnly={true} value={formValues.category_id} onChange={(e) => pushValue(e, 'category_id')} ></input>

                <div className={`company-form-content`}>



                    <div className="avatar-wrap avatar-md-wrap">
                        <div className={`avatar-preview border border logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage: `url(${profileLogo})` }} ></div>

                        <div className='profile-logo-change text-center'>
                            <button type="button" className="btn-select-file bg-theme-green font-gotham-bold border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2 px-4" onClick={() => selectImage()}>change</button>
                            <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                        </div>
                    </div>

                    <section className="row pt-3">

                        {
                            branchId > 0 &&
                            <>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <div className="form-control opacity-05 cursor-notallow">{formValues.company_name}</div>
                                        <label className="form-label">Company Name</label>
                                    </div>
                                </div>
                                <div className='col-md-6'></div>
                            </>
                        }

                        {
                            branchId > 0 ?
                                <div className='col-md-6'>
                                    <FieldInput inputName="company_name" inputLabel={`Branch Name`} inputValue={formValues.branch_name} pushValue={pushValue} checkerType="general-companyname" showOption={true} optionLabel={`(br)`} />
                                </div>
                                :
                                <div className='col-md-6'>
                                    <FieldInput inputName="company_name" inputLabel={`Company Name`} inputValue={formValues.company_name} pushValue={pushValue} checkerType="general-companyname" />
                                </div>
                        }


                        <div className='col-md-6'>
                            <FieldInput inputName="company_display_name" inputLabel="internal display name" inputValue={formValues.company_display_name} pushValue={pushValue} checkerType="general-companyname" />
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group ">
                                <input type="email" name="company_email" className="form-control" placeholder=" " autoComplete="new-password" value={formValues.company_email} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)} onChange={(e) => pushValue(e, 'company_email')} disabled ></input>
                                <label className="form-label">Email</label>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group ">
                                <input type="text" name="company_website" className="form-control" placeholder=" " autoComplete="off" value={formValues.company_website} onChange={(e) => pushValue(e, 'company_website')} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)}  ></input>
                                <label className="form-label">Company Website</label>
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <MobileSelection inputValue={formValues.company_landline} countryListProvided={countries} inputLabel="Landline Number" countryCodeProvided={formValues.company_landline_code} inputListingId="landline-input-list" inputName="company_landline" pushValue={pushValue} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeLandline} extraClasses={`has-off-on`}>
                                <input type="checkbox" className="checkbox-onoff" value={formValues.company_landline_checked} checked={formValues.company_landline_checked === 1} onChange={(e) => pushValue(e, 'company_landline_checked')} ></input>
                            </MobileSelection>
                        </div>

                        <div className='col-md-6'>
                            <MobileSelection inputValue={formValues.company_phone} countryListProvided={countries} inputLabel="Mobile Number" countryCodeProvided={formValues.company_phone_code} inputListingId="mobile-input-list" inputName="company_phone" pushValue={pushValue} placeholderExtra={`(mandatory)`} changeCountryCode={changeCountryCodeMobile} extraClasses={`has-off-on`}>
                                <input type="checkbox" className="checkbox-onoff" value={formValues.company_phone_checked} checked={formValues.company_phone_checked === 1} onChange={(e) => pushValue(e, 'company_phone_checked')} ></input>
                            </MobileSelection>

                        </div>




                        <div className='col-md-6'>
                            <div className="form-group">
                                {
                                    branchId > 0 ?
                                        <>
                                            <input type="text" name="business_type" className="d-none" value={formValues.category_name}></input>
                                            <div className="form-control opacity-05 cursor-notallow">{formValues.category_name}</div>
                                        </>
                                        :
                                        <input type="text" name="business_type" className="form-control cursor-pointer" placeholder=" " readOnly={true} autoComplete="off" value={formValues.category_name} onChange={(e) => pushValue(e, 'category_name')} onClick={() => dispatch(showPopupCompanyCategoryReducer(true))} ></input>
                                }
                                <label className="form-label">Business Type</label>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group optional active" >
                                <div className="form-control cursor-pointer" onClick={() => setShowSection('company-social')}>view</div>
                                <label className="form-label">social media links</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group optional active" >
                                <div className="form-control cursor-pointer" onClick={() => setShowSection('company-app-link')}>view</div>
                                <label className="form-label">company app link</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div id="form-group-keyword" className="form-group active">
                                <div className="form-control cursor-pointer" onClick={() => setUpkeywordContent()}>view</div>
                                <label className="form-label">Keyword Info</label>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group optional active">
                                <div className="form-control cursor-pointer" onClick={() => setUpAboutContent()}>view</div>
                                <label className="form-label">Company Info</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>

                    </section>



                    <div className="form-heading text-lowercase mt-3 pb-2 mb-4 border-bottom border-theme-gray  font-gotham-bold fs-20">Address</div>

                    <div className="form-col-2">

                        <div className="form-group active">
                            <Select options={countryList} id="country_id" name="country_id" onChange={(e) => pushValue(e, 'country_id')} placeholder="select country" defaultValue={{ value: formValues.country_id, label: formValues.country_name }} value={{ value: formValues.country_id, label: formValues.country_name }} className="boostrap-select2" />
                            <label className="form-label">Country</label>
                        </div>

                        <div className="form-group active">
                            <Select options={stateList} ref={selectStateRef} name="state_id" placeholder={`${select_labels.state}`} onChange={(e) => pushValue(e, 'state_id')} defaultValue={{ value: formValues.state_id, label: formValues.state_name }} value={{ value: formValues.state_id, label: formValues.state_name }} className="boostrap-select2" />
                            <label className="form-label">State or Province</label>
                        </div>

                    </div>

                    <div className="form-col-2">

                        <div className="form-group active">
                            <Select options={cityList} ref={selectCityRef} name="city_id" placeholder="select city" onChange={(e) => pushValue(e, 'city_id')} defaultValue={{ value: formValues.city_id, label: formValues.city_name }} value={{ value: formValues.city_id, label: formValues.city_name }} className="boostrap-select2" />
                            <label className="form-label">City or Town</label>
                        </div>

                        <div className="form-group optional active">
                            <Select options={areaList} ref={selectAreaRef} name="area_id" placeholder="select suburb" onChange={(e) => pushValue(e, 'area_id')} defaultValue={{ value: formValues.area_id, label: formValues.area_name }} value={{ value: formValues.area_id, label: formValues.area_name }} className="boostrap-select2" />
                            <label className="form-label">Suburb</label>
                            <div className="optional-label">Optional</div>
                        </div>

                    </div>

                    <div className="form-col-2">
                        <div className="form-group optional">
                            <input type="text" name="street_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.street_name} onChange={(e) => pushValue(e, 'street_name')} onBlur={() => getCoordinatesByCompleteLocation()}></input>
                            <label className="form-label">Street Name</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="street_number" className="form-control" placeholder=" " autoComplete="off" value={formValues.street_number} onChange={(e) => pushValue(e, 'street_number')} ></input>
                            <label className="form-label">Street No.</label>
                            <div className="optional-label">Optional</div>
                        </div>
                    </div>

                    <div className="form-col-2">
                        <div className="form-group optional">
                            <input type="text" name="building_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.building_name} onChange={(e) => pushValue(e, 'building_name')} onBlur={() => getCoordinatesByCompleteLocation()} ></input>
                            <label className="form-label">Building or Complex Name</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="building_number" className="form-control" placeholder=" " autoComplete="off" value={formValues.building_number} onChange={(e) => pushValue(e, 'building_number')} ></input>
                            <label className="form-label">Apartment, Office or Unit No.</label>
                            <div className="optional-label">Optional</div>
                        </div>
                    </div>

                    <div className="form-col-2">
                        <div className="form-group optional">
                            <input type="text" name="postal_code" className="form-control" placeholder=" " autoComplete="off" value={formValues.postal_code} onChange={(e) => pushValue(e, 'postal_code')} ></input>
                            <label className="form-label">Postal Code</label>
                            <div className="optional-label">Optional</div>
                        </div>
                    </div>

                    <div className="form-col-2">
                        <div className="form-group  has-off-on border-0">
                            <div className="form-control text-blue-placehoder">show full address</div>
                            <input type="checkbox" className="checkbox-onoff" name="address_visibility_status" value={formValues.address_visibility_status} checked={formValues.address_visibility_status === 1} onChange={(e) => pushValue(e, 'address_visibility_status')}></input>
                        </div>
                    </div>

                    <div className="mt-4 content-disabled position-relative" onClick={() => setShowMapSearchPopup(true)}>
                        <MapBox
                            coordinatesAttributes={coordinates}
                            handleCoordinatesRequest={handleCoordinatesRequest}
                            mapMarkerDraggableAttribues={false}
                            mapLocationActionAttributes={mapLocationAction}
                            mapLocationProvidedAttributes={mapLocationProvided} />
                    </div>

                    {/* <div className="pt-2 text-center"><span className="text-blue cursor-pointer"  onClick={ () => getCurrentLocation() }>{ TitlesLabels.general.clickHereToGetCurrentLocation }</span> ( <span className='color-theme-green cursor-pointer' onClick={() => setShowMapSearchPopup(true) }>change location</span> )</div> */}

                </div>



                <div className="button-wrap text-lowercase text-center my-5  col-md-6 mx-auto">
                    <button type="button" id="btn-submit-detail" className="btn-theme-black text-lowercase button-has-loader" onClick={(e) => submitDetail(e)}>Update</button>

                </div>

            </form>
        }
    }

    const redirectToBranchList = () => {
        history.push(`/${UrlSlugs.companyBranch_manageBranch}/${btoa(branchId)}`);
    }

    const getBranchDetail = async () => {

        if (parseInt(branchId) > 0) {

            dispatch(showLoaderReducer(1));
            const responseBranchList = await getCompanyBranchesByIds(branchId.toString());
            dispatch(showLoaderReducer(0));

            if (parseInt(responseBranchList.status) === 0) {
                return false;
            }

            setMainBranchDetail(responseBranchList.list[0]);
        }
    }


    const handleCloseMapSearchPopup = () => {
        setShowMapSearchPopup(false);
    }

    useEffect(() => {
        getCountries();

    }, []);


    useEffect(() => {

        if (Object.entries(RM.isNil(passCompanyCategoryDetail) ? {} : passCompanyCategoryDetail).length > 0) {

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ category_id: passCompanyCategoryDetail.category_id.toString(), category_name: passCompanyCategoryDetail.category_name.toString() } };
            setFormValues(formValuesTemp);

            dispatch(passCompanyCategoryDetailReducer({}));
        }

    }, [passCompanyCategoryDetail])

    useEffect(async () => {

        let reqAccountID = match.params.mid;
        if (typeof reqAccountID !== 'undefined') {
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if (reqAccountID > 0) {
                await getBranchDetail();
                await setupForm(reqAccountID);

            }
        }

    }, [match.params.mid]);

    useEffect(() => {
        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.companyProfile}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

    }, []);

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            {
                                branchId > 0 &&
                                <>
                                    <div>{TitlesLabels.navigations.manageCompany}</div>
                                    <div className='cursor-pointer' onClick={() => redirectToBranchList()}>{TitlesLabels.navigations.branchInfo}</div>
                                </>
                            }
                            <div className={`${showSection != 'company-social' ? 'current' : ''}`}>{TitlesLabels.navigations.manageCompanyProfile}</div>
                            {
                                showSection === 'company-social' &&
                                <div className="current">{TitlesLabels.navigations.socialMediaLibrary}</div>
                            }
                        </li>
                        <li className="btn-option">
                            {
                                showSection === 'company-social' &&
                                <div className="btn-icon btn-plus" onClick={() => dispatch(showPopupSocialMediaLibraryReducer(true))} title={TitlesLabels.navigations.addSocial}></div>
                            }
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>

                        </li>
                    </ul>
                </div>


            </div>

            <div className="main-content">
                <div className="container">

                    {htmlContent()}

                </div>
            </div>

            <MapSearchPopup showPopup={showMapSearchPopup} handleClosePopUp={handleCloseMapSearchPopup} title={TitlesLabels.general.companyAddress} coordinate={{ lat: formValues.latitude ?? 0, lng: formValues.longitude ?? 0 }} applyMapChanges={handleMapChanges} mapLocationProvided={mapLocationProvided} />
        </div >

    )
}

export default CompanyForm;