import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jQuery from 'jquery';

import ApiUrls from '../../helpers/ApiUrls';
import Utilities from '../../helpers/Utilities';

import QueryChatUtilities from '../../helpers/QueryChatUtilities';
import TitlesLabels from '../../helpers/TitlesLabels';
import ChatUtilities from '../../helpers/ChatUtilities';
import UrlSlugs from '../../helpers/UrlSlugs';
import { showLoader } from '../../actions';
import SessionUtilities from '../../helpers/SessionUtilities';

import { refershDashboadConversation as refershDashboadConversationReducer } from '../../actions';
import CommonProfileLogo from './CommonProfileLogo';


const ConversationProfiles = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const refershDashboadConversation = useSelector(state => state.refershDashboadConversation);
    const conversID = props.conversID ?? 0;

    const [listProfiles, setListProfiles] = useState([]);
    const [rowProfileSelected, setRowProfileSelected] = useState({ convers_id: conversID });
    const [loggedInProfile, setLoggedInProfile] = useState({});
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [contentLoaderBool, setContentLoaderBool] = useState(true);

    const [globalPassvalConversation, setGlobalPassvalConversation] = useState({});
    const [paginationNumber, setPaginationNumber] = useState(0);
    const [paginationLoader, setPaginationLoader] = useState(false);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const blockOrCounter = (chatDetail) => {
        let html_return = '';

        if (chatDetail.hasOwnProperty('contact_settings') && parseInt(chatDetail.contact_settings.mute_status_settings.mute_status || 0) === 1) {
            html_return = <div className="onmute-icon"></div>
        }

        if (chatDetail.hasOwnProperty('block_status')) {
            const blockValue = parseInt(chatDetail.block_status.block_in_status || 0) + parseInt(chatDetail.block_status.block_out_status || 0);
            if (parseInt(blockValue) > 0) {
                html_return = <span className="blocked-icon"></span>
            }
        }

        if (chatDetail.hasOwnProperty('unread_count') && parseInt(chatDetail.unread_count || 0) > 0) {
            html_return = <> {html_return} <div className="unread-counter">{Utilities.counterMore(chatDetail.unread_count)}</div></>
        }

        return html_return;
    }

    const getRecentConversation = async (account = {}, refresh = false) => {


        let master_account_id = account.account_id;
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);
            accountsList = Object.values(accountsList);
            master_account_id = accountsList[0].account_id;
        }

        let flag = '1';
        let account_department_id = '0';
        if (account.hasOwnProperty('group_id')) {
            account_department_id = account.group_id.toString();
            flag = '4';
        }

        const passValues = {
            api_token: TOKEN_SESSION.toString(),    //TOKEN.toString(),
            account_id: account.account_id.toString(),
            account_type: account.account_type.toString(),
            master_account_id: master_account_id.toString(),
            flag: flag.toString(),
            group_id: account_department_id.toString(),
        }

        setContentLoaderBool(refresh ? false : true);
        setGlobalPassvalConversation(passValues);

        await axios
            .post(ApiUrls.bizcomWebHomeDetails, passValues)
            .then(response => {
                setContentLoaderBool(false);

                refresh && dispatch(refershDashboadConversationReducer(''));


                if (parseInt(response.status) === 201) {
                    if (parseInt(response.data.success) === 1) {

                        const conversationResult = response.data.result.beonchat_home_details ?? {};

                        if (Object.keys(conversationResult.home_conversation_list ?? []).length > 0) {

                            let conversationListingTemp = [];
                            Object.entries(conversationResult.home_conversation_list).map(([key, row]) => {

                                let converDetail = Utilities.formatConversationList(row);

                                if (converDetail.hasOwnProperty('department_name') && !Utilities.isEmpty(converDetail.department_name)) {
                                    if (converDetail.name.toLowerCase() === converDetail.title.toLowerCase()) {
                                        converDetail = { ...converDetail, name: row.name.toLowerCase(), title: row.department_name.toLowerCase() };
                                    }
                                }

                                conversationListingTemp.push(converDetail);
                            });

                            setListProfiles(conversationListingTemp);

                            if (refresh) {
                                setTimeout(() => {
                                    setupSelectedProfile();
                                }, 1000);
                            }
                        }

                    }
                }

            })
            .catch((err) => {
                setContentLoaderBool(false);
                setListProfiles([]);
                refresh && dispatch(refershDashboadConversationReducer(''));
            });

    }

    const gettingConversations = (refresh = false) => {
        //setListProfiles([]);

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);

            let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
            getRecentConversation(profileLoggedinTemp, refresh);
            setLoggedInProfile(profileLoggedinTemp);
        }

    }


    const showChat = async (profileChat = {}, key = -1) => {

        setRowProfileSelected(profileChat);

        let flagId = profileChat.hasOwnProperty('flag') ? profileChat.flag : 0;
        let profileChatConversation = {}, passValues = "", passval = {}, conversationDetailResponse = {};
        flagId = parseInt(flagId);

        dispatch(showLoader(1));
        jQuery('.chat-conversation-content').addClass('opacity-0');

        jQuery(`#listing-profiles > li[list-id="${key}"] .unread-counter`).remove();

        if (flagId === 6) {
            passval = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString()
                account_id: loggedInProfile.account_id.toString(),
                group_id: profileChat.id.toString(),
                convers_id: profileChat.convers_id.toString(),
            }

            conversationDetailResponse = await QueryChatUtilities.getBeonchatGroupsConversationDetail(passval);

        } else {
            passval = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: loggedInProfile.account_id.toString(),
                account_type: loggedInProfile.account_type.toString(),
                convers_id: profileChat.convers_id.toString(),
            }

            conversationDetailResponse = await QueryChatUtilities.getBeonchatConversationDetail(passval);
        }

        //console.log(conversationDetailResponse);

        if (Object.keys(conversationDetailResponse).length === 0) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetConversationDetails);
            return false;
        }

        if (parseInt(conversationDetailResponse.success) === 0) {
            Utilities.messagePopup('error', conversationDetailResponse.errorMessage);
            return false;

        }

        profileChatConversation = flagId !== 6 ? 
            (conversationDetailResponse.result.beonchat_conversation ?? conversationDetailResponse.result.conversation_details) :
                ( conversationDetailResponse.result.company_broadcast_conversation_details ?? conversationDetailResponse.result.company_group_conversation_details );

        passValues = ChatUtilities.encryptChatDetail(profileChatConversation);
        ChatUtilities.saveAccountsConversationDetail(profileChatConversation, 'dashboard');


        setTimeout(() => {
            dispatch(showLoader(0));
            jQuery('.chat-conversation-content').removeClass('opacity-0');
        }, 1000);


        history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(profileChatConversation.account_id))}/${passValues}`);
    }

    const paginationContacts = async (elemId = '') => {
        const reachedScrollTopBool = Utilities.getElementSrollPosition(elemId);

        if (reachedScrollTopBool === true && paginationLoader === false) {

            let paginationNumberTemp = paginationNumber + 1;
            const passval = { ...globalPassvalConversation, page: paginationNumberTemp.toString() };

            setPaginationLoader(true);
            const contactListResponse = await QueryChatUtilities.getContactPagination(passval);
            setPaginationLoader(false);

            if (contactListResponse.length > 0) {

                let conversationListingTemp = [];
                Object.entries(contactListResponse ?? []).map(([key, row]) => {
                    conversationListingTemp.push(Utilities.formatConversationList(row));
                });

                const updatedList = [...listProfiles, ...conversationListingTemp];
                setListProfiles(updatedList);
                setPaginationNumber(paginationNumberTemp);

                SessionUtilities.setDashboadLoadedListTemp(updatedList);

                jQuery(`#${elemId}`).stop().animate({
                    scrollTop: parseInt(document.querySelector(`#${elemId}`).scrollTop) + 200

                }, 500, '', function () { });
            }

        }
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const handleDirectorySearch = () => {
        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(1)}`);
    }

    const setupSelectedProfile = () => {
        let segment = Utilities.getUrlSegments();
        if (segment.length > 0) {

            const encryptedParam = segment.slice(-1).pop();
            const splitUrlParam = atob(encryptedParam).toString().split('-');
            setRowProfileSelected({ convers_id: parseInt(splitUrlParam[0]) });
        }
    }

    useEffect(() => {

        /* if ( QueryChatUtilities.getConversationProfileListingLoadingStatus()==='0' ){
            console.log('...');
            gettingConversations();
            console.log('cccc');
            QueryChatUtilities.updateConversationProfileListingLoadingStatus('1');
            
        } */

        gettingConversations();
        setupSelectedProfile();

    }, []);

    useEffect(async () => {

        if (!Utilities.isEmpty(refershDashboadConversation)) {

            setTimeout(() => {
                //gettingConversations( true );
                setupSelectedProfile();
            }, 1500);

        }

    }, [refershDashboadConversation]);



    return (
        <div className='dashboad-page' key={rowProfileSelected.convers_id ?? 0}>

            <div className="content-subnav-wrap">
                <div className="container pr-4 pl-0  d-flex align-items-center">
                    <div className="btn-new-chat btn-icon-wrap cursor-pointer fs-16 border-radius" onClick={() => handleDirectorySearch()}>new chat</div>

                    <div className="profile-search-wrap bg-white d-flex align-items-center ml-3 form-theme  w-100 border-radius">
                        <div className='form-group mb-0 border-0  w-100 '>
                            <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                        </div>
                        {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                    </div>

                </div>
            </div>

            <div className={`list-profile-messages-wrap pr-4 ${!Utilities.isEmpty(refershDashboadConversation) ? 'processing-latest-loader-' : ''} ${paginationLoader ? 'processing-latest-loader' : ''} `} >
                {
                    contentLoaderBool &&
                    <ul className="dashboard-list-preloader darker-content binking-animate mt-3">
                        {
                            [...Array(10).keys()].map((key) =>
                                <li key={key}>
                                    <div className="profile-image"></div>
                                    <div></div>
                                    <div></div>
                                </li>
                            )
                        }
                    </ul>
                }


                {
                    (Object.keys(listProfiles).length > 0 && !contentLoaderBool) &&

                    <ul id="listing-profiles" className={`list-profile-messages height-inherit-important`} onScroll={(e) => paginationContacts('listing-profiles')}>
                        {
                            Object.entries(listProfiles).map(([key, row]) =>
                                <li key={key} list-id={key} data-keyword={row.name.toLowerCase()} className={`${parseInt(row.access_status || 0) === 1 ? 'opacity-05' : ''} ${parseInt(row.convers_id || 0) === parseInt(rowProfileSelected.convers_id || -1) ? 'selected-profile' : ''}`} onClick={() => showChat(row, key)}>
                                    <div>
                                        {/*  <div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }} onClick={ () => previewProfileImage(row.logo) }>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                        <CommonProfileLogo classAttr={`profile-image`} profileDetail={row} />
                                    </div>
                                    <div className='line-height-1-3'>
                                        <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                        <div className="profile-label color-theme-grey">{row.title}</div>
                                    </div>
                                    <div className="time color-theme-grey">{row.time}</div>
                                    <div>{blockOrCounter(row)}</div>
                                </li>
                            )
                        }
                    </ul>

                }

            </div>
        </div>
    )

}

export default memo(ConversationProfiles);