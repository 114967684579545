import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
//import axios from 'axios';
import jQuery from 'jquery';
import Select from 'react-select';
import * as RM from 'ramda';
import moment from 'moment';


import { pageTitle, passCompanyCategoryDetail, reloadAccountsDB, showFooter, showHeader, showLoader, showMainSettings, showPopupCompanyCategory, showPopupQuickLinks } from '../../actions';
import FieldInput from '../../components/FieldInput';
import MapBox from '../../components/MapBox';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import { addCompanyIdToMasterById, copyDefaultBranchLogoToCompany, getCompanyBranchesByIds, saveCompanyAdminDetailFromBranch, saveCompanyDetailFromBranch } from '../../helpers/QueryCompanyBranch';
import TitlesLabels from '../../helpers/TitlesLabels';
import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';
import { blockWordsAndEmailsValidation, checkCompanyEmailExistance } from '../../helpers/VerificationUtilities';
import QueryUtilities from '../../helpers/QueryUtilities';
//import ApiUrls from '../../helpers/ApiUrls';
import { uploadProfileLogo } from '../../helpers/AWSUpload';
import MapSearchPopup from '../../components/popups/MapSearchPopup';
import MobileSelection from '../../components/MobileSelection';


const CompanyBranchForm = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const passCompanyCategoryDetailSelector = useSelector(state => state.passCompanyCategoryDetail);
    const select_labels = Utilities.selectLocationLabels;

    let { mainBranchId } = useParams();
    mainBranchId = (mainBranchId ?? '0') !== '0' ? atob(mainBranchId) : '0';

    const limitCharacters = Utilities.companyAboutWordsLimit;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const defaultLocation = Utilities.defaultLocation();

    const utcTime = Utilities.currentUTCTime();
    const curretTime = moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());


    const masterProfileDetail = PagesAuthentication.getMasterUserDetailDetail();

    /* const defaultLocation = {
        country_id : 0, // process.env.REACT_APP_country_id,
        country_name : '', //process.env.REACT_APP_country_name,
        state_id : 0, //process.env.REACT_APP_state_id,
        state_name :  '', //process.env.REACT_APP_state_name,
        city_id : 0, //process.env.REACT_APP_city_id,
        city_name :  '', //process.env.REACT_APP_city_name,
        area_id : 0, //process.env.REACT_APP_area_id,
        area_name : '', // process.env.REACT_APP_area_name,
    }; */

    let defaultFormValues = {
        ...defaultLocation,
        area_id: 0,
        area_name: 'select suburb',

        company_id: 0,
        company_name: '',
        company_display_name: '',
        company_email: '',
        company_website: '',
        category_name: '',
        category_id: 0,

        company_phone: '',
        company_phone_code: 0, //defaultLocationUtil.country_id,
        company_landline: '',
        company_landline_code: 0, //defaultLocationUtil.country_id,

        street_name: '',
        street_number: '',
        building_name: '',
        building_number: '',
        postal_code: '',

        latitude: '',
        longitude: '',

        company_phone_checked: 0,
        company_landline_checked: 0,

        address_visibility_status: 0
    }

    let finalSocialValues = { facebook: '', youtube: '', tiktok: '', instagram: '', twitter: '' };
    let defaultInfoValues = { company_keywords: [], company_about: '' }

    let defaultFormTeamValues = {
        //team_id : 0,
        team_first_name: masterProfileDetail.first_name ?? '',
        team_last_name: masterProfileDetail.last_name ?? '',
        team_email: '',

        team_department: 'management',
        team_position: 'owner',

        team_internal_chat_status: 0,
        team_is_listing: 0,
        team_external_availability: 0,

        team_contact_number_code_id: 0,
        team_contact_number_code: '',
        team_contact_number: '',
        team_contact_number_status: 0,

        team_is_admin: 1,
        team_about: ''
    }

    const formGroupKeywordElem = jQuery('#form-group-keyword');
    const coordinatesInitial = { lat: 0, lng: 0 };

    const selectStateRef = useRef();
    const selectCityRef = useRef();
    const selectAreaRef = useRef();

    const [mainBranchDetail, setMainBranchDetail] = useState({});
    const [mainBranchInfo, setMainBranchInfo] = useState({});

    const [coordinates, setCoordinates] = useState(coordinatesInitial);
    //const [ coordinatesOld, setCoordinatesOld] = useState(coordinatesInitial); 
    const [mapLocationProvided, setMapLocationProvided] = useState('');
    const [mapLocationAction, setMapLocationAction] = useState(2);

    const [countryNames, setCountryNames] = useState({});
    const [showSection, setShowSection] = useState('');
    const [formInfoValuesPrev, setFormInfoValuesPrev] = useState(defaultInfoValues);

    const [formTeamValues, setFormTeamValues] = useState(defaultFormTeamValues);
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [formSocialValues, setFormSocialValues] = useState(finalSocialValues);
    const [formInfoValues, setFormInfoValues] = useState(defaultInfoValues);

    const [profileLogo, setProfileLogo] = useState('');
    const [profileLogoValue, setProfileLogoValue] = useState('');

    /* const [ showLandlineMsg, setShowLandlineMsg] = useState(false);
    const [ showMobileMsg, setShowMobileMsg] = useState(false);
    const [ showTeamMobileMsg, setTeamShowMobileMsg] = useState(false); */

    /* const [ defaultFlagMobileTeam, setDefaultFlagMobileTeam ] = useState('');
    const [ defaultCountryCodeMobileTeam, setDefaultCountryCodeMobileTeam ] = useState('');

    const [ defaultFlagMobile, setDefaultFlagMobile ] = useState(''); //process.env.REACT_APP_country_iso);
    const [ defaultCountryCodeMobile, setDefaultCountryCodeMobile ] = useState(''); //process.env.REACT_APP_country_ext);

    const [ defaultFlagLandline, setDefaultFlagLandline ] = useState(''); //process.env.REACT_APP_country_iso);
    const [ defaultCountryCodeLandline, setDefaultCountryCodeLandline ] = useState(''); //process.env.REACT_APP_country_ext); */

    const [countryListOption, setCountryListOption] = useState([]);
    /*  const [ stateListOption, setStateListOption] = useState([]);
     const [ cityListOption, setCityListOption] = useState([]);
     const [ areaListOption, setAreaListOption] = useState([]); */

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [showMapSearchPopup, setShowMapSearchPopup] = useState(false);

    const htmlContent = () => {

        if (showSection === 'company-social') {

            return <section className="w-100 mt-4">
                <div className="container py-5">

                    <div id="form-company-social" className="col-sm-5 mx-auto">
                        <div className="mb-2  font-gotham-bold fs-18 fs-md-18">add account</div>

                        <ul className="company-social-input profile-edit-option ">
                            <li className="social-facebook"><input type="text" className="pl-4" placeholder="user name" autoFocus value={formSocialValues.facebook} onChange={(e) => setFormSocialValues({ ...formSocialValues, ...{ facebook: e.target.value } })} /></li>
                            <li className="social-twitter"><input type="text" className="pl-4" placeholder="user name" value={formSocialValues.twitter} onChange={(e) => setFormSocialValues({ ...formSocialValues, ...{ twitter: e.target.value } })} /></li>
                            <li className="social-instagram"><input type="text" className="pl-4" placeholder="user name" value={formSocialValues.instagram} onChange={(e) => setFormSocialValues({ ...formSocialValues, ...{ instagram: e.target.value } })} /></li>
                            <li className="social-tiktok"><input type="text" style={{ paddingLeft: '35px' }} placeholder="user name" value={formSocialValues.tiktok} onChange={(e) => setFormSocialValues({ ...formSocialValues, ...{ tiktok: e.target.value } })} /></li>
                        </ul>

                        <div className="mt-5 pt-4">
                            <button type="button" className="btn-theme-black" onClick={() => submitCompanySocial()}>submit</button>
                        </div>
                    </div>

                </div>
            </section>

        } else if (showSection === 'company-info') {

            return <section className="col-sm-8 mx-auto pt-3 form-theme text-lowercase mt-5">

                <div id="form-company-info">

                    <div className="form-group">
                        <textarea id="company_about" name="company_about" className="form-control" rows="10" autoFocus placeholder=" " value={formInfoValues.company_about} onChange={(e) => setFormInfoValues({ ...formInfoValues, ...{ company_about: e.target.value } })}></textarea>
                        <label className="form-label">about your business</label>
                    </div>

                    <div className="d-flex align-items-center justify-content-center text-color-gray text-center fs-14-important">
                        <div>
                            <div className="text-color-blue cursor-pointer" onClick={() => setFormInfoValues({ ...formInfoValues, company_about: '' })}>clear</div>
                            <div>maximum {Utilities.number_format(limitCharacters.toString())} words</div>
                        </div>
                    </div>

                    <div className="mt-5 mb-5 col-md-8 mx-auto button-group-boolean">
                        <button type="button" className="btn-theme-black" onClick={() => cancelAboutContent()}>cancel</button>
                        <button type="button" className="btn-theme-black" onClick={() => submitCompanyAbout()}>submit</button>
                    </div>

                </div>
            </section>

        } else if (showSection === 'keyword-info') {

            return <section className="col-sm-5 mx-auto pt-3 form-theme text-lowercase mt-5">


                <div id="form-keyword-info" >
                    <div className="mb-2  font-gotham-bold fs-18 fs-md-18 pb-3">add keyword</div>

                    {
                        [1, 2, 3, 4, 5].map(key =>
                            <div key={key} className="form-group ">
                                <input type="text" name={`keyword_${key.toString()}`} autoFocus={key === 1 ? true : false} className="form-control" placeholder=" " onChange={(e) => pushKeywordValue(e, 'keyword_' + key.toString())} autoComplete="off" ></input>
                                <label className="form-label">keyword</label>
                            </div>
                        )
                    }

                    <div className="mt-5 pt-5 button-group-boolean">
                        <button type="button" className="btn-theme-black" onClick={() => cancelKeywordContent()}>cancel</button>
                        <button id="btn-submit-keywords" className="btn-theme-black button-has-loader" onClick={() => submitCompanyKeyword()}>submit</button>
                    </div>

                </div>
            </section>

        } else {
            return <form id="form-company-details" className="col-md-9 mx-auto pt-1 form-theme text-lowercase" autoComplete="off">

                <input type="file" id="input_select_file" name="file" className="invisible" accept={Utilities.attributeImageAcceptExtension} readOnly={true} onChange={(e) => previewImage(e)} ></input>
                <input type="hidden" id="input_company_phone_code" name="company_phone_code" readOnly={true} value={formValues.company_phone_code ?? ''} onChange={(e) => pushValue(e, 'company_phone_code')} ></input>
                <input type="hidden" id="input_company_landline_code" name="company_landline_code" readOnly={true} value={formValues.company_landline_code ?? ''} onChange={(e) => pushValue(e, 'company_landline_code')} ></input>
                <input type="hidden" id="input_category_id" name="category_id" readOnly={true} value={formValues.category_id ?? '0'} onChange={(e) => pushValue(e, 'category_id')} ></input>

                <div className='form-content'>

                    <div className="avatar-wrap avatar-md-wrap">
                        <div className={`avatar-preview border border logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage: `url(${profileLogo})` }} >
                        </div>

                        <div className='profile-logo-change text-center'>
                            <button type="button" className="btn-select-file bg-theme-green font-gotham-bold border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2 px-4" onClick={() => selectImage()}>select</button>
                            <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                        </div>
                    </div>

                    <section className="row pt-3">

                        <div className='col-md-6'>
                            <div className="form-group">
                                <div className="form-control opacity-05 cursor-notallow">{mainBranchInfo.company_name}</div>
                                <label className="form-label">Company Name</label>
                            </div>
                        </div>
                        <div className='col-md-6'></div>

                        <div className='col-md-6'>
                            <FieldInput inputName="company_name" inputLabel="Branch Name" inputValue={formValues.company_name} pushValue={pushValue} checkerType="general-companyname" />
                        </div>
                        <div className='col-md-6'>
                            <FieldInput inputName="company_display_name" inputLabel="Internal Display Name" inputValue={formValues.company_display_name} pushValue={pushValue} checkerType="general-companyname" />
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group ">
                                <input type="email" name="company_email" className="form-control" placeholder=" " autoComplete="off" value={formValues.company_email} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)} onChange={(e) => pushValue(e, 'company_email')} ></input>
                                <label className="form-label">Email</label>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group ">
                                <input type="text" name="company_website" className="form-control" placeholder=" " autoComplete="off" value={formValues.company_website} onChange={(e) => pushValue(e, 'company_website')} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)}  ></input>
                                <label className="form-label">Company Website</label>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <MobileSelection inputValue={formValues.company_landline} countryListProvided={countryList} inputLabel="Landline Number" countryCodeProvided={formValues.company_landline_code} inputListingId="landline-input-list" inputName="company_landline" pushValue={pushValue} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeLandline} extraClasses={`has-off-on`}>
                                <input type="checkbox" className="checkbox-onoff" value={formValues.company_landline_checked} checked={formValues.company_landline_checked === 1} onChange={(e) => pushValue(e, 'company_landline_checked')} ></input>
                            </MobileSelection>
                        </div>

                        <div className='col-md-6'>
                            <MobileSelection inputValue={formValues.company_phone} countryListProvided={countryList} inputLabel="Mobile Number" countryCodeProvided={formValues.company_phone_code} inputListingId="mobile-input-list" inputName="company_phone" pushValue={pushValue} placeholderExtra={`(mandatory)`} changeCountryCode={changeCountryCodeMobile} extraClasses={`has-off-on`}>
                                <input type="checkbox" className="checkbox-onoff" value={formValues.company_phone_checked} checked={formValues.company_phone_checked === 1} onChange={(e) => pushValue(e, 'company_phone_checked')} ></input>
                            </MobileSelection>

                        </div>



                        <div className='col-md-6'>
                            <div className="form-group">
                                {/* <input type="text" name="category_name" className="form-control cursor-pointer" placeholder=" " readOnly={true} autoComplete="off" value={ formValues.category_name }  onChange={ (e) => pushValue( e, 'category_name') } onClick={ () => dispatch( showPopupCompanyCategory(true) ) } ></input> */}
                                <div className="form-control opacity-05 cursor-notallow">{formValues.category_name}</div>
                                <label className="form-label">Business Type</label>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group content-disabled optional cursor-pointer" onClick={() => setShowSection('company-social')}>
                                <input type="text" name="company_social" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_social ?? ''}></input>
                                <label className="form-label">Social Media</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div id="form-group-keyword" className="form-group content-disabled cursor-pointer" onClick={() => setUpkeywordContent()}>
                                <input type="text" name="company_keyword" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_keyword ?? ''}></input>
                                <label className="form-label">Keyword Info</label>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group content-disabled optional cursor-pointer cursor-pointer" onClick={() => setUpAboutContent()}>
                                <input type="text" name="company_about" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_about ?? ''}></input>
                                <label className="form-label">Company Info</label>
                                <div className="optional-label">Optional</div>
                            </div>

                        </div>

                    </section>


                    <div className="form-heading text-lowercase mt-3 pb-2 mb-4 border-bottom border-theme-gray  font-gotham-bold fs-20">Address</div>

                    <section>
                        <div className="form-col-2">

                            <div className="form-group active">
                                <Select options={countryListOption} id="country_id" name="country_id" onChange={(e) => pushValue(e, 'country_id')} placeholder="select country" defaultValue={{ value: formValues.country_id, label: formValues.country_name }} value={{ value: formValues.country_id, label: formValues.country_name }} className="boostrap-select2" />
                                <label className="form-label">Country</label>
                            </div>

                            <div className="form-group active">
                                <Select options={stateList} ref={selectStateRef} name="state_id" placeholder="select state" onChange={(e) => pushValue(e, 'state_id')} defaultValue={{ value: formValues.state_id, label: formValues.state_name }} value={{ value: formValues.state_id, label: formValues.state_name }} className="boostrap-select2" />
                                <label className="form-label">State or Province</label>
                            </div>

                        </div>

                        <div className="form-col-2">

                            <div className="form-group active">
                                <Select options={cityList} ref={selectCityRef} name="city_id" placeholder="select city" onChange={(e) => pushValue(e, 'city_id')} defaultValue={{ value: formValues.city_id, label: formValues.city_name }} value={{ value: formValues.city_id, label: formValues.city_name }} className="boostrap-select2" />
                                <label className="form-label">City or Town</label>
                            </div>

                            <div className="form-group optional active">
                                <Select options={areaList} ref={selectAreaRef} name="area_id" placeholder="select suburb" onChange={(e) => pushValue(e, 'area_id')} defaultValue={{ value: formValues.area_id, label: formValues.area_name }} value={{ value: formValues.area_id, label: formValues.area_name }} className="boostrap-select2" />
                                <label className="form-label">Suburb</label>
                                <div className="optional-label">Optional</div>
                            </div>

                        </div>


                        <div className="form-col-2">
                            <div className="form-group">
                                <input type="text" name="street_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.street_name} onChange={(e) => pushValue(e, 'street_name')} onBlur={() => getCoordinatesByComplereLocation()}></input>
                                <label className="form-label">Street Name</label>
                            </div>

                            <div className="form-group optional">
                                <input type="text" name="street_number" className="form-control" placeholder=" " autoComplete="off" value={formValues.street_number} onChange={(e) => pushValue(e, 'street_number')} ></input>
                                <label className="form-label">Street No.</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>

                        <div className="form-col-2">
                            <div className="form-group optional">
                                <input type="text" name="building_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.building_name} onChange={(e) => pushValue(e, 'building_name')} onBlur={() => getCoordinatesByComplereLocation()} ></input>
                                <label className="form-label">Building or Complex Name</label>
                                <div className="optional-label">Optional</div>
                            </div>

                            <div className="form-group optional">
                                <input type="text" name="building_number" className="form-control" placeholder=" " autoComplete="off" value={formValues.building_number} onChange={(e) => pushValue(e, 'building_number')} ></input>
                                <label className="form-label">Apartment, Office or Unit No.</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>

                        <div className="form-col-2">
                            <div className="form-group optional">
                                <input type="text" name="postal_code" className="form-control" placeholder=" " autoComplete="off" value={formValues.postal_code} onChange={(e) => pushValue(e, 'postal_code')} ></input>
                                <label className="form-label">Postal Code</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>

                        <div className="form-col-2">
                            <div className="form-group  has-off-on border-0">
                                <div className="form-control text-blue-placehoder">show full address</div>
                                <input type="checkbox" className="checkbox-onoff" name="address_visibility_status" value={formValues.address_visibility_status} checked={formValues.address_visibility_status === 1} onChange={(e) => pushValue(e, 'address_visibility_status')}></input>
                            </div>
                        </div>

                    </section>

                    <section className="mt-4 content-disabled position-relative" onClick={() => setShowMapSearchPopup(true)}>
                        <MapBox
                            coordinatesAttributes={coordinates}
                            handleCoordinatesRequest={handleCoordinatesRequest}
                            mapMarkerDraggableAttribues={false}
                            mapLocationActionAttributes={mapLocationAction}
                            mapLocationProvidedAttributes={mapLocationProvided} />
                    </section>


                    <div className="form-heading text-lowercase mt-3 pb-2 mb-4 border-bottom border-theme-gray  font-gotham-bold fs-20 mt-5">Admin Details</div>

                    <section>
                        <div className='row'>

                            <div className='col-md-6'>
                                <FieldInput inputName="team_first_name" inputLabel="First Name" inputValue={formTeamValues.team_first_name} pushValue={pushValueTeam} checkerType="general" />
                            </div>
                            <div className='col-md-6'>
                                <FieldInput inputName="team_last_name" inputLabel="Last Name" inputValue={formTeamValues.team_last_name} pushValue={pushValueTeam} checkerType="general" />
                            </div>

                            <div className='col-md-6'>
                                <div className="form-group">
                                    <input type="text" name="team_department" className="form-control" placeholder=" " autoComplete="off" value={formTeamValues.team_department} onChange={(e) => pushValueTeam(e, 'team_department')} ></input>
                                    <label className="form-label">Department</label>
                                </div>
                            </div>

                            <div className='col-md-6'>

                                <div className="form-group ">
                                    <input type="text" name="team_position" className="form-control" placeholder=" " autoComplete="off" value={formTeamValues.team_position} onChange={(e) => pushValueTeam(e, 'team_position')} ></input>
                                    <label className="form-label">Company Position</label>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <MobileSelection inputValue={formTeamValues.team_contact_number} countryListProvided={countryList} inputLabel="Work mobile Number" countryCodeProvided={formTeamValues.team_contact_number_code} inputListingId="mobile-input-list-team" inputName="team_contact_number" pushValue={pushValueTeam} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeMobileTeam} extraClasses={`has-off-on`}>
                                    <input type="checkbox" className="checkbox-onoff" value={formTeamValues.team_contact_number_status} checked={formTeamValues.team_contact_number_status === 1} onChange={(e) => pushValueTeam(e, 'team_contact_number_status')} ></input>
                                </MobileSelection>

                            </div>



                            <div className='col-md-6'>
                                <div className="form-group active border-bottom-0">
                                    <div className="form-control">
                                        <div className="d-flex mt-2 checkbox-radio-default align-items-center">
                                            <div className="align-items-center d-flex"><input type="checkbox" selected name="listing" value={formTeamValues.team_internal_chat_status} onChange={() => pushValueVisiblity('team_internal_chat_status')} checked={formTeamValues.team_internal_chat_status === 1} ></input><span className="ml-2">Internal</span></div>
                                            <div className="ml-4 align-items-center d-flex"><input type="checkbox" name="external" value={formTeamValues.team_is_listing} onChange={() => pushValueVisiblity('team_is_listing')} checked={formTeamValues.team_is_listing === 1} ></input><span className="ml-2">External</span></div>
                                        </div>
                                    </div>
                                    <label className="form-label">Allow Visibility</label>
                                </div>
                            </div>

                        </div>

                    </section>

                    <div className="button-wrap text-lowercase text-center my-5  col-md-6 mx-auto">
                        <button id="btn-submit-detail" type="button" className="btn-theme-black button-has-loader" onClick={() => submitDetail()}>submit</button>

                    </div>

                </div>

            </form>
        }
    }


    const pushValueVisiblity = (fieldName) => {

        if (!Utilities.isEmpty(fieldName)) {

            let formTeamValuesTemp = formTeamValues;
            if (fieldName === 'team_is_listing') {
                let valueTemp = (parseInt(formTeamValuesTemp.team_is_listing) === 0) ? 1 : 0;
                formTeamValuesTemp = { ...formTeamValuesTemp, ...{ team_is_listing: valueTemp } };

            } else if (fieldName === 'team_internal_chat_status') {
                let valueTemp = (parseInt(formTeamValuesTemp.team_internal_chat_status) === 0) ? 1 : 0;
                formTeamValuesTemp = { ...formTeamValuesTemp, ...{ team_internal_chat_status: valueTemp } };
            }

            setFormTeamValues(formTeamValuesTemp);
        }

    }

    const submitDetail = async () => {

        formGroupKeywordElem.removeClass('error');

        let formValuesTemp = formValues, formTeamData = formTeamValues;
        const button_name = 'btn-submit-detail';
        const formData = new FormData();
        const companyDetail = mainBranchDetail.list[0].company_info ?? {};
        const companyLogoFilename = companyDetail.company_logo ?? '';
        const personalMasterId = mainBranchDetail.list[0].master_account_id ?? 0;

        const locationFields = ['country_id', 'state_id', 'city_id', 'area_id'];
        const requiredFields = ['company_name', 'company_display_name', 'company_email', 'country_id', 'state_id', 'city_id', 'street_name', 'company_landline_code', 'company_phone_code'];

        const saveFields = ['company_name', 'company_website', 'company_email', 'company_landline', 'country_id', 'state_id', 'city_id', 'area_id', 'street_name', 'street_number', 'building_name', 'building_number', 'company_landline_code', 'company_phone_code', 'company_phone', 'postal_code', 'category_id', 'category_name', 'address_visibility_status'];

        const fieldsChecker = ['team_first_name', 'team_last_name', 'team_department', 'team_position', 'team_is_admin'];

        let errorCounter = 0, responseChecker = false;

        requiredFields.map(key => {
            const value = jQuery('input[name="' + key + '"]').val();
            if (Utilities.isEmpty(value)) {
                Utilities.addFormGroupClass(key);
                errorCounter++;
            } else {
                formData.append(key, value);
            }

            if (['company_name', 'company_display_name'].includes(key)) {
                responseChecker = Utilities.inputCharacterChecker(value, 'general-companyname');
                if (responseChecker === false) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }

        });


        Object.entries(formTeamValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {

                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;

                } else {

                    if (['team_first_name', 'team_last_name'].includes(key)) {
                        responseChecker = Utilities.inputCharacterChecker(value, 'general');
                        if (responseChecker === false) {
                            Utilities.addFormGroupClass(key);
                            errorCounter++;
                        } else {
                            Utilities.removeFormGroupClass(key);
                        }
                    } else {
                        Utilities.removeFormGroupClass(key);
                    }
                }
            }
        });

        if (errorCounter > 0) { return false; }

        saveFields.map(key => {
            const value = jQuery('input[name="' + key + '"]').val();
            formData.append(key, value);
        });

        locationFields.map(key => {
            const inputElem = jQuery('input[name="' + key + '"]');
            if (inputElem.length > 0) {
                let value = inputElem.val();
                value = (Utilities.isEmpty(value)) ? 0 : value;
                if (key === 'country_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ country_id: value } };
                } else if (key === 'state_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ state_id: value } };
                } else if (key === 'city_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ city_id: value } };
                } else if (key === 'area_id') {
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ area_id: value } };
                }

            }
        });


        let contactErrorCounter = 0;
        if (!Utilities.isEmpty(formValuesTemp.company_landline) && !Utilities.contactNumberValidate(formValuesTemp.company_landline)) {
            //jQuery('[name="company_landline"]').closest('.form-group').addClass('error');
            Utilities.addFormGroupClass(`company_landline`);
            contactErrorCounter++;
        }

        if (!Utilities.contactNumberValidate(formValuesTemp.company_phone)) {
            //('[name="company_phone"]').closest('.form-group').addClass('error');
            Utilities.addFormGroupClass(`company_phone`);
            contactErrorCounter++;
        }

        if (contactErrorCounter > 0) { return false; }


        if (formInfoValues.company_keywords.length === 0) {
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired);
            formGroupKeywordElem.addClass('error');
            return false;
        }


        // RESTRICTED WORDS VALIDATION
        // TYPES : 1=>email, 2=>name, 3=>keywords
        let wordsRequestArray = [];
        wordsRequestArray.push(formValues.company_name);
        wordsRequestArray.push(formValues.company_display_name);

        Utilities.elementDisabledStatus(button_name, true);
        let responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(responseRestrictedValidationsDB.status) === 0) {
            Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_name);
            Utilities.addFormGroupClass(`company_name`);
            return false;
        }

        // END RESTRICTED WORDS VALIDATION


        // Checking email if exist in database
        dispatch(showLoader(1));
        const responseCompanyEmailCheckingDB = await checkCompanyEmailExistance(formValues.company_email);
        dispatch(showLoader(0));

        if (Object.keys(responseCompanyEmailCheckingDB).length > 0) {
            if (parseInt(responseCompanyEmailCheckingDB.exist) === 1) {
                Utilities.messagePopup('Error', Utilities.labelEmailAddressAlreadyExist);
                return false;
            }
        }



        // Checking mobile number if exist in database
        /* Utilities.elementDisabledStatus(button_name, true );
        const responseMobileCheckingDB = await checkCompanyMobileExistance( formValues.company_phone_code, formValues.company_phone );
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(responseMobileCheckingDB).length>0 ){
            if ( parseInt(responseMobileCheckingDB.exist)===1 ){
                Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist )
                return false;
            }
        } */

        formData.append('category_id', formValues.category_id);
        formData.append('category_name', formValues.category_name);

        formData.append('personal_id', personalMasterId);
        formData.append('create_date', curretTime);
        formData.append('update_date', curretTime);

        //formData.append('company_logo', profileLogoValue);
        formData.append('company_keywords', formInfoValues.company_keywords.length > 0 ? formInfoValues.company_keywords.join('|') : '');
        formData.append('company_about', formInfoValues.company_about);

        formData.append('company_phone_checked', parseInt(formValuesTemp.company_phone_checked || 0));
        formData.append('company_landline_checked', parseInt(formValuesTemp.company_landline_checked || 0));


        formData.append('latitude', coordinates.lat ?? 0);
        formData.append('longitude', coordinates.lng ?? 0);

        let socialFieldValues = Object.entries(formSocialValues).filter(val => val).join('|');
        formData.append('company_social', socialFieldValues);

        // ADDED BRANCH ID
        formData.append('branch_id', mainBranchId);
        formData.append('company_name', mainBranchInfo.company_name ?? '');
        formData.append('branch_name', formValues.company_name ?? '');


        //console.log(formData, formValues);

        // SAVE COMPANY DETAIL //////////////////////////////
        dispatch(showLoader(1));
        const responseGeneratedCompanyId = await saveCompanyDetailFromBranch(formData);
        const generatedCompanyId = parseInt(responseGeneratedCompanyId.status);
        dispatch(showLoader(0));

        //console.log('companyId:' + generatedCompanyId);

        if (parseInt(generatedCompanyId) === 0) {
            Utilities.messagePopup('error', errorMsg);

        } else {

            // SAVE ADMIN //////////////////////////////
            formTeamData = {
                ...formTeamData,
                personal_id: personalMasterId,
                company_id: generatedCompanyId,
                team_create_by: generatedCompanyId,

                category_id: formValues.category_id,
                city_id: formValues.city_id,
                area_id: formValues.city_id,
                country_code: formTeamValues.team_contact_number_code,
                mobile_number: formTeamValues.team_contact_number,
                create_date: curretTime
            };


            dispatch(showLoader(1));
            const responseCompanyAdmin = await saveCompanyAdminDetailFromBranch(formTeamData);
            dispatch(showLoader(0));


            if (parseInt(responseCompanyAdmin.status) === 1) {


                // UPDATE MASTER BRANCH , ADDED CompanyID //////////////////////////////
                dispatch(showLoader(1));
                await addCompanyIdToMasterById({ id: mainBranchId, request_company_id: generatedCompanyId });
                dispatch(showLoader(0));

                //console.log('adminId:' + responseCompanyAdmin.account_id);

                // UPLOAD IMAGE //////////////////////////////
                const logo_value = document.getElementById('input_select_file').value;
                if (!Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue)) {

                    const formLogoData = new FormData();
                    formLogoData.append('account_id', generatedCompanyId);
                    formLogoData.append('file', profileLogoValue);

                    dispatch(showLoader(1));
                    const responseUpload = await uploadProfileLogo(formLogoData);
                    dispatch(showLoader(0));
                    //console.log(responseUpload);

                    if (parseInt(responseUpload.status) === 1) {

                        Utilities.messagePopup('success', TitlesLabels.alertMessages.profileSavedSuccessfully);
                        redirectToBranchList();
                        return false;
                    }
                }

                // COPY LOGO //////////////////////////////

                if (!Utilities.isEmpty(companyLogoFilename)) {

                    dispatch(showLoader(1));
                    const logoPassval = {
                        filename: companyLogoFilename,
                        main_branch_id: personalMasterId,
                        company_id: generatedCompanyId,
                        update_profile: 1
                    };
                    //console.log(logoPassval);
                    const responseCopyLogo = await copyDefaultBranchLogoToCompany(logoPassval);
                    dispatch(showLoader(0));
                    //console.log(responseCopyLogo);
                }


                // RELOAD THE SETTING PROFILE POPUP
                dispatch(showLoader(1));
                dispatch(reloadAccountsDB(2)); // avoid page reload
                dispatch(showLoader(0));

                redirectToBranchList();


            }

        }

    }



    /// ABOUT ///////////////////////////////////////////////////////////
    const submitCompanyAbout = () => {

        let formValuesTemp = { ...formValues, company_about: '' };

        const charCounter = Utilities.wordsCounter(formInfoValues.company_about);
        if (parseInt(charCounter) > limitCharacters) {
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!`);
            return false;
        }

        setShowSection('');

        if (!Utilities.isEmpty(formInfoValues.company_about)) {
            formValuesTemp = { ...formValues, company_about: 'view' };
        }

        setFormValues(formValuesTemp);
    }

    const cancelAboutContent = () => {
        setFormInfoValues(formInfoValuesPrev);
        setShowSection('');
        formGroupKeywordElem.removeClass('error');
    }

    const setUpAboutContent = () => {
        setFormInfoValuesPrev(formInfoValues);
        setShowSection('company-info');
    }


    /// KEYWORD ///////////////////////////////////////////////////////////
    const setUpkeywordContent = () => {

        // USE JQUERY FOR KEYWORD ----------------------------------------
        extracyCompanyKeywords(formInfoValues.company_keywords);
        // ----------------------------------------

        setFormInfoValuesPrev(formInfoValues);
        setShowSection('keyword-info');
    }

    const pushKeywordValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
    }

    const cancelKeywordContent = () => {
        setFormInfoValues(formInfoValuesPrev);
        setShowSection('');
        formGroupKeywordElem.removeClass('error');
    }

    const extracyCompanyKeywords = (keywordsProvided = {}) => {
        setTimeout(() => {
            const formKeywordElem = jQuery('#form-keyword-info');
            Object.entries(keywordsProvided).map(([key, keyword]) => {
                formKeywordElem.find('input[name="keyword_' + (parseInt(key) + 1) + '"]').val(keyword);
            });
        }, 200);
    }

    const submitCompanyKeyword = async () => {

        let keywordLoop = '', keywordJoined = [], keywordError = 0, formKeywordElem = jQuery('#form-keyword-info'),
            button_name = 'btn-submit-keywords', formValuesTemp = { ...formValues, company_keyword: '' };

        formKeywordElem.find('input').each(function () {
            keywordLoop = jQuery(this).val();
            if (!Utilities.isEmpty(keywordLoop)) {
                if (!Utilities.checkSpecialChar(keywordLoop)) {
                    keywordJoined.push(keywordLoop.toLowerCase());
                    jQuery(this).closest('.form-group').removeClass('error');
                } else {
                    jQuery(this).closest('.form-group').addClass('error');
                    keywordError++;
                }
            }
        });


        if (keywordError > 0) {
            Utilities.messagePopup('Error', TitlesLabels.general.specialCharactersNotAllowed);
            return false;
        }

        if (keywordJoined.length === 0) {
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired);
            return false;
        }

        if (keywordJoined.length > 0) {
            let wordsRequestArray = keywordJoined;

            Utilities.elementDisabledStatus(button_name, true);
            const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('3', wordsRequestArray);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_someKeyword);
                return false;
            }

            formValuesTemp = { ...formValues, company_keyword: 'view' };

        }

        setFormValues(formValuesTemp);
        setFormInfoValues({ ...formInfoValues, company_keywords: keywordJoined });
        setShowSection('');
    }

    /// SOCIAL ///////////////////////////////////////////////////////////
    const submitCompanySocial = () => {

        if (!Utilities.isEmpty(formSocialValues.youtube) && !Utilities.isValidURL(formSocialValues.youtube)) {
            Utilities.messagePopup('warning', TitlesLabels.alertMessages.invalidYoutubeUrl);
            return false;
        }

        setShowSection('');

        let formValuesTemp = { ...formValues, company_social: '' };
        let concatSocialText = Object.values(formSocialValues).filter(val => val !== '').join('|');
        concatSocialText = concatSocialText.trim();

        if (!Utilities.isEmpty(concatSocialText)) {
            formValuesTemp = { ...formValues, company_social: 'view' };
        }
        setFormValues(formValuesTemp);
    }



    /// EXTRA ///////////////////////////////////////////////////////////


    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }


    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setProfileLogo(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }

    const triggerSetting = () => {
        dispatch(showMainSettings(new Date()));
    }

    const setupMainBranchDetail = async (bId = 0) => {
        if (parseInt(bId) > 0) {

            dispatch(showLoader(1));
            const responseBranchList = await getCompanyBranchesByIds(bId.toString());
            dispatch(showLoader(0));

            if (parseInt(responseBranchList.status) === 0) {
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.noRecordFound,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => redirectToHome() }
                    ],
                    onClickOutside: () => redirectToHome(),
                    onKeypressEscape: () => redirectToHome(),
                });

                return false;
            }
            console.log(responseBranchList);

            setMainBranchDetail(responseBranchList);

            const companyDetail = responseBranchList.list[0].company_info ?? {},
                socialAccountsObject = (Object.keys(companyDetail.company_social) ?? {}).length === 0 ? {} : companyDetail.company_social,
                finalSocialValuesTemp = {
                    ...formSocialValues,
                    facebook: socialAccountsObject.facebook ?? '',
                    youtube: socialAccountsObject.youtube ?? '',
                    tiktok: socialAccountsObject.tiktok ?? '',
                    instagram: socialAccountsObject.instagram ?? '',
                    twitter: socialAccountsObject.twitter ?? ''
                };

            let concatSocialText = Object.values(finalSocialValuesTemp).filter(val => val !== '').join('|');
            concatSocialText = concatSocialText.trim();

            const formValuesTemp = {
                ...formValues,
                company_name: companyDetail.company_name ?? '',
                company_display_name: companyDetail.company_name ?? '',
                company_website: companyDetail.company_website ?? '',
                category_name: companyDetail.category_name ?? '',

                company_phone: companyDetail.company_phone ?? '',
                company_phone_code: companyDetail.company_phone_code ?? '',
                company_phone_checked: parseInt(companyDetail.mobile_number_status || 0),

                company_landline: companyDetail.company_landline ?? '',
                company_landline_code: companyDetail.company_landline_code ?? '',
                company_landline_checked: parseInt(companyDetail.landline_number_status || 0),

                company_about: (companyDetail.company_about ?? '') === '' ? '' : 'view',
                company_keyword: (companyDetail.company_keywords ?? []).length === 0 ? '' : 'view',
                company_social: Utilities.isEmpty(concatSocialText) ? '' : 'view'
            };

            setMainBranchInfo(companyDetail);
            setFormSocialValues(finalSocialValuesTemp);
            setProfileLogo((companyDetail.company_logo ?? '') === '' ? '' : companyDetail.logo);
            setFormValues(formValuesTemp);

            getCountries({ company_landline_code: formValuesTemp.company_landline_code, company_phone_code: formValuesTemp.company_phone_code });

            getStateByCountryID(formValuesTemp.country_id);
            getCityByStateID(formValuesTemp.state_id);
            getAreByCityID(formValuesTemp.city_id);

            const formInfoValuesTemp = {
                ...formInfoValues,
                company_keywords: (companyDetail.company_keywords ?? []).length === 0 ? [] : companyDetail.company_keywords,
                company_about: companyDetail.company_about ?? ''
            };

            setFormInfoValues(formInfoValuesTemp);
            extracyCompanyKeywords(formInfoValuesTemp.company_keywords);

            setFormTeamValues({ ...formTeamValues, team_contact_number_code: companyDetail.company_phone_code ?? '' });

        }
    }

    const redirectToHome = () => {
        history.push(`/${UrlSlugs.dashboard}`);
    }

    const redirectToBranchList = () => {
        history.push(`/${UrlSlugs.companyBranch_manageBranch}/${btoa(mainBranchId)}`);
    }


    const changeCountryCodeLandline = (passValues, fieldName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    const changeCountryCodeMobile = (passValues, fieldName = '') => {
        let formTeamValuesTemp = formTeamValues;
        formTeamValuesTemp = { ...formTeamValuesTemp, ...{ team_contact_number_code: passValues.id.toString().trim() } };
        setFormTeamValues(formTeamValuesTemp);
    }

    const changeCountryCodeMobileTeam = (passValues, fieldName = '') => {
        let formTeamValuesTemp = formTeamValues;
        formTeamValuesTemp = { ...formTeamValuesTemp, ...{ team_contact_number_code: passValues.id.toString().trim() } };
        setFormTeamValues(formTeamValuesTemp);
    }


    const pushValueTeam = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        let errorCounter = 0, formValuesTemp = formTeamValues;

        if (fieldName === 'team_first_name') {
            formValuesTemp = { ...formValuesTemp, ...{ team_first_name: value } };
        } else if (fieldName === 'team_last_name') {
            formValuesTemp = { ...formValuesTemp, ...{ team_last_name: value } };
        } else if (fieldName === 'team_department') {
            formValuesTemp = { ...formValuesTemp, ...{ team_department: value } };
        } else if (fieldName === 'team_position') {
            formValuesTemp = { ...formValuesTemp, ...{ team_position: value } };

        } else if (fieldName === 'team_contact_number') {

            let team_contact_number = value.replace(' ', '');
            if (!Utilities.isEmpty(team_contact_number) && !Utilities.contactNumberValidate(team_contact_number)) {
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number: parseInt(team_contact_number) || '' } };

        } else if (fieldName === 'team_contact_number_status') {
            value = parseInt(formValuesTemp.team_contact_number_status) === 1 ? 0 : 1;

            if (value === 1 && Utilities.isEmpty(formValuesTemp.team_contact_number || '')) {
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                value = 0;
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status: value } };
        }

        if (errorCounter === 0) {
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormTeamValues(formValuesTemp);

        if (!Utilities.isEmpty(fieldName) && ['team_contact_number_status'] && value === 1) {
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileTeamActivationWarningMessage,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0, 'team') },
                    { label: 'continue', onClick: () => confirmContactNumber(fieldName, 1, 'team') }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0, 'team'),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0, 'team'),
            });
        }

        if (!Utilities.isEmpty(fieldName) && ['team_is_admin'].includes(fieldName) && value === 2) {
            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wishGrantManagementAccess,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmManaementAccess(0) },
                    { label: 'continue', onClick: () => confirmManaementAccess(2) }
                ],
                onClickOutside: () => confirmManaementAccess(0),
                onKeypressEscape: () => confirmManaementAccess(0),
            });
        }
    }

    const confirmManaementAccess = (status = 0) => {
        setFormTeamValues({ ...formTeamValues, team_is_admin: status });
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;

        let errorCounter = 0;

        if (['country_id', 'state_id', 'city_id', 'area_id'].includes(fieldName)) {

            if (fieldName === 'country_id') {
                getStateByCountryID(e.value);

                const country_name = (typeof countryNames[e.value] !== 'undefined') ? countryNames[e.value] : 'select country';
                formValuesTemp = { ...formValuesTemp, ...{ country_id: e.value, country_name: country_name, state_id: 0, state_name: 'select state', city_id: 0, city_name: 'select city', area_id: 0, area_name: 'select suburb' } };

            } else if (fieldName === 'state_id') {
                getCityByStateID(e.value);
                formValuesTemp = { ...formValuesTemp, ...{ state_id: e.value, state_name: e.label, city_name: 'select city', area_id: 0, area_name: 'select suburb' } };

            } else if (fieldName === 'city_id') {
                getAreByCityID(e.value);
                formValuesTemp = { ...formValuesTemp, ...{ city_id: e.value, city_name: e.label, area_id: 0, area_name: 'select suburb' } };

                const stateNameSelected = !Utilities.isEmpty(formValuesTemp.state_id) ? stateList.filter(state => parseInt(state.value) === parseInt(formValuesTemp.state_id))[0]['label'] ?? '' : '';
                const cityNameSelected = !Utilities.isEmpty(e.value) ? cityList.filter(city => parseInt(city.value) === parseInt(e.value))[0]['label'] ?? '' : '';
                if (!Utilities.isEmpty(cityNameSelected)) {
                    setMapLocationProvided(stateNameSelected + ',' + cityNameSelected);
                    setMapLocationAction(1);
                }


            } else if (fieldName === 'area_id') {
                formValuesTemp = { ...formValuesTemp, ...{ area_id: e.value, area_name: e.label } };

                const cityNameSelected = !Utilities.isEmpty(formValuesTemp.city_id) ? cityList.filter(city => parseInt(city.value) === parseInt(formValuesTemp.city_id))[0]['label'] ?? '' : '';
                const areaNameSelected = !Utilities.isEmpty(e.value) ? areaList.filter(area => parseInt(area.value) === parseInt(e.value))[0]['label'] ?? '' : '';
                if (!Utilities.isEmpty(areaNameSelected)) {
                    setMapLocationProvided(cityNameSelected + ',' + areaNameSelected);
                    setMapLocationAction(1);
                }

            }
            setFormValues(formValuesTemp);


        } else {

            let value = e.target.value;

            if (fieldName === 'company_name') {
                formValuesTemp = { ...formValuesTemp, ...{ company_name: value } };
            } else if (fieldName === 'company_display_name') {
                formValuesTemp = { ...formValuesTemp, ...{ company_display_name: value } };
            } else if (fieldName === 'company_email') {
                formValuesTemp = { ...formValuesTemp, ...{ company_email: value } };
            } else if (fieldName === 'company_website') {
                formValuesTemp = { ...formValuesTemp, ...{ company_website: value } };

            } else if (fieldName === 'company_landline') {

                let company_landline = value.replace(' ', '');
                if (!Utilities.isEmpty(company_landline) && !Utilities.contactNumberValidate(company_landline)) {
                    //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(fieldName);
                    errorCounter++;
                }
                formValuesTemp = { ...formValuesTemp, ...{ company_landline: parseInt(company_landline) || '' } };

            } else if (fieldName === 'company_landline_code') {
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: value.toString().trim() } };


            } else if (fieldName === 'company_phone') {

                let company_phone = value.replace(' ', '');
                if (!Utilities.contactNumberValidate(company_phone)) {
                    //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(fieldName);
                    errorCounter++;
                }
                formValuesTemp = { ...formValuesTemp, ...{ company_phone: parseInt(company_phone) || '' } };

            } else if (fieldName === 'company_phone_code') {
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_code: value.toString().trim() } };


            } else if (fieldName === 'street_name') {
                formValuesTemp = { ...formValuesTemp, ...{ street_name: value } };
            } else if (fieldName === 'street_number') {
                formValuesTemp = { ...formValuesTemp, ...{ street_number: value } };
            } else if (fieldName === 'building_name') {
                formValuesTemp = { ...formValuesTemp, ...{ building_name: value } };
            } else if (fieldName === 'building_number') {
                formValuesTemp = { ...formValuesTemp, ...{ building_number: value } };
            } else if (fieldName === 'postal_code') {
                formValuesTemp = { ...formValuesTemp, ...{ postal_code: value } };

            } else if (fieldName === 'company_phone_checked') {
                value = parseInt(formValuesTemp.company_phone_checked) === 1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: value } };
            } else if (fieldName === 'company_landline_checked') {
                value = parseInt(formValuesTemp.company_landline_checked) === 1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: value } };

            } else if (fieldName === 'address_visibility_status') {
                value = parseInt(formValuesTemp.address_visibility_status) === 1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ address_visibility_status: value } };
            }

            setFormValues(formValuesTemp);

            if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'].includes(fieldName) && value === 1) {
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.mobileActivationWarningMessage,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                        { label: 'continue', onClick: () => confirmContactNumber(fieldName, 1) }
                    ],
                    onClickOutside: () => confirmContactNumber(fieldName, 0),
                    onKeypressEscape: () => confirmContactNumber(fieldName, 0),
                });
            }

            if (!Utilities.isEmpty(fieldName) && ['address_visibility_status'].includes(fieldName) && value === 1) {
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.companyAddressVisibilityWarningMessage,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmAddressVisibility(0) },
                        { label: 'continue', onClick: () => confirmAddressVisibility(1) }
                    ],
                    onClickOutside: () => confirmAddressVisibility(0),
                    onKeypressEscape: () => confirmAddressVisibility(0),
                });
            }
        }

        if (errorCounter === 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
        }

    }

    const confirmAddressVisibility = (status = 0) => {
        setFormValues({ ...formValues, address_visibility_status: status });
    }

    const confirmContactNumber = (fieldName = '', value = 0, typeName = '') => {

        if (typeName === 'team') {

            if (!Utilities.isEmpty(fieldName) && ['team_contact_number_status']) {
                let formValuesTemp = formTeamValues;

                if (fieldName === 'team_contact_number_status') {
                    formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status: parseInt(value) } };

                }
                setFormTeamValues(formValuesTemp);
            }

            return false;
        }

        if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked']) {
            let formValuesTemp = formValues;

            if (fieldName === 'company_phone_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: parseInt(value) } };

            } else if (fieldName === 'company_landline_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: parseInt(value) } };
            }

            setFormValues(formValuesTemp);
        }
    }


    const getCoordinatesByComplereLocation = async () => {

        let formValuesTemp = formValues;

        let addressArray = [];
        //addressArray.push(formValuesTemp.building_number);
        addressArray.push((formValuesTemp.building_name ?? '').trim());
        addressArray.push((formValuesTemp.street_name ?? '').trim());

        addressArray.push((formValuesTemp.area_name ?? '').trim());
        addressArray.push((formValuesTemp.city_name ?? '').trim());
        addressArray.push((formValuesTemp.state_name ?? '').trim());
        addressArray.push((formValuesTemp.country_name ?? '').trim());

        addressArray = addressArray.filter(function (el) {
            return el != null;
        });

        const address = addressArray.join(',');

        setMapLocationProvided(address);
        setMapLocationAction(1);
    }

    const handleCoordinatesRequest = (coodinatesResponse = coordinatesInitial) => {
        setCoordinates(coodinatesResponse);
    }

    // COUNTRIES
    const getCountries = async (setupContact = {}) => {
        const countryListDb = await QueryUtilities.countryList();
        let country_names = {}, optionList = [];

        if (Object.keys(setupContact).length > 0) {

            Object.entries(countryListDb).map(([key, row]) => {

                /* if ( parseInt(setupContact.company_landline_code||0)===parseInt(row.id) ) {
                    setDefaultFlagLandline(row.country_short_code.toLowerCase());
                    setDefaultCountryCodeLandline(row.phonecode);
                }
 
                if ( parseInt(setupContact.company_phone_code||0)===parseInt(row.id) ) {
                    setDefaultFlagMobile(row.country_short_code.toLowerCase());
                    setDefaultCountryCodeMobile(row.phonecode);

                    setDefaultFlagMobileTeam(row.country_short_code.toLowerCase());
                    setDefaultCountryCodeMobileTeam(row.phonecode);
                } */

                country_names[row.id] = row.country_name;
                optionList = [...optionList, { value: row.id, label: row.country_name }];
            });
        }

        setCountryList(countryListDb);
        setCountryNames(country_names);
        setCountryListOption(optionList);
    }


    // STATES
    const getStateByCountryID = async (country_id = 0) => {
        let optionList = [];

        if (parseInt(country_id) > 0) {
            const responseStateList = await QueryUtilities.stateListByCountryId(country_id);
            Object.entries(responseStateList ?? {}).map(([key, row]) => {
                optionList = [...optionList, { value: row.id, label: row.state_name }];
            });
        }

        setStateList(optionList);
    }

    // CITIES
    const getCityByStateID = async (state_id = 0) => {
        let optionList = [];
        if (parseInt(state_id) > 0) {
            const responseCityList = await QueryUtilities.cityListByStateId(state_id);
            Object.entries(responseCityList ?? {}).map(([key, row]) => {
                optionList = [...optionList, { value: row.id, label: row.city_name }];
            });
        }
        setCityList(optionList);
    }

    // AREA / SUBURB
    const getAreByCityID = async (city_id = 0) => {
        let optionList = [];

        if (parseInt(city_id) > 0) {
            const responseAreaList = await QueryUtilities.areaListByCityId(city_id);
            Object.entries(responseAreaList ?? {}).map(([key, row]) => {
                optionList = [...optionList, { value: row.area_id, label: row.area_name }]
            });
        }
        setAreaList(optionList);
    }


    const handleCloseMapSearchPopup = () => {
        setShowMapSearchPopup(false);
    }

    const handleMapChanges = async (locationArray = {}, coordinates = {}) => {

        setShowMapSearchPopup(false);

        //console.log(locationArray);
        if (Object.keys(locationArray).length > 0 && Object.keys(coordinates).length > 0) {


            let profileCompanyTemp = {
                ...formValues,
                country_id: locationArray.country_id ?? 0,
                state_id: locationArray.state_id ?? 0,
                city_id: locationArray.city_id ?? 0,
                area_id: locationArray.area_id ?? 0,

                country_name: locationArray.country_name ?? select_labels.country,
                state_name: locationArray.state_name ?? select_labels.state,
                city_name: locationArray.city_name ?? select_labels.city,
                area_name: locationArray.area_name ?? select_labels.area,

                street_name: '',
                street_number: '',
                building_name: '',
                building_number: '',
                postal_code: '',

                latitude: locationArray.latitude ?? '',
                longitude: locationArray.longitude ?? '',
            };



            dispatch(showLoader(1));

            await getStateByCountryID(profileCompanyTemp.country_id);
            await getCityByStateID(profileCompanyTemp.state_id);
            await getAreByCityID(profileCompanyTemp.city_id);

            dispatch(showLoader(0));


            setFormValues(profileCompanyTemp);


            // CHANGE MAP
            setMapLocationAction(0);
            const coordinatesValue = { lat: parseFloat(coordinates.lat || 0), lng: parseFloat(coordinates.lng || 0) };

            let timer = setTimeout(function () {
                setCoordinates(coordinatesValue);
                setMapLocationAction(3);


                clearTimeout(timer);
            }, 1000);


        }

    }


    useEffect(() => {

        if (Object.entries(RM.isNil(passCompanyCategoryDetailSelector) ? {} : passCompanyCategoryDetailSelector).length > 0) {

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ category_id: passCompanyCategoryDetailSelector.category_id.toString(), category_name: passCompanyCategoryDetailSelector.category_name.toString() } };
            setFormValues(formValuesTemp);

            dispatch(passCompanyCategoryDetail({}));
        }

    }, [passCompanyCategoryDetailSelector])

    useEffect(() => {

        if (mainBranchId === '0') {
            history.push(`/${UrlSlugs.dashboard}`);
        }

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.security}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        setupMainBranchDetail(mainBranchId);


    }, []);


    return (
        <div className="profile-security-page chat-template-messages-content">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{TitlesLabels.settings}</div>
                            <Link to={`/${UrlSlugs.companyBranch_manageBranch}/${btoa(mainBranchId)}`} className='cursor-pointer'>{TitlesLabels.navigations.branchList}</Link>
                            <div className="current">{TitlesLabels.navigations.add}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinks(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()}></div>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container pt-5">

                    {htmlContent()}


                </div>
            </div>

            <MapSearchPopup showPopup={showMapSearchPopup} handleClosePopUp={handleCloseMapSearchPopup} title={TitlesLabels.general.companyAddress} applyMapChanges={handleMapChanges} mapLocationProvided={mapLocationProvided} />


        </div>
    )
}

export default CompanyBranchForm;