import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import MapBox from '../MapBox';
import AutoComplete from "react-google-autocomplete";

import Utilities from '../../helpers/Utilities';
import { getLocationByCoordinates } from '../../helpers/LocationUtilities';

import { showLoader } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';


const MapSearchPopup = (props) => {

    const dispatch = useDispatch();

    const IMG_FOLDER = process.env.REACT_APP_img_folder;
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_google_map_key;

    const defaultLocation = Utilities.defaultLocation();
    const initialCoordinate = { lat: defaultLocation.latitude ?? 0, lng: defaultLocation.longitude ?? 0 };

    let initialLocation = [];


    initialLocation.push(defaultLocation.state_name ?? '');
    initialLocation.push(defaultLocation.city_name ?? '');

    initialLocation = Utilities.arrayUnique(initialLocation);

    const props_title = props.title ?? '';
    const props_allowSubmssion = props.allowSubmssion ?? false;
    const props_coordinate = props.coordinate ?? initialCoordinate; //{ lat:0, lng:0 }; 
    const props_mapLocationAction = props.mapLocationAction ?? 3;
    const props_mapLocationProvided = props.mapLocationProvided ?? initialLocation.join(' '); //'ligaya pagudpud ilocos norte';


    const [mapLocationProvided, setMapLocationProvided] = useState(props_mapLocationProvided);
    const [mapLocationAction, setMapLocationAction] = useState(props_mapLocationAction);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [databaseLocation, setDatabaseLocation] = useState({});
    const [databaseAddress, setDatabaseAddress] = useState('');
    const [coordinate, setCoordinate] = useState(initialCoordinate);

    const [showAutoComplete, setShowAutoComplete] = useState(false);


    const handleReset = () => {
        setDatabaseAddress('');
        setSearchKeyword('');
        setDatabaseLocation({});
        //setMapLocationProvided('');
    }

    const handleClosePopup = () => {
        handleReset();
        props.handleClosePopUp();
    }

    const handleResetSearchInput = () => {
        setSearchKeyword('');

        const inputSearchElem = document.getElementById('input_address_keyword');
        inputSearchElem.value = '';
        inputSearchElem.focus();
    }

    const handleCoordinatesRequest = async (coodinatesResponse = props_coordinate) => {
        //console.log(coodinatesResponse);

        dispatch(showLoader(1));
        const responseLocationInformation = await getLocationByCoordinates(coodinatesResponse.lat, coodinatesResponse.lng);
        dispatch(showLoader(0));

        //console.log(responseLocationInformation);

        setDatabaseLocation(responseLocationInformation.database ?? {});
        setDatabaseAddress(responseLocationInformation.address ?? '');

        setCoordinate({ ...coordinate, lat: coodinatesResponse.lat ?? 0, lng: coodinatesResponse.lng ?? 0 });
        //console.log(responseLocationInformation);
    }


    const submitDatabaseLocation = () => {

        if (props_allowSubmssion) {
            handleReset();
            props.applyMapChanges(databaseLocation, coordinate);
            return false;
        }

        if (Object.keys(databaseLocation).length > 0) {
            //console.log(databaseLocation);
            handleReset();
            props.applyMapChanges(databaseLocation, coordinate);
        }
    }

    const handleClickShowInMap = (locationArray = {}) => {
        //console.log(locationArray);

        if (Object.keys(locationArray).length > 0) {
            const formattedAddress = locationArray.formatted_address ?? '';

            if (!Utilities.isEmpty(formattedAddress)) {

                setSearchKeyword(formattedAddress);
                setMapLocationProvided(formattedAddress);
                setMapLocationAction(1);

            }
        }

    }

    useEffect(() => {
        /* const delayDebounceFn = setTimeout( () => {

            // Send Axios request here
            if ( ! Utilities.isEmpty(searchKeyword) ){
                setMapLocationAction(1);
                setMapLocationProvided(searchKeyword);
            }
            
          }, 1500);

        return () => {
            clearTimeout(delayDebounceFn);
        }; */

    }, [searchKeyword]);


    useEffect(() => {
        setMapLocationAction(props_mapLocationAction);

    }, [props_mapLocationAction]);


    useEffect(() => {

        setTimeout(() => {
            setShowAutoComplete(true);
        }, 500);

    }, [props.showPopup]);


    //types: ["establishment","geocode","address","(regions)","(cities)"]
    return (

        (props.showPopup ?? false) === true &&

        <Modal key={`popup-map-search`} id="popup-map-search" show={props.showPopup} onHide={handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" dialogClassName="modal-dialog-theme-" contentClassName="overflow-hidden" >
            <Modal.Header closeButton className="border-bottom-0">
                <h2 className="text-center font-gotham-bold fs-25 w-100 text-lowercase">{props_title ?? TitlesLabels.general.companyAddress}</h2>
            </Modal.Header>
            <Modal.Body className='p-0 pb-4 mt-n2'>

                <div className='bg-dark form-theme px-2'>
                    <div className='form-group mb-0 border-0  w-100 d-flex align-items-center justify-content-between'>

                        {
                            showAutoComplete &&
                            <AutoComplete
                                apiKey={GOOGLE_MAPS_API_KEY}
                                className="form-input text-white py-3"
                                autoFocus
                                placeholder='search'
                                options={{
                                    types: ["address"]
                                }}
                                id="input_address_keyword"
                                onChange={(e) => setSearchKeyword(e.target.value)} autoComplete='off'
                                onPlaceSelected={(place) => handleClickShowInMap(place)}
                            />
                        }


                        {
                            !Utilities.isEmpty(searchKeyword) &&
                            <div>
                                <img src={`${IMG_FOLDER}/icons/white/outline-cross-border.svg`} width='20' className='cursor-pointer mr-2' onClick={() => handleResetSearchInput()} />
                            </div>
                        }
                    </div>
                </div>



                <MapBox
                    coordinatesAttributes={props_coordinate}
                    handleCoordinatesRequest={handleCoordinatesRequest}
                    mapMarkerDraggableAttribues={true}
                    mapLocationActionAttributes={mapLocationAction}
                    markerTooltip={TitlesLabels.general.dragTheMarkerToSelectlocation}
                    mapLocationProvidedAttributes={mapLocationProvided} />

                <div className={`mt-2 text-center mx-4 text-lowercase ${Utilities.isEmpty(databaseAddress) ? 'pt-2' : ''}`}>
                    {databaseAddress}
                </div>

                <div className='col-5 mx-auto mt-2'>
                    {
                        !props_allowSubmssion ?
                            <button type="button" className={`btn-theme-black ${Object.keys(databaseLocation).length === 0 ? 'opacity-05 cursor-notallow' : ''}`} onClick={() => submitDatabaseLocation()}>submit</button>
                            :
                            <button type="button" className={`btn-theme-black`} onClick={() => submitDatabaseLocation()}>submit</button>
                    }

                </div>

            </Modal.Body>
        </Modal>


    )
}

export default MapSearchPopup;
