import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jQuery from 'jquery';
import moment from 'moment';

import ApiUrls from '../../helpers/ApiUrls';
import Utilities from '../../helpers/Utilities';

import { refershDashboadConversation as refershDashboadConversationReducer } from '../../actions';
import ChatUtilities from '../../helpers/ChatUtilities';
import UrlSlugs from '../../helpers/UrlSlugs';
import { showLoader } from '../../actions';
import SessionUtilities from '../../helpers/SessionUtilities';
import QueryUtilities from '../../helpers/QueryUtilities';
import CommonProfileLogo from './CommonProfileLogo';
import { getInternalConversation } from '../../helpers/InternalUtilities';

const ConversationInternal = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    //const TOKEN = process.env.REACT_APP_chat_api_token;
    const BIZCOM_TOKEN = process.env.REACT_APP_chat_api_token_bizcom;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    //const conversID = props.conversID ?? 0 ;

    const refershDashboadConversation = useSelector(state => state.refershDashboadConversation);

    const [listProfiles, setListProfiles] = useState([]);
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [rowProfileSelected, setRowProfileSelected] = useState({ convers_id: 0 });
    const [profileDetail, setProfileDetail] = useState([]);
    const [companyDetail, setCompanyDetail] = useState([]);
    const [contentLoaderBool, setContentLoaderBool] = useState(true);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getMembers = async (companyID = 0, accountDetail = {}, masterDetail = {}, refresh = 0) => {

        if (parseInt(companyID) > 0 && Object.keys(accountDetail).length > 0 && Object.keys(masterDetail).length > 0) {

            setContentLoaderBool(refresh ? false : true);

            const passValues = {
                api_token: TOKEN_SESSION.toString(), //BIZCOM_TOKEN.toString(),
                account_id: accountDetail.account_id.toString(),
                company_id: companyID.toString(),
                master_account_id: masterDetail.account_id.toString(),
            }

            await axios
                .post(ApiUrls.bizcomWebInternalHomeDetail, passValues)
                .then(response => {

                    setContentLoaderBool(false);

                    refresh && dispatch(refershDashboadConversationReducer(''));

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {
                            //const team_list = response.data.result.module_list.internal_conversation ?? {};
                            const team_list = response.data.result.beonchat_internal_home_details.internal_conversation_list ?? [];

                            if (parseInt(team_list.length) > 0) {

                                let memberList = [];
                                Object.entries(team_list).map(([id, memberdetail]) => {

                                    let local_time = '';
                                    //const constconversa_list = memberdetail.conversa_list ?? {};

                                    //if (memberdetail.hasOwnProperty('conversa_list') && Object.keys(memberdetail.conversa_list).length > 0) {
                                    if (memberdetail.hasOwnProperty('order_by')) {

                                        let localTime = moment(parseInt(memberdetail.order_by));
                                        let local_date = moment(new Date(localTime)).format(Utilities.dateFormatStr.toString()).toLocaleString();

                                        if (local_date.toString().toLocaleLowerCase() !== 'invalid date') {

                                            var sameDay = moment(memberdetail.order_by).isSame(moment(), 'day');
                                            if (sameDay === true) {
                                                localTime = moment.utc(memberdetail.order_by);
                                                local_time = moment(new Date(localTime)).format(Utilities.timeFormatStr.toString()).toLocaleString();

                                            } else {
                                                local_time = moment(memberdetail.order_by).format(Utilities.dateFormatStr.toString());
                                            }
                                        }

                                    }
                                    //}

                                    memberdetail = { ...memberdetail, ...{ order_by: local_time } };
                                    memberList = [...memberList, memberdetail];
                                });


                                if (parseInt(memberList.length) > 0) {
                                    setListProfiles(memberList);

                                }

                            }
                        }
                    }
                })
                .catch((err) => {
                    setContentLoaderBool(false);
                    refresh && dispatch(refershDashboadConversationReducer(''));
                });


        } else {
            setContentLoaderBool(false);
        }

    }

    const blockOrCounter = (chatDetail) => {
        let html_return = '';

        if (parseInt(chatDetail.mute_status || 0) === 1) {
            html_return = <div className="onmute-icon"></div>
        }

        let blockValue = 0;

        if (!(chatDetail.hasOwnProperty('flag')) && parseInt(chatDetail.flag || 0) !== 2) {
            if (chatDetail.hasOwnProperty('block_status')) {
                blockValue = parseInt(chatDetail.block_status.block_in_status || 0) + parseInt(chatDetail.block_status.block_out_status || 0);
            }
        }

        if (parseInt(blockValue) > 0) {
            html_return = <span className="blocked-icon"></span>
        }

        if (chatDetail.hasOwnProperty('unread_count') && parseInt(chatDetail.unread_count || 0) > 0) {
            html_return = <> {html_return} <div className="unread-counter">{Utilities.counterMore(chatDetail.unread_count)}</div></>
        }

        return html_return;
    }


    const getMemberList = async (reload = 0) => {

        let segment = Utilities.getUrlSegments();
        if (segment.length > 0) {

            const reqAccountID = atob(segment[1]);

            if (parseInt(reqAccountID) > 0) {
                let profileDetailTemp = SessionUtilities.getProfileDetail(reqAccountID);
                setProfileDetail(profileDetailTemp);

                let coompanyDetailTemp = Utilities.workToCompany(profileDetailTemp);
                setCompanyDetail(coompanyDetailTemp);

                let masterAccountArr = {};
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if (accountsList !== null) {
                    accountsList = JSON.parse(accountsList);

                    let firstKey = Object.keys(accountsList)[0];
                    firstKey = parseInt(firstKey);

                    masterAccountArr = accountsList[firstKey];
                }

                await getMembers(coompanyDetailTemp.account_id, profileDetailTemp, masterAccountArr, reload);

            }
        }
    }

    const hideChatLoader = () => {
        setTimeout(() => {
            dispatch(showLoader(0));
            jQuery('.chat-conversation-content').removeClass('opacity-0');
        }, 1400);
    }

    const showChat = async (profile = {}) => {

        if (parseInt(profile.access_status || 1) === 0) {
            return false;
        }

        dispatch(showLoader(1));
        jQuery('.chat-conversation-content').addClass('opacity-0');

        setRowProfileSelected({ ...profile, convers_id: parseInt(profile.convers_id??0) });

        SessionUtilities.setDashboadInternalListSelectedProfile(profile);

        const contactRequestValues = ChatUtilities.contactRequest(profileDetail, profile, 0, 1);
        const conversationDetailRes = await QueryUtilities.getConversationDetail(contactRequestValues.url, contactRequestValues.values);

        const conversation = await getInternalConversation(profile, conversationDetailRes); //conversationDetailRes.result.conversation_details ?? {};
        
        ChatUtilities.saveAccountsConversationDetail(conversation, 'internal 2');
        hideChatLoader();

        const passValues = ChatUtilities.encryptChatDetail(conversation, 1, { account_id: profile.account_id });
        history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(profileDetail.account_id))}/${passValues}`)

    }

    useEffect(() => {
        getMemberList();

        let segment = Utilities.getUrlSegments();

        if (segment.length > 0) {
            const encryptedParam = segment.slice(-1).pop();
            const splitUrlParam = atob(encryptedParam).toString().split('-');
            //setRowProfileSelected({ account_id : parseInt(splitUrlParam.slice(-1).pop()) });
            setRowProfileSelected({ convers_id: parseInt(splitUrlParam[0]) });
        }
    }, []);


    useEffect(async () => {

        if (!Utilities.isEmpty(refershDashboadConversation)) {

            setTimeout(() => {
                getMemberList(1);
            }, 1500);

        }

    }, [refershDashboadConversation]);


    return (
        <div className='dashboad-page' key={rowProfileSelected.convers_id ?? 0}>

            <div className="content-subnav-wrap">
                <div className="container pr-4 pl-0  d-flex align-items-center">
                    <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                        <div className='form-group mb-0 border-0  w-100 '>
                            <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                        </div>
                        {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                    </div>

                </div>
            </div>

            <div className={`list-profile-messages-wrap pr-4`} >
                {
                    contentLoaderBool &&
                    <ul className="dashboard-list-preloader darker-content binking-animate mt-3">
                        {
                            [...Array(10).keys()].map((key) =>
                                <li key={key}>
                                    <div className="profile-image"></div>
                                    <div></div>
                                    <div></div>
                                </li>
                            )
                        }
                    </ul>
                }

                {
                    (Object.keys(listProfiles).length > 0 && !contentLoaderBool) &&

                    <ul id="listing-profiles" className={`list-profile-messages height-inherit-important  pb-5`}>
                        {
                            Object.entries(listProfiles).map(([key, row]) =>
                                <li key={key} data-keyword={row.name.toLowerCase()}
                                    className={`${parseInt(row.access_status || 1) === 0 ? 'opacity-05' : ''} ${parseInt(row.convers_id || 0) === parseInt(rowProfileSelected.convers_id || -1) ? 'selected-profile' : ''} `}
                                >
                                    <div>
                                        {/* <div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }} onClick={ () => previewProfileImage(row.logo) }>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                        <CommonProfileLogo classAttr={`profile-image`} profileDetail={row} />
                                    </div>
                                    <div className='line-height-1-3' onClick={() => showChat(row)} >
                                        <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                        <div className="profile-label color-theme-grey">{row.title}</div>
                                    </div>
                                    <div className="time color-theme-grey">{row.order_by}</div>
                                    <div>{blockOrCounter(row)}</div>
                                </li>
                            )
                        }
                    </ul>

                }

            </div>
        </div>
    )

}

export default memo(ConversationInternal);