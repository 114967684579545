import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from "react-datepicker";
import moment from 'moment';
import jQuery from 'jquery';

import Utilities from '../../helpers/Utilities';
import QueryChatUtilities from '../../helpers/QueryChatUtilities';


import TitlesLabels from '../../helpers/TitlesLabels';
import TimePicker from '../TimePicker';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import DatePickerSelection from './DatePickerSelection';
import TimePickerSelection from './TimePickerSelection';
import SessionUtilities from '../../helpers/SessionUtilities';

const AppointmentReservationDetail = (props) => {

    const TOKEN = process.env.REACT_APP_chat_api_token;

    const dateFormatted = Utilities.currentDateFormatted();
    const timeFormatted = Utilities.returnTimeFormat();
    const maxWords = Utilities.appointmentReservationCharLimit;

    let defaultFormValues = {
        name: '',
        date: dateFormatted,
        time: '',
        no_people: '',
        remarks: '',
    }

    const [userLoggedinInfo, setUserLoggedinInfo] = useState({});

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [formValuesOld, setFormValuesOld] = useState(defaultFormValues);

    const [modalTitle, setModalTitle] = useState(Utilities.viewFormLabel(1));
    const [recordSelected, setRecordSelected] = useState({});
    const [ammedAction, setAmmedAction] = useState(false);

    const [requestTime, setRequestTime] = useState(timeFormatted);
    const [requestDate, setRequestDate] = useState(new Date());

    const [showDatePickerPopup, setShowDatePickerPopup] = useState(false);
    const [showTimePickerPopup, setShowTimePickerPopup] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;
        let value = e.target.value;

        if (fieldName === 'name') {
            formValuesTemp = { ...formValuesTemp, ...{ name: value } };

        } else if (fieldName === 'no_people') {
            formValuesTemp = { ...formValuesTemp, ...{ no_people: value } };

        } else if (fieldName === 'subject') {
            formValuesTemp = { ...formValuesTemp, ...{ subject: value } };

        } else if (fieldName === 'remarks') {

            const charactersCounter = Utilities.charactersCounter(value);
            if (parseInt(charactersCounter) > maxWords) {
                Utilities.messagePopup('error', `Only ${maxWords} characters allowed!`);
                return false;
            }
            formValuesTemp = { ...formValuesTemp, ...{ remarks: value } };
        }

        setFormValues(formValuesTemp);
        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
    }


    const handleHoldDateTime = (typeName = 'date') => {
        if (typeName === 'date') {
            setRequestDate(requestDate);
            setShowDatePickerPopup(true);
        } else {
            setRequestTime(requestTime);
            setShowTimePickerPopup(true);
        }
    }


    const handleDateChange = (date) => {
        const dateRequestFormatted = Utilities.currentDateFormatted(date);
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ date: dateRequestFormatted } };
        setFormValues(formValuesTemp);

        setRequestDate(date);
        setShowDatePickerPopup(false);
    }

    const handleTimeChange = (timeProvided) => {
        setRequestTime(timeProvided);
        setShowTimePickerPopup(false);
    }


    const handleCancelRequest = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wishToCancelRequest,
            overlayClassName: 'error',
            buttons: [
                { label: 'yes', onClick: () => { cancelRequestSubmit() } },
                { label: 'no' }
            ]
        });
    }

    const acceptRequestSubmit = async () => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passValues = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: recordSelected.receiver_account_id.toString(),
            group_id: recordSelected.detail.group_id.toString(),
            convers_id: recordSelected.detail.conversa_id.toString()
        }

        const button_name = 'btn-submit-accept';
        Utilities.elementDisabledStatus(button_name, true);

        const acceptanceResponse = await QueryChatUtilities.acceptAppointmentReservation(passValues);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(acceptanceResponse.success) === 1) {
            Utilities.messagePopup('success', acceptanceResponse.successMessage);
            props.handleCloseAppointmentPopUp();
            props.handleSelectedDateReload();

        } else {
            Utilities.messagePopup('error', acceptanceResponse.errorMessage);
        }
    }


    const cancelRequestSubmit = async () => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passValues = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: recordSelected.receiver_account_id.toString(),
            group_id: recordSelected.detail.group_id.toString(),
            convers_id: recordSelected.detail.conversa_id.toString()
        }

        const button_name = 'btn-submit-form-cancel';
        Utilities.elementDisabledStatus(button_name, true);

        const cancelationResponse = await QueryChatUtilities.cancelAppointmentReservation(passValues);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(cancelationResponse.success) === 1) {
            Utilities.messagePopup('success', cancelationResponse.successMessage);
            props.handleCloseAppointmentPopUp();
            props.handleSelectedDateReload();

        } else {
            Utilities.messagePopup('error', cancelationResponse.errorMessage);
        }
    }



    const viewFormEditableContent = (formType = 1) => {
        formType = parseInt(formType);
        let returnHtml = '';

        if (formType === 1) {
            returnHtml = <>
                <div className='form-col-2 grid-gap-h-0'>
                    <div className="form-group">
                        <input type="text" name="name" className="form-control" placeholder=" " autoComplete="new-password" value={formValues.name} onChange={(e) => pushValue(e, 'name')} readOnly ></input>
                        <label className="form-label">Name</label>
                    </div>

                    <div className="form-group">
                        <div className="form-control" onClick={() => handleHoldDateTime('date')}>{Utilities.currentDateFormatted(formValues.date)}</div>
                        <label className="form-label">Date</label>
                    </div>

                    <div className="form-group active">
                        <div className="form-control" onClick={() => handleHoldDateTime('time')}>{requestTime}</div>
                        <label className="form-label">Time</label>
                    </div>
                </div>

                <div className="form-group pb-2 border-0">
                    <textarea name="remarks" className="form-control text-tranform-initial" placeholder=" " rows="4" value={formValues.remarks} onChange={(e) => pushValue(e, 'remarks')}></textarea>
                    <label className="form-label">Special Request</label>
                </div>
            </>

        } else if (formType === 2) {

            returnHtml = <>
                <div className='form-col-2 grid-gap-h-0'>
                    <div className="form-group">
                        <input type="text" name="name" className="form-control" placeholder=" " autoComplete="new-password" value={formValues.name} onChange={(e) => pushValue(e, 'name')} readOnly ></input>
                        <label className="form-label">Name</label>
                    </div>

                    <div className="form-group">
                        <div className="form-control" onClick={() => handleHoldDateTime('date')}>{Utilities.currentDateFormatted(formValues.date)}</div>
                        <label className="form-label">Date</label>
                    </div>

                    <div className="form-group active">
                        <div className="form-control" onClick={() => handleHoldDateTime('time')}>{requestTime}</div>
                        <label className="form-label">Time</label>
                    </div>

                    <div className="form-group">
                        <input type="number" name="no_people" className="form-control hide-arrow" placeholder=" " autoComplete="new-password" value={formValues.no_people} onChange={(e) => pushValue(e, 'no_people')}></input>
                        <label className="form-label">Number of People</label>
                    </div>
                </div>
                <div className="form-group pb-2 border-0">
                    <textarea name="remarks" className="form-control text-tranform-initial" placeholder=" " rows="4" value={formValues.remarks} onChange={(e) => pushValue(e, 'remarks')}></textarea>
                    <label className="form-label">Special Request</label>
                </div>
            </>

        }

        return returnHtml;
    }


    const submitAmend = async () => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        let formDetailRequest = formValues;

        let requestDateTemp = '';
        let requestTimeTemp = Utilities.convertTo24Hour(requestTime);


        if (formDetailRequest.hasOwnProperty('date')) {
            requestDateTemp = formDetailRequest.date;
            requestTimeTemp = requestDateTemp.toString() + ' ' + requestTimeTemp.toString() + ':00';

            requestDateTemp = moment(new Date(requestDateTemp.toString())).format(Utilities.dayFormatServerStr.toString()).toLocaleString();
            requestTimeTemp = moment(new Date(requestTimeTemp.toString())).format(Utilities.timeFormatServerStr.toString()).toLocaleString();

            formDetailRequest = { ...formDetailRequest, ...{ date: requestDateTemp, time: requestTimeTemp } };
        }



        // AMEND
        const fieldChecker = ['date', 'no_people', 'remarks', 'time'];
        if (Object.entries(formValuesOld).length > 0) {

            let amendmentDetail = {};
            Object.entries(formValuesOld).map(([key, val]) => {
                if (!Utilities.isEmpty(val)) {
                    val = val.toString().toLowerCase();

                    if (key in formDetailRequest && fieldChecker.includes(key.toString())) {
                        const reqValue = formDetailRequest[key].toString().toLowerCase()
                        if (val !== reqValue) {
                            amendmentDetail[key] = reqValue;
                        }
                    }
                }
            });

            amendmentDetail = {
                amendment_date: amendmentDetail.hasOwnProperty('date') ? formDetailRequest.date.toString() : '',
                amendment_time: amendmentDetail.hasOwnProperty('time') ? formDetailRequest.time.toString() : '',
                amendment_no_people: amendmentDetail.hasOwnProperty('no_people') ? formDetailRequest.no_people.toString() : '',
                amendment_remarks: amendmentDetail.hasOwnProperty('remarks') ? formDetailRequest.remarks.toString() : '',
            }

            const passValues = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: recordSelected.receiver_account_id.toString(),
                group_id: recordSelected.detail.group_id.toString(),
                convers_id: recordSelected.detail.conversa_id.toString(),
                amendment_details: amendmentDetail
            }

            const button_name = 'btn-submit-form-amend';
            Utilities.elementDisabledStatus(button_name, true);

            const amendmentResponse = await QueryChatUtilities.amendAppointmentReservation(passValues);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(amendmentResponse.success) === 1) {
                Utilities.messagePopup('success', amendmentResponse.successMessage);
                props.handleCloseAppointmentPopUp();
                props.handleSelectedDateReload();

            } else {
                Utilities.messagePopup('error', amendmentResponse.errorMessage);
            }
        }
    }


    useEffect(async () => {

        setAmmedAction(false);
        setRecordSelected({});
        setFormValues(defaultFormValues);
        setFormValuesOld(defaultFormValues);

        const { appointmentDetail } = props;
        if (typeof appointmentDetail !== 'undefined' && Object.entries(appointmentDetail).length > 0) {

            let appointmentDetailTemp = appointmentDetail;
            const TOKEN_SESSION = SessionUtilities.getSessionToken();

            const requestValues = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: appointmentDetailTemp.account_id.toString(),
                group_id: appointmentDetailTemp.group_id.toString()
            }

          
            const responseInformation = await QueryChatUtilities.getAppointmentReservationDetail(requestValues);
            if (Object.entries(responseInformation).length > 0) {
                let detailAppointment = responseInformation.result.reservation_feedback_details;

                const dateApply = (detailAppointment.hasOwnProperty('amendment_date') && !Utilities.isEmpty(detailAppointment.amendment_date)) ? detailAppointment.amendment_date : detailAppointment.date;
                detailAppointment = { ...detailAppointment, ...{ date: dateApply } };

                const timeApply = (detailAppointment.hasOwnProperty('amendment_time') && !Utilities.isEmpty(detailAppointment.amendment_time)) ? detailAppointment.amendment_time : detailAppointment.time;
                detailAppointment = { ...detailAppointment, ...{ time: timeApply } };

                if (parseInt(appointmentDetailTemp.type) === 2) {
                    const timeApply = (detailAppointment.hasOwnProperty('amendment_no_people') && !Utilities.isEmpty(detailAppointment.amendment_no_people)) ? detailAppointment.amendment_no_people : detailAppointment.no_people;
                    detailAppointment = { ...detailAppointment, ...{ no_people: timeApply } };
                }

                const remarksApply = (detailAppointment.hasOwnProperty('amendment_remarks') && !Utilities.isEmpty(detailAppointment.amendment_remarks)) ? detailAppointment.amendment_remarks : detailAppointment.remarks;
                detailAppointment = { ...detailAppointment, ...{ remarks: remarksApply } };

                appointmentDetailTemp = { ...appointmentDetailTemp, ...{ detail: detailAppointment } };

            }

            let formValuestemp = {
                name: appointmentDetailTemp.hasOwnProperty('sender_detail') && (appointmentDetailTemp.sender_detail.name ?? ''),
                date: appointmentDetailTemp.detail.date ?? '',
                time: appointmentDetailTemp.detail.time ?? '',
                no_people: parseInt(appointmentDetailTemp.type) === 2 ? (appointmentDetailTemp.hasOwnProperty('detail') && (appointmentDetailTemp.detail.no_people ?? '')) : '',
                remarks: appointmentDetailTemp.detail.remarks ?? '',
            }


            setRequestDate(new Date(appointmentDetailTemp.detail.date));
            setRequestTime(Utilities.returnTimeFormat(appointmentDetailTemp.detail.date + ' ' + appointmentDetailTemp.detail.time ?? ''));

            setFormValues(formValuestemp);
            setFormValuesOld(formValuestemp);

            setModalTitle(appointmentDetailTemp.title);
            setRecordSelected(appointmentDetailTemp);

        }

        setUserLoggedinInfo(PagesAuthentication.getUserLoggedinDetail());

    }, [props.showAppoinmentPopup]);

    return (
        <>
            <Modal id="popup-department-form" show={props.showAppoinmentPopup} onHide={props.handleCloseAppointmentPopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme" >
                <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline  pt-4 text-lowercase">{modalTitle}</Modal.Header>
                <Modal.Body>

                    {
                        Object.entries(recordSelected).length === 0 ?
                            <div className="content-loader animate"></div>
                            :

                            ammedAction === true ?
                                <div className="text-lowercase form-theme form-theme-medium col-11 mx-auto py-4">
                                    {viewFormEditableContent(recordSelected.type)}

                                    <div className="text-center font-gotham-book fs-13 fs-md-14 text-color-gray mt-n2">{maxWords} characters</div>
                                    <div className="button-group-boolean mt-5">
                                        <button type="button" className="btn-theme-black" onClick={() => setAmmedAction(false)}>close</button>
                                        <button type="button" id="btn-submit-form-amend" className="btn-theme-black button-has-loader" onClick={() => submitAmend()}>amend</button>
                                    </div>
                                </div>
                                :
                                <div className="text-lowercase form-theme form-theme-medium col-11 mx-auto py-4">

                                    <div className="form-group active">
                                        <div className="form-control">{recordSelected.hasOwnProperty('sender_detail') && (recordSelected.sender_detail.name ?? '')}</div>
                                        <label className="form-label">Name</label>
                                    </div>

                                    <div className="form-col-2">
                                        <div className="form-group active">
                                            <div className="form-control">{Utilities.currentDateFormatted(recordSelected.detail.date ?? '')}</div>
                                            <label className="form-label">Date</label>
                                        </div>

                                        <div className="form-group active">
                                            <div className="form-control">{Utilities.returnTimeFormat(recordSelected.detail.date + ' ' + recordSelected.detail.time ?? '')}</div>
                                            <label className="form-label">Time</label>
                                        </div>

                                    </div>

                                    {
                                        parseInt(recordSelected.type) === 2 &&
                                        <div className="form-col-2">
                                            <div className="form-group active">
                                                <div className="form-control">{recordSelected.hasOwnProperty('detail') && (recordSelected.detail.no_people ?? '')}</div>
                                                <label className="form-label">Number of People</label>
                                            </div>
                                        </div>

                                    }

                                    <div className="form-group active pb-2 border-0">
                                        <div className="form-control form-control-inherit text-tranform-initial">{recordSelected.detail.remarks || TitlesLabels.general.noContent}</div>
                                        <label className="form-label">Special Request</label>
                                    </div>


                                    {
                                        [0, 2].includes(parseInt(recordSelected.request_status)) ?
                                            <div className={`button-group-boolean ${(parseInt(userLoggedinInfo.account_id) !== parseInt(recordSelected.sender_detail.account_id)) ? 'column-3' : ''} mt-5`}>

                                                {
                                                    (parseInt(userLoggedinInfo.account_id) !== parseInt(recordSelected.sender_detail.account_id)) &&
                                                    <button type="button" id="btn-submit-accept" className="btn-theme-black button-has-loader" onClick={() => acceptRequestSubmit()}>accept request</button>
                                                }

                                                <button type="button" className="btn-theme-black" onClick={() => setAmmedAction(true)}>amend request</button>
                                                <button type="button" id="btn-submit-form-cancel" className="btn-theme-black button-has-loader" onClick={() => handleCancelRequest()}>cancel request</button>
                                            </div>
                                            :
                                            <button type="button" className="btn-theme-black text-lowercase mt-5" onClick={() => props.handleCloseAppointmentPopUp()}>close</button>
                                    }


                                </div>

                    }


                </Modal.Body>
            </Modal>

            <DatePickerSelection showPopUp={showDatePickerPopup} selectedDate={requestDate} handleClosePopUp={handleDateChange} />
            <TimePickerSelection showPopUp={showTimePickerPopup} providedTime={requestTime} handleClosePopUp={handleTimeChange} />
        </>
    )
}

export default AppointmentReservationDetail;
